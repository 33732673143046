import {devices} from 'constants/breakpoints';
import formatDate from 'helpers/formatDate';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";
import Modal from "ui/material-kit/Modal";
import {useHallOfFameMonths} from "../model/hall-of-fame.store";

type calendarProps = {
    month: string,
    onClickMonth: (month: string) => void,
    className?: string
}

type CalendarProps = {
    open: boolean,
    onClose: () => void,
    month: string,
    monthList: string[],
    onClickMonth: (month: string) => void,
}

const CalendarModal = (props: CalendarProps) => {
    const {i18n} = useTranslation();
    const {open, onClose, monthList, month, onClickMonth} = props;
    return (
        <Modal open={open} onClose={onClose}>
            <WrapperCalendar>
                {
                    monthList.map((item, _) => (
                        <CalendarItem key={item + 'calendar-item'} onClick={() => {
                            onClickMonth(item);
                        }} active={item === month}>
                            {`${formatDate({
                                date: new Date(item),
                                dateFormat: 'MMMM',
                                lang: i18n.language
                            })}`}
                        </CalendarItem>
                    ))
                }
            </WrapperCalendar>
        </Modal>
    );
};

const WrapperCalendar = styled.div`
  display: grid;
  margin: 5px;
  gap: 3px;
  grid-template-columns: repeat(3, 1fr);
  width: 365px;

  ${devices.tablet} {
    width: 215px;
  }

  padding: 5px;
  background: #A1712B;
  border-radius: 5px;
`

const CalendarItem = styled.div<{ active?: boolean }>`
  text-transform: uppercase;
  padding: 4px 8px;
  height: max-content;
  border-radius: 5px;
  background: ${props => props.active ? '#FFF6B1' : '#C89D4B'};
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #79531F;

  ${devices.tablet} {
    font-size: 12px;
    line-height: 14px;
  }

  :active {
    background: #FFF6B1;
  }
`

export const Calendar = (props: calendarProps) => {
    const {i18n} = useTranslation();
    const monthsList = useHallOfFameMonths();
    const {month, className, onClickMonth} = props;
    const [openMonth, setOpenMonth] = useState(false);
    return (
        <>
            <CalendarModal onClose={() => setOpenMonth(false)} open={openMonth} onClickMonth={onClickMonth}
                           month={month} monthList={monthsList}/>
            <Title className={className} onClick={() => setOpenMonth(true)}>
                {month && `${formatDate({
                    date: new Date(month),
                    dateFormat: 'MMMM',
                    lang: i18n.language
                })}`}
            </Title>
        </>
    );
};

const Title = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  cursor: pointer;
  color: #906325;
  text-decoration: underline;
  text-decoration-style: dashed;

  ${devices.tablet} {
    font-size: 12px;
    line-height: 14px;
  }

  :hover {
    color: #4D2608;
  }

  :active {
    opacity: 0.8;
  }

`

