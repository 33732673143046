import React, {useState} from 'react';
import {ReactComponent as FilterIcon} from "assets/images/profile_filter_icon.svg"
import CustomDatePicker from './material-kit/CustomDatePicker';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {endOfDay, startOfDay} from 'date-fns';

type FilterDateProps = {
    onSave: (from: Date, to: Date) => void,
    isLoading: boolean
}


const FilterDate = ({onSave, isLoading}: FilterDateProps): JSX.Element => {
    const [dateStart, setDateStart] = useState<Date | undefined>(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [dateEnd, setDateEnd] = useState<Date | undefined>(new Date());
    const {t} = useTranslation();
    return (
        <Container id={`filter-date`}>
            <ContainerIcon>
                <Title>
                    {t(`common.filter_date.title`)}
                </Title>

                <FilterIcon/>

            </ContainerIcon>

            <>
                <CustomDatePicker label={t(`common.filter_date.from_field`)}
                                  value={dateStart}
                                  maxDate={dateEnd}
                                  onChange={setDateStart}/>
                <CustomDatePicker label={t(`common.filter_date.to_field`)}
                                  value={dateEnd}
                                  minDate={dateStart}
                                  onChange={setDateEnd}/>
                <StyledButton disabled={isLoading} onClick={() => {
                    onSave(startOfDay(dateStart!), endOfDay(dateEnd!));
                }}>
                    {t(`common.filter_date.show_button`)}
                </StyledButton>
            </>

        </Container>
    );
};

export default FilterDate;


const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
`


const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
`

const Title = styled.div`
  font-family: Roboto Condensed;
  font-weight: 700;
  font-size: 14px;
`

const StyledButton = styled("button")`
  text-transform: none;
  box-sizing: border-box;
  height: 38px;
  width: 100%;
  cursor: pointer;
  font-family: Roboto Condensed;
  color: white;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px;
  background: #829FF5;
  box-sizing: border-box;

  &:disabled {
    background: #4f4f4f;
    cursor: not-allowed;

    &:hover {
      background: #4f4f4f;
    }
  }

  &:hover {
    background: #6a86d3;
  }
`
