import React, {useEffect, useState} from 'react';
import {ReactComponent as CashbackIcon} from "assets/images/profile_cashback-icon.svg"
import axios from 'api';
import {useActions} from "hooks/useActions";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import TextField from "ui/material-kit/TextField";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {getStorage} from "../../hooks/useStorage";
import GoldTime from "../../ui/GoldTime";
import LottieDotsLoader from "../../ui/loaders/DotsLottieLoader";
import BackButton from "../../ui/BackButton";
import {MobileContainer} from 'ui/containers';
import {getCashbackInfo} from "../../store/actions/userActions";


const Cashback = (): JSX.Element => {
    const {t, i18n} = useTranslation();
    const {setUserBalance, getCashbackInfo} = useActions();
    const [isDisabled, setIsDisabled] = useState(false);

    const {user, cashbackInfo, loadingCashbackInfo} = useTypedSelector(state => {
        return ({
            user: state.user.user,
            cashbackInfo: state.user.cashbackInfo,
            loadingCashbackInfo: state.user.loadingCashbackInfo,
        })
    })


    useEffect(() => {
        getCashbackInfo();
    }, [window.location]);

    const getCashback = async () => {
        axios.post(`/api/get-cashback`).then((pld: any) => {
            if (user) {
                setUserBalance(pld.data.balance);
                getCashbackInfo();
                setIsDisabled(false);
            }

        }).catch((e) => {
            getCashbackInfo();
            setIsDisabled(false);
        });
    }

    const currency = getStorage("APP_OTHER.CURRENCY.KEY");
    return (
        <>
            <BackButton href={'/profile'} title={t(`common.options.back_to_profile`)}/>
            <MobileContainer>
                <Content>
                    <FlexContent style={{gap: 10}}>
                        <CashbackIcon style={{width: 100, height: 100}}/>
                        <Title style={i18n.language === "en" ? {} : {fontSize: 18}}>{t(`pages.cashback.title`)}</Title>
                        {cashbackInfo?.isAvailable && <Description>
                            {cashbackInfo?.isReceived && t(`pages.cashback.already_description`)}
                        </Description>}

                        {!cashbackInfo?.isAvailable && <Description>
                            {t(`pages.cashback.no_description`)}
                        </Description>}

                        <ContainerInput>
                            {cashbackInfo?.isAvailable && <div style={{width: "100%", display: "flex", gap: 10}}>
                                <TextField readOnly extra={`height: 45px; width: 100%`} onChange={(e) => {
                                }}
                                           value={`${t(`pages.cashback.amount`).toUpperCase()}:  ${cashbackInfo?.amount} ${(user?.currency ?? currency).toUpperCase()}`}/>
                            </div>
                            }
                            <div style={{
                                padding: "0px 20px 20px 20px", width: "100%", display: "flex",
                                justifyContent: "center", flexDirection: "column", alignItems: "flex-start", gap: 10
                            }}>
                                <div>
                                    {t(`pages.cashback.time_left`)}
                                </div>

                                <GoldTime initialTime={cashbackInfo?.timeLeft} timeEndCallBack={() => {
                                    setTimeout(() => {
                                        getCashbackInfo();
                                    }, 1000)

                                }}/>

                                <StyledButton
                                    disabled={!cashbackInfo?.isAvailable || cashbackInfo?.isReceived || loadingCashbackInfo || isDisabled}
                                    onClick={async () => {
                                        setIsDisabled(true);
                                        await getCashback();
                                    }}>
                                    {loadingCashbackInfo ? <LottieDotsLoader/> : t(`pages.cashback.get_cashback`)}

                                </StyledButton>

                            </div>
                        </ContainerInput>
                    </FlexContent>
                </Content>

            </MobileContainer>
        </>
    );
};

export default Cashback;

const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

}))

const Content = styled(FlexContent)((props) => ({
    paddingTop: "50px",
}))

const Title = styled(`div`)((props) => ({
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: 600,
    fontFamily: "Roboto Condensed"
}))


const Description = styled.div`
  font-size: 14px;
  font-weight: 600;
  max-width: 200px;
  line-height: 16px;
  text-align: center;
`

const ContainerHistory = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
    paddingTop: 24,
}))

export const ContainerInput = styled(`div`)((props) => ({
    gap: 10,
    paddingTop: 20,
    maxWidth: 260,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
}));


const StyledButton = styled("button")<{ disabled?: boolean }>((props) => ({
    textTransform: "none",
    height: 45,
    width: "100%",
    fontFamily: "Roboto Condensed",
    color: "white",
    fontSize: 16,
    borderRadius: 5,
    cursor: "pointer",
    background: "#829FF5",
    "&:hover": {
        background: "#6a86d3"
    },
    "&:disabled": {
        background: "#373D50",
        cursor: "default"
    }
}));
