import {userAPI} from "api/user/userApi";
import {Dispatch} from "redux";
import {
    userAction,
    UserActionTypes as types,
    IUser,
    UserActionTypes as userTypes,
    ICashbackInfo
} from "../types/userTypes";
import {IUserLogin} from "api/user/userTypes";
import {RootState} from "store";
import {InitialTransactions_} from "store/actions/transcationActions";
import {transactionAction} from "../types/transactionTypes";
import {appAction} from "../types/appTypes";
import {appAPI} from "../../api/app/appApi";

////////////////////////////////////////////////////////
//LOAD//GET

const loadUser_ = (): userAction => {
    return {
        type: types.LOAD_USER
    }
}

const loadUserFailed = (error: string): userAction => {
    return {
        type: types.LOAD_USER_FAIL,
        error: error
    }
}

const loadUserSuccess = (user: IUser): userAction => {
    return {
        type: types.LOAD_USER_SUCCESS,
        user: user
    }
}


//THUNK
export const userLogin = (obj: IUserLogin) => {
    return (dispatch: Dispatch<userAction>) => {
        dispatch(loadUser_());
        return userAPI.userLogin(obj).then(pld => {
            window.location.reload();
            dispatch(loadUserSuccess(pld));
        }).catch(error => {
            let res = error.response;
            dispatch(loadUserFailed(res.data.message))
        })
    }
}


export const getUserData = () => {
    return (dispatch: Dispatch<userAction>) => {
        dispatch(loadUser_());
        return userAPI.getUser().then(res => {
            dispatch(loadUserSuccess(res))
        }).catch(error => {
            let res = error.response;
            dispatch(loadUserFailed(res.data.message))
        })

    }
}

////////////////////////////////////////////////////////
//UPDATE

const updateUser_ = (): userAction => {
    return {
        type: types.UPDATE_USER
    }
}

const updateUserFailed = (error: string): userAction => {
    return {
        type: types.UPDATE_USER_FAIL,
        error
    }
}

export const updateUserSuccess = (user: IUser): userAction => {
    return {
        type: types.UPDATE_USER_SUCCESS,
        user
    }
}


export const setUserBalance = (newBalance: number) => {
    return (dispatch: Dispatch<userAction>, getState: () => RootState) => {
        dispatch(updateUser_());
        let user = getState().user.user!;
        try {
            dispatch(updateUserSuccess({...user, balance: newBalance}))
        } catch (error) {
            console.log(error)
        }

    }
}

export const updateUser = () => {
    return (dispatch: Dispatch<userAction>, getState: () => RootState) => {
        dispatch(updateUser_());
        let user = getState().user.user!;
        return userAPI.getUserBalance().then(res => {
            dispatch(updateUserSuccess({...user, balance: res.balance}))
        }).catch(error => {
            let res = error.response;
            dispatch(updateUserFailed(res.data.message))
        })

    }
}


////////////////////////////////////////////////////////
//LOGOUT


const logoutUser_ = (): userAction => {
    return {
        type: types.UPDATE_USER
    }
}

const logoutUserFailed = (error: string): userAction => {
    return {
        type: types.LOGOUT_USER_FAIL,
        error
    }
}

const logoutUserSuccess = (): userAction => {
    return {
        type: types.LOGOUT_USER_SUCCESS,
    }
}


//THUNK
export const logoutUser = () => {
    return (dispatch: Dispatch<userAction | transactionAction>) => {
        dispatch(logoutUser_());
        return userAPI.logoutUser().then(res => {
            dispatch(logoutUserSuccess());
            dispatch(InitialTransactions_());
        }).catch(error => {
            let res = error.response;
            dispatch(logoutUserFailed(res.data.message))
        })

    }
}



const loadCashback = (): userAction => {
    return {
        type: userTypes.LOAD_CASHBACK_INFO
    }
}

const loadCashbackSuccess = (cashbackInfo: ICashbackInfo): userAction => {
    return {
        type: userTypes.LOAD_CASHBACK_INFO_SUCCESS,
        cashbackInfo
    }
}

const loadCashbackFailed = (error: string): userAction => {
    return {
        type: userTypes.LOAD_CASHBACK_INFO_FAIL,
        error
    }
}

export const getCashbackInfo = () => {
    return (dispatch: Dispatch<userAction>) => {
        dispatch(loadCashback());
        return userAPI.getCashbackInfo().then(pld => {
            dispatch(loadCashbackSuccess(pld));
        }).catch((error) => {
            let res = error.response;
            dispatch(loadCashbackFailed(res.data.message))
        })
    }
}
