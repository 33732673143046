import React from 'react';
import {ReactComponent as SupportIcon} from "assets/images/drawer_support_icon.svg";
import {ReactComponent as PrivacyIcon} from "assets/images/drawer_privacy_icon.svg";
import SocialLinks from 'ui/SocialLinks';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {TawkService} from "../../../services/tawkService";
import {getStorage} from "../../../hooks/useStorage";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const DrawerFooter = (): JSX.Element => {
    const {t, i18n} = useTranslation();
    const APP_OTHER = getStorage("APP_OTHER");
    const FOOTER = getStorage("APP_OTHER.FOOTER");
    const currency = APP_OTHER.CURRENCY.KEY;
    const APP_SEO = getStorage("APP_SEO");
    const isVisible = APP_OTHER.HEADER.MENU.HELP.VISIBLE;
    const isTawk = APP_OTHER.HEADER.MENU.HELP.TAWK;
    const isFeedback = APP_OTHER.HEADER.MENU.HELP.FEED_BACK;
    const TITLE = APP_SEO.TITLE;
    const SOCIAL_LINKS = APP_OTHER.SOCIAL_LINKS;
    const socialLinksArr: any = Object.values(SOCIAL_LINKS).filter((item: any) => item.VISIBLE);

    const {user} = useTypedSelector(state => {
        return ({
            user: state.user.user,

        })
    })

    const {updateModal} = useActions();

    const getCur = () : {amount: number, currency: string} => {
        const curCur =  user?.currency ?? currency;
        const amount = curCur === "TND" ? 150000 : curCur === "USD" ? 50000 : 180000;

        return {amount, currency: curCur}
    }
    return (
        <Wrapper>
            <ContainerInfo style={{borderBottom: "1px solid #263154", paddingBottom: 9}}>
                {socialLinksArr.length !== 0 && <SocialLinks/>}
                {isVisible && <RightContainer style={i18n.language === "en" ? {} : {gap: 0}} onClick={() => {
                    {
                        isTawk && TawkService.toggleWidget()
                        isFeedback && updateModal(true)
                        isFeedback && console.log(1)
                    }
                }}>
                    <Text style={i18n.language === "en" ? {} : {fontSize: 10}}>{t(`common.info.support`)}</Text>
                    <SupportIcon/>
                </RightContainer>}
            </ContainerInfo>
            <ContainerInfo>
                <Wrapper style={{gap: 8}}>
                    <Text style={{maxWidth: 170, fontWeight: 500, fontSize: 13}}>
                        {t(`common.info.attention`)}
                    </Text>
                    {
                        FOOTER.ATTENTION_MAX_BALANCE.VISIBLE && <Text style={{color: "#FFB401", fontSize: 11}}>
                            *** {t(`common.info.attention_max_balance`, getCur())} ***
                        </Text>
                    }
                    <SecondText>
                        {t(`common.options.copyright`, {title: TITLE, year: new Date().getFullYear()})}
                    </SecondText>
                </Wrapper>
                <PrivacyIcon/>
            </ContainerInfo>
        </Wrapper>
    );
};

export default DrawerFooter;


const Text = styled('div')((props) => ({
    fontSize: 12,
    fontWeight: 700,

}))


const SecondText = styled(Text)((props) => ({
    fontSize: 10,
    fontWeight: 900,
    color: "#829FF5"
}))


const Wrapper = styled("div")((props) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%"
}))


const RightContainer = styled("div")((props) => ({
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    boxSizing: "border-box",
    alignItems: "center",
    gap: 9,
}))


const ContainerInfo = styled("div")((props) => ({
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
    padding: "20px 15px",
    width: "100%"
}))
