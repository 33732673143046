import React, {useState} from 'react';
import BackButton from 'ui/BackButton';
import {ReactComponent as ChangePasswordIcon} from "assets/images/profile_logo_change-password_icon.svg"
import {MobileContainer} from 'ui/containers';
import {useInput} from "hooks/useInput";
import axios from 'api';
import {useActions} from "hooks/useActions";
import {useTranslation} from 'react-i18next';
import TextField from 'ui/material-kit/TextField';
import styled from 'styled-components';

const Settings = (): JSX.Element => {
    const {t} = useTranslation();
    const errorFields = {
        oldPassword: t(`pages.settings.old_password_error`),
        newPassword: t(`pages.settings.new_password_error`),
        newRepeatPassword: t(`pages.settings.new_repeat_password_error`),
        repeatPassword: t(`pages.settings.repeat_password_error`)
    }
    let oldPassword = useInput("");
    let newPassword = useInput("");
    let repeatPassword = useInput("");
    const {updateAlert} = useActions();
    const [errorState, setErrorState] = useState({
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
    });

    const changePassword = async () => {
        let errorInfo = {
            oldPassword: "",
            newPassword: "",
            repeatPassword: ""
        };

        if (oldPassword.value === "") {
            errorInfo.oldPassword = errorFields.oldPassword;
        }
        if (newPassword.value === "") {
            errorInfo.newPassword = errorFields.newPassword;
        }
        if (newPassword.value !== repeatPassword.value) {
            errorInfo.repeatPassword = errorFields.repeatPassword;
        }
        if (repeatPassword.value === "") {
            errorInfo.repeatPassword = errorFields.newRepeatPassword;
        }

        if (!Object.values(errorInfo).some(x => (x !== ""))) {
            await axios.put("api/me/password", {
                password: oldPassword.value,
                newPassword: newPassword.value,
                repeatPassword: repeatPassword.value
            })
                .then((x) => x.data)
                .then(pld => {
                    if (pld.error) {
                        errorInfo.oldPassword = `${t(`pages.settings.invalid_password`)}`;
                        setErrorState(errorInfo);
                        updateAlert({
                            isOpen: true,
                            status: "error",
                            message: `${t(`pages.settings.invalid_password`)}`
                        });
                    } else {
                        updateAlert({isOpen: true, status: "warning", message: "Operation successful"});
                    }
                })
                .catch((pld) => {
                    errorInfo.oldPassword = `${t(`pages.settings.invalid_password`)}`;
                    setErrorState(errorInfo);
                    updateAlert({isOpen: true, status: "error", message: `${t(`pages.settings.invalid_password`)}`});
                })
        } else {
            setErrorState(errorInfo)
        }
    }
    return (
        <>

            <BackButton href={'/profile'} title={t(`common.options.back_to_profile`)}/>
            <MobileContainer>
                <Content>
                    <FlexContent style={{gap: 20}}>
                        <ChangePasswordIcon/>
                        <Title>{t(`pages.settings.title`)}</Title>
                        <ContainerInput>
                            <TextField type={"password"} extra={`height: 37px; font-size: 16px`} onChange={(e) => {
                                setErrorState({...errorState, oldPassword: ""})
                                oldPassword.onChange(e)
                            }} value={oldPassword.value} placeholder={t(`pages.settings.old_password_field`)}/>
                            <TextField type={"password"} extra={`height: 37px; font-size: 16px`} onChange={(e) => {
                                setErrorState({...errorState, newPassword: ""})
                                newPassword.onChange(e)
                            }} value={newPassword.value} placeholder={t(`pages.settings.new_password_field`)}/>
                            <TextField type={"password"} extra={`height: 37px; font-size: 16px`} onChange={(e) => {
                                setErrorState({...errorState, repeatPassword: ""})
                                repeatPassword.onChange(e)
                            }} value={repeatPassword.value} placeholder={t(`pages.settings.repeat_password_field`)}/>
                            <div style={{
                                width: "100%", boxSizing: "border-box", display: "flex",
                                justifyContent: "center", flexDirection: "column", alignItems: "center"
                            }}>

                                <StyledButton onClick={async () => {
                                    await changePassword();
                                }} disabled={Object.values(errorState).some(x => (x !== ""))}>
                                    {t(`pages.settings.save_button`)}
                                </StyledButton>
                                {Object.values(errorState).some(x => (x !== "")) && <ErrorText>
                                    {Object.values(errorState).find(item => item !== "")}
                                </ErrorText>
                                }
                            </div>

                        </ContainerInput>
                    </FlexContent>
                </Content>
            </MobileContainer>
        </>
    );
};

export default Settings;


const ErrorText = styled(`div`)((props) => ({
    color: "#C01414",

}))


const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "center",
    width: "100%",

}))

const Content = styled(FlexContent)((props) => ({
    paddingTop: "50px",
}))

const Title = styled(`div`)((props) => ({
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: 600,
    fontFamily: "Roboto Condensed"
}))

const StyledButton = styled("button")<{ disabled?: boolean }>((props) => ({
    textTransform: "none",
    height: 38,
    width: "100%",
    borderRadius: 5,
    fontFamily: "Roboto Condensed",
    boxSizing: "border-box",
    color: "white",
    fontWeight: 400,
    fontSize: 16,
    background: "#829FF5",
    "&:hover": {
        background: "#6a86d3"
    }
}));


export const ContainerInput = styled(`div`)((props) => ({
    gap: 10,
    paddingTop: 20,
    maxWidth: 260,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box"
}));
