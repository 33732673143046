import React, {useEffect, useState} from 'react';
import {Container} from 'ui/containers';
import PromotionsFirstImg from "assets/images/promotions_first_img.png";
import PromotionsSecondImg from "assets/images/promotions_second_img.png";
import PromotionsThirdImg from "assets/images/promotions_third_img.png";
import styled from "styled-components";
import PromotionCard from 'ui/PromotionCard';
import {useTranslation} from 'react-i18next';
import FooterDesktop from "../components/wrapper/footer/FooterDesktop";
import {useActions} from "../hooks/useActions";
import {useLocation} from 'react-router-dom';
import {useTypedSelector} from "../hooks/useTypedSelector";
import {IPromotion} from "../store/types/appTypes";
import CustomModal from 'components/modal';
import Modal from 'ui/material-kit/Modal';
import PromotionModal from "../components/modal/PromotionModal";

const data = [
    {
        type: "first",
        img: PromotionsFirstImg,
        onClick: () => {
        }
    },
    {
        type: "second",
        img: PromotionsSecondImg,
        onClick: () => {
        }
    },
    {
        type: "third",
        img: PromotionsThirdImg,
        onClick: () => {
        }
    },


]

type PromotionsProps = {
    isDesktop?: boolean
}


const Promotions = (props: PromotionsProps): JSX.Element => {

    const [currentPromotion, setCurrentPromotion] = useState<IPromotion | undefined>();

    const {promotions} = useTypedSelector(state => {
        return ({
            promotions: state.app.promotions
        })
    });


    const {t} = useTranslation();
    return (<>
        {
            props.isDesktop ? <>
                <ContainerDes>
                    <Title>
                        {t(`common.navbar.nav_promotions`)}
                    </Title>
                    <div style={{marginTop: 55}}>
                        <Container>
                            <SwiperContainer>
                                {promotions.map((item, index) => (
                                    <PromotionCard title={item.title} content={item.content}
                                                   imageUrl={item.imageUrl}
                                                   link={item.link}
                                                   onClick={() => {
                                                       setCurrentPromotion(item)
                                                   }}
                                                   key={`promotions-card-${index}-${item.title}`}/>

                                ))}
                            </SwiperContainer>
                        </Container>
                    </div>
                </ContainerDes>

                <div style={{marginTop: "200px"}}>
                    <FooterDesktop/>
                </div>
            </> : <Container>
                <Content>
                    <Title>
                        {t(`common.navbar.nav_promotions`)}
                    </Title>
                    <ContentCard>
                        {promotions.map((item, index) => (
                            <React.Fragment key={`promotions-card-${index}-mobile`}>
                                <PromotionCard isDesktop={props.isDesktop} imageUrl={item.imageUrl}
                                               title={item.title}
                                               content={item.content}
                                               onClick={() => {
                                                   setCurrentPromotion(item)
                                               }}/>
                            </React.Fragment>
                        ))}
                    </ContentCard>
                </Content>
            </Container>
        }
        <PromotionModal promotion={currentPromotion} open={!!currentPromotion} onClose={() => setCurrentPromotion(undefined)} isDesktop={props.isDesktop}/>

    </>)


};

export default Promotions;

const ContainerDes = styled("div")((props) => ({
    paddingTop: 35,
    margin: "0px auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 1658
}))

const SwiperContainer = styled.div`
  width: max-content;
  max-width: 1074px;
  display: flex;
  justify-content: flex-start;
  align-content: center;

  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
`


const Content = styled("div")((props) => ({
    padding: "20px 10px 0 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
}))

const ContentCard = styled(Content)((props) => ({
    gap: 10
}))


const Title = styled("div")((props) => ({
    margin: "0 auto",
    fontSize: 24,
    textTransform: "uppercase",
    fontWeight: 400
}))
