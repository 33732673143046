import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTypedSelector} from 'hooks/useTypedSelector';
import {useNavigate} from "react-router-dom";
import {useActions} from "hooks/useActions";
import {useTranslation} from 'react-i18next';
import {useSocket} from "services/SocketService";
import axios from "api";
import {getStorage} from 'hooks/useStorage';

type InfoContainerProps = {
    isDesktop?: boolean
}

const InfoContainer = (props: InfoContainerProps): JSX.Element => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const socket = useSocket();
    const {setUserBalance} = useActions();
    const {user} = useTypedSelector(state => {
        return {
            user: state.user.user,
        }
    })

    const currency = getStorage("APP_OTHER.CURRENCY.KEY");


    useEffect(() => {
        axios.get(`/api/info/balance`).then((pld) => {
            setUserBalance(pld.data.balance);
        });

        socket.on("balance", pld => {
            console.log("balance", pld)
            setUserBalance(pld.balance);

        });
    }, [props.isDesktop]);

    return (
        <>
            {
                props.isDesktop ? (<>
                    <BlueContainer>
                        {`${t(`common.navbar.balance_info`)}: ${user?.balance?.toLocaleString()}`} {(user?.currency ?? currency).toUpperCase()}
                    </BlueContainer>
                </>) : (<>
                    <ContainerInfo onClick={() => {
                        navigate("/profile")
                    }}>
                        <Title>
                            {user?.login}
                        </Title>
                        <SecondText>
                            {` ${user?.balance?.toLocaleString()} `} {(user?.currency ?? currency).toUpperCase()}
                        </SecondText>
                    </ContainerInfo>
                </>)
            }

        </>
    );
};

export default InfoContainer;


const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
  text-align: right;
  justify-content: flex-start;
  align-items: flex-end;
,
cursor: pointer;
`

const Title = styled.div`
  color: #FDD115;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none
  }
  font-size: 13px;
`

const SecondText = styled.div`
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none
  }
  font-size: 13px;
`


const BlueContainer = styled.div`
  font-family: Roboto Condensed;
  padding: 5px 14px;
  font-size: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 400;
  background: #263154;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1540px) {
    font-size: 18px;
  }

`
