export interface ServerToClientEvents {
    noArg: () => void;
    basicEmit: (a: number, b: string, c: Buffer) => void;
    withAck: (d: string, callback: (e: number) => void) => void;
}


export interface ClientToServerEvents {
    hello: () => void;
}

export enum InputTypes {
    text = "text",
    password = "password"
}

export interface RoutingList {
    key: string
    lang_key: string
    to: string
    component?: JSX.Element
    mobileComponent?: JSX.Element
    isAuth: boolean
    iconJsx?: JSX.Element,
}


export interface ILanguage {
    "VISIBLE": boolean,
    "TITLE": string,
    "PATH": string,
    "DIRECTION": string,
    "ICON_PATH": string,
    "KEY": string
}
