import React, {useEffect} from 'react';
import styled from 'styled-components';
import {List} from "./list";
import {TopHallOfFame} from "./top-hall-of-fame";
import {devices} from "constants/breakpoints";

export const HallOfFame = () => {

    return (
        <Wrapper>
            <TopHallOfFame/>
            <ListContainer>
                <List/>
            </ListContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`

`

const ListContainer = styled.div`
  margin-bottom: 50px;

  ${devices.tablet} {
    margin-bottom: 10px;
  }

`

const Image = styled.div`

`
