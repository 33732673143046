import React from 'react'
import styled from 'styled-components'

type StyledButtonProps = {
    onClick?: () => void,
    isDisabled?: boolean,
    children: React.ReactNode,
    other?: object
    extra?: string,

}
const StyledButton = (props: StyledButtonProps) => {

    return (
        <CustomButton extra={props.extra} id={`styled-button`} onClick={props.onClick} disabled={props.isDisabled}
                      color={'secondary'}>
            {props.children}
        </CustomButton>
    );
};

export default StyledButton;

export const CustomButton = styled.button<{ extra?: string}>`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 48px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: white;
  background: #FFB401;
  border-radius: 5px;

  &:hover {
    background: #de9903;
  }

  &:active {
    background: #b98101;
  }

  &:disabled {
    cursor: default;
    background: #938a79;
  }

  ${({extra}) => extra}
`;
