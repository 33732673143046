import React from 'react';
import styled from "styled-components";
import Lottie from 'react-lottie-player';
import lottieJson from 'assets/lotties/work_loader.json'

const LottieWorkLoader = () => {
    return (

        <Lottie
            loop
            animationData={lottieJson}
            play
            speed={0.5}
            style={{width: 280, height: 300}}
            segments={[0, 100]}
        />

    );
};

export default LottieWorkLoader;


