export const unionArraysByKey = <T extends { [key: string]: any }>(arr1: T[], arr2: any[], key: string): T[] => {
    const merged: any = [];
    for (let i = 0; i < arr1.length; i += 1) {
        merged.push({
            ...arr1[i],
            ...(arr2.find((itmInner) => itmInner[key] === arr1[i][key]) || {}),
        });
    }
    return merged;
};
