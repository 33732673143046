import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import styled from 'styled-components';
import ArrowButton from "ui/ArrowButton";
import {useActions} from "../hooks/useActions";
import { useTranslation } from 'react-i18next';

type HorsesProps = {
    isDesktop?: boolean
}
const Horses = (props: HorsesProps): JSX.Element => {
    const [error, setError] = useState("");
    const {t} = useTranslation();
    const [dataGame, setDataGame] = useState("");
    const {setLoading} = useActions();
    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        setLoading(true);
        axios.get(`/api/json-rpc/create-session/wallet`).then(d => d.data).then(x => {
            if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
                window.location.href = x.SessionUrl;
            } else {
                setDataGame(x.SessionUrl);
                setLoading(false);
            }
        }).catch(error => {
            let {data} = error.response;
            setError(data.message);
            setLoading(false);
        })

    }, [location.pathname])

    if (!props.isDesktop) {
        return (
            <ContainerMobile>
                <Iframe src={dataGame} frameBorder={0} allowFullScreen={true} height={"100%"} width={"100%"}/>
                <ContainerButton title={`${t(`common.options.back_to_bwin`)}`}>
                    <ArrowButton styles={{transform: "rotate(-90deg)"}} onClick={() => {
                        navigate("/")
                    }}/>
                </ContainerButton>
            </ContainerMobile>

        );
    } else {
        return (

            <Container>
                <iframe title={"horses"} src={dataGame} frameBorder={0}
                        allowFullScreen={true} height={"100%"} width={"100%"}/>
            </Container>
        )
    }

};

export default Horses;

const ContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  height: calc(100vh - 145px);
`;


const ContainerButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px
`;


const Iframe = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

`;
