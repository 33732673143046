import axios from "../index"
import {IGetBets, IGetTransactions} from "./transactionTypes";

export const transactionApi = {

    async getTransactions(obj: IGetTransactions) {
        let pld = (await axios.get(`/api/transactions/player-operations`, {
            params: {
                page: obj?.page,
                size: obj?.size,
                type: obj?.type,
                range_end: obj?.range_end,
                range_start: obj?.range_start,

            },
        })).data;
        return pld;
    },

    async getBets(obj: IGetBets) {
        let pld = (await axios.get(`/api/transactions/player-bets`, {
            params: {
                page: obj?.page,
                size: obj?.size,
                range_end: obj?.range_end,
                range_start: obj?.range_start,

            },
        })).data;
        return pld;
    },

}
