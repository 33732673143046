import React, {useEffect, useState} from 'react';
import {IStaticPage} from 'store/types/appTypes';
import {useTypedSelector} from "../hooks/useTypedSelector";
import {appAPI} from "../api/app/appApi";
import {Container} from "../ui/containers";
import styled from "styled-components";

type StaticPageProps = {
    id?: number
}

const StaticPage = (props: StaticPageProps) => {
    const [pageContent, setPageContent] = useState<IStaticPage>();
    const {pages, getCurrentPage} = useTypedSelector(state => {
        return {
            pages: state.app.pages,
            getCurrentPage: (id: number) => {
                return pages.find(item => item.id === id);
            }
        }
    })

    useEffect(() => {
        if (props.id) {
            let curP = getCurrentPage(props.id);
            if (curP) {
                setPageContent(curP);
            } else {
                appAPI.getStaticPage(props.id).then(pld => {
                    setPageContent(pld);
                })
            }
        }

    }, [props.id]);

    return (
        <Container>
            {
                pageContent &&
                <Content>
                    <Title>
                        {pageContent.title}
                    </Title>
                    <div dangerouslySetInnerHTML={{__html: pageContent.content}}/>
                </Content>
            }

        </Container>
    );
};

export default StaticPage;


const Content = styled("div")((props) => ({
    padding: "20px 10px 0 10px"
}))


const Title = styled("div")((props) => ({
    display: "flex",
    justifyContent: "center",
    fontSize: 24,
    margin: "0 auto",
    textOverflow: "ellipsis",
    maxWidth: 280,
    overflow: "hidden",
    textTransform: "uppercase",
    fontWeight: 400
}))
