/*eslint-disable*/
import {useEffect, useState} from 'react'

export type Status = 'idle' | 'loading' | 'ready' | 'error'
export type ScriptElt = HTMLScriptElement | null
declare const window: any;

function useScript(src: string, isTawk?: boolean): Status {
    const [status, setStatus] = useState<Status>(src ? 'loading' : 'idle')

    useEffect(
        () => {
            if (!src) {
                setStatus('idle')
                return
            }

            let script: ScriptElt = document.querySelector(`script[src="${src}"]`)

            if (!script) {

                script = document.createElement('script')
                script.src = src
                script.async = true

                if (isTawk) {
                    window.Tawk_API = window.Tawk_API || {};
                    window.Tawk_LoadStart = new Date();

                    script.setAttribute('crossorigin', '*');
                    let script2 = document.getElementsByTagName("script")[0];
                    // @ts-ignore
                    script2.parentNode.insertBefore(script, script2);

                    window.Tawk_API.onLoad = function () {
                        window.Tawk_API.hideWidget();
                    };
                }

                script.setAttribute('data-status', 'loading')
                document.body.appendChild(script)
                const setAttributeFromEvent = (event: Event) => {
                    script?.setAttribute(
                        'data-status',
                        event.type === 'load' ? 'ready' : 'error',
                    )
                }

                script.addEventListener('load', setAttributeFromEvent)
                script.addEventListener('error', setAttributeFromEvent)
            } else {
                setStatus(script.getAttribute('data-status') as Status)
            }

            const setStateFromEvent = (event: Event) => {
                setStatus(event.type === 'load' ? 'ready' : 'error')
            }

            script.addEventListener('load', setStateFromEvent)
            script.addEventListener('error', setStateFromEvent)

            return () => {
                if (script) {
                    script.removeEventListener('load', setStateFromEvent)
                    script.removeEventListener('error', setStateFromEvent)
                }
            }
        },
        [src],
    )

    return status
};

export default useScript;
/*eslint-enable*/
