import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import GoldenButton from "../../../ui/GoldenButton";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {getStorage} from "../../../hooks/useStorage";
import styled from 'styled-components';
import {ReactComponent as CashbackIcon} from "assets/images/profile_cashback-icon.svg"
import {useTranslation} from "react-i18next";
import LottieDotsLoader from "../../../ui/loaders/DotsLottieLoader";
import {useActions} from "../../../hooks/useActions";

type CashbackButtonProps = {}

const CashbackButton = (props: CashbackButtonProps) => {
    const [isStart, setIsStart] = useState(false);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [deadline, setDeadline] = useState<number>(0);
    const {t, i18n} = useTranslation();
    const {getCashbackInfo} = useActions();

    const {cashbackInfo, loadingCashbackInfo, user} = useTypedSelector(state => {
        return {
            cashbackInfo: state.user.cashbackInfo,
            loadingCashbackInfo: state.user.loadingCashbackInfo,
            user:  state.user.user
        }
    });

    const getTwoDigitalNumber = (num: number) => {
        return num < 10 ? `0${num}` : num;
    }
    const getTime = (dead: number) => {
        if (!loadingCashbackInfo) {
            const time = dead - Date.now();
            if (time / 1000 > 0) {
                setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
                setMinutes(Math.floor((time / 1000 / 60) % 60));
                setSeconds(Math.floor((time / 1000) % 60));
            } else {
                setTimeout(() => {
                    getCashbackInfo();
                }, 1000)
                setIsStart(false);
            }
        }
    };


    useEffect(() => {
        if (cashbackInfo) {
            setIsStart(true);
            getTime(Date.now() + cashbackInfo.timeLeft * 1000)
            setDeadline(Date.now() + cashbackInfo.timeLeft * 1000);
        }

    }, [cashbackInfo]);


    useEffect(() => {
        if (isStart) {
            getTime(deadline);
            const interval = setInterval(() => getTime(deadline), 1000);
            return () => clearInterval(interval);
        }

    }, [deadline, isStart]);


    useEffect(() => {
        getCashbackInfo();
    }, [window.location]);


    const navigate = useNavigate();
    const currency = getStorage("APP_OTHER.CURRENCY.KEY");
    return (
        <>
            {
                cashbackInfo?.isAvailable && !cashbackInfo?.isReceived? <GoldenButton isAnimated onClick={() => {
                    navigate("/profile/cashback");
                }}>
                    <div style={{whiteSpace: "nowrap"}}>
                        {cashbackInfo.amount.toLocaleString()} {(user?.currency ?? currency).toUpperCase()}
                    </div>
                    <b style={{marginLeft: 5}}>GET</b>
                </GoldenButton> : <Container onClick={() => {
                    navigate("/profile/cashback");
                }} title={t(`pages.cashback.no_description`)}>
                    {!loadingCashbackInfo ? <>
                        <div>
                            {getTwoDigitalNumber(hours)}:{getTwoDigitalNumber(minutes)}:{getTwoDigitalNumber(seconds)}
                        </div>
                        <CashbackIcon style={{width: 28, height: 28}}/>
                    </> : <>
                        <LottieDotsLoader/>
                    </>}
                </Container>
            }
        </>

    );
};

export default CashbackButton;


const Container = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 21px;
  line-height: 10px;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  height: 38px;
  width: 100px;
  gap: 5px;
  border: 1px solid #FFB401;
  color: white;
  background: ${props => props.theme.palette.primary.main};
  padding: 3px 10px;
  @media (max-width: 1540px) {
    height: 32px;
  }

  @media (max-width: 500px) {
    height: 32px;
    width: 111px;
    padding: 2px 2px;
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 370px) {
    height: 32px;
    width: 100px;
    font-size: 13px;
    line-height: 8px;
    padding: 1px 2px;
  }
`
