import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

type GoldTimeProps = {
    initialTime: number | undefined,
    timeEndCallBack: () => void,

};

const GoldTime = (props: GoldTimeProps) => {
    const {t} = useTranslation();
    const [isStart, setIsStart] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [deadline, setDeadline] = useState<number>(0);

    const getTwoDigitalNumber = (num: number) => {
        return num < 10 ? `0${num}` : num;
    }

    const getTime = async (dead: number) => {
        if (!isLoading) {
            const time = dead - Date.now();
            if (time / 1000 > 0) {
                setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
                setMinutes(Math.floor((time / 1000 / 60) % 60));
                setSeconds(Math.floor((time / 1000) % 60));
            } else {
                await props.timeEndCallBack();
                setIsLoading(true);
                setIsStart(false);
            }
        }
    };


    useEffect(() => {
        if (props.initialTime) {
            setIsLoading(false);
            setIsStart(true);
            getTime(Date.now() + props.initialTime * 1000)
            setDeadline(Date.now() + props.initialTime * 1000);
        }


    }, [props.initialTime]);


    useEffect(() => {
        if (isStart) {
            getTime(deadline)
            const interval = setInterval(() => getTime(deadline), 1000);
            return () => clearInterval(interval);
        }

    }, [deadline, isStart]);


    return (
        <Container>
            <TimeBlock>
                <YellowTimeBlock isLoading={isLoading}>
                    {getTwoDigitalNumber(hours)}
                </YellowTimeBlock>
                <div style={{fontWeight: 400}}>
                    {t(`common.info.hours`)}
                </div>
            </TimeBlock>
            <TimeBlock>
                <YellowTimeBlock isLoading={isLoading}>
                    {getTwoDigitalNumber(minutes)}
                </YellowTimeBlock>
                <div style={{fontWeight: 400}}>
                    {t(`common.info.minutes`)}
                </div>
            </TimeBlock>
            <TimeBlock>
                <YellowTimeBlock isLoading={isLoading}>
                    {getTwoDigitalNumber(seconds)}
                </YellowTimeBlock>
                <div style={{fontWeight: 400}}>
                    {t(`common.info.seconds`)}
                </div>
            </TimeBlock>
        </Container>
    );
}

export default GoldTime;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
`


const TimeBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: white;
`


const YellowTimeBlock = styled.div<{ isLoading: boolean }>`
  width: 59px;
  color: black;
  height: 50px;
  left: 148px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  background: conic-gradient(from 0deg at 50% 50%, ${props => !props.isLoading ? "#FFB401" : "#646464FF"} 0deg, ${props => !props.isLoading ? "#FFED4F" : "#A9A9A9FF"} 360deg);
  border-radius: 5px;

`
