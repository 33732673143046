import React, {useEffect, useRef, useState} from 'react';

import styled, {css} from "styled-components";
import {useLocation} from "react-router-dom";
import {useActions} from "../hooks/useActions";
import {betbyService} from "../services/betbbyService";
import {useTypedSelector} from "../hooks/useTypedSelector";
import axios from 'api';
import {useTranslation} from "react-i18next";
import useScript from 'hooks/useScript';
import { getStorage } from 'hooks/useStorage';

declare const BTRenderer: any;

type SportsProps = {
    isDesktop?: boolean,
    betBy?: any,
    setBetBy?: (betBy: any) => void,
    type: string,
}


const Sport = ({isDesktop, type, betBy, setBetBy}: SportsProps): JSX.Element => {
    const {setLoading, updateModal, updateAlert} = useActions();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const {user} = useTypedSelector(state => {
        return ({
            user: state.user.user,
        })
    });
    const BETBY = getStorage("APP_SERVICES.BETBY");
    const status = useScript(BETBY.BETSLIP_URL);

    useEffect(() => {
        if (typeof BTRenderer !== 'undefined') {
            console.log("BTRenderer", BTRenderer)
        }
    }, [status])



    useEffect(() => {
        let bbSettings = {
            url: type === "live-sport" ? "/live" : "",
            lang: i18n.language,
            stickyTop: isDesktop ? 138 : 60,
            betSlipOffsetTop: isDesktop ? 138 : 60,
            onRecharge: () => updateAlert({
                isOpen: true,
                status: "warning",
                message: `${t(`common.alert.deposit_error`)}`
            }),
            onSessionRefresh: () => {
                window.location.reload()
            },
            onTokenExpired: async () => {
                const {data} = await axios.get("/api/json-rpc/create-session/betby");
                return data.token;
            },
            onLogin: () => updateModal(true),
            onRegister: () => updateModal(true)
        }
        if (status === "ready") {
            setLoading(true);
            if (user) {
                axios.get("/api/json-rpc/create-session/betby").then(x => x.data).then(x => {
                    if (setBetBy) {
                        setBetBy(new betbyService(x.token ?? "", {
                            ...bbSettings
                        }));
                    }
                    setLoading(false)
                })
            } else {
                if (setBetBy) {
                    setBetBy(new betbyService("", {
                        ...bbSettings
                    }));
                }
                setLoading(false)
            }
        } else {
            setLoading(true);
        }


    }, [user, i18n.language, status]);

    useEffect(() => {
        if (betBy) {
            betBy.update({
                url: type === "live-sport" ? "/live" : "/",
                lang: i18n.language,
                stickyTop: isDesktop ? 138 : 60,
                betSlipOffsetTop: isDesktop ? 138 : 60,
            })
        }

    }, [type])

    return (
        <Container isDesktop={isDesktop}>

        </Container>
    );
};

export default Sport;


const Container = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  ${({isDesktop}) =>
          !isDesktop &&
          css`
            width: 100%;
            height: 100%;
          `}
`;
