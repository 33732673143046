import {createEffectorField, useCreateMutation} from "helpers/effector";
import {highRollerFX} from "./high-roller.effects";
import {IHighRoller} from "./high-roller.types";
import {useStore} from "effector-react";
import {createEvent, sample} from "effector";

export const mergeHighRollersEvent = createEvent<IHighRoller>();

export const [$highRollers, highRollersChanged] = createEffectorField<IHighRoller[]>({
    defaultValue: []
});

$highRollers.on(highRollerFX.doneData, (_, payload) => payload);


const mergeHighRoller = (newHighRoller: IHighRoller, highRollers: IHighRoller[]): IHighRoller[] => {
    let newHighRollers: IHighRoller[] = [];

    let oldHighRoller = highRollers.find((highRoller) => (highRoller.login === newHighRoller.login) &&
        (highRoller.game.Id === newHighRoller.game.Id));

    if (oldHighRoller) {

        newHighRollers = highRollers.filter((highRoller) => (highRoller.login !== newHighRoller.login) ||
            (highRoller.game.Id !== newHighRoller.game.Id));

        newHighRollers.push(newHighRoller);
    } else {
        newHighRollers = ([...highRollers, newHighRoller]);
    }
    return newHighRollers.sort((a, b) => b.coeff - a.coeff);
}


sample({
    clock: mergeHighRollersEvent,
    source: $highRollers,
    fn: (highRollers, newHighRoller) => mergeHighRoller(newHighRoller, highRollers),
    target: highRollersChanged
})




export const useHighRollers = () => useStore($highRollers);
export const useHighRollerMutation = () => useCreateMutation(highRollerFX);



