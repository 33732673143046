import React, {useState} from 'react';
import Modal from "../../ui/material-kit/Modal";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import styled from "styled-components";
import {useTranslation} from 'react-i18next';
import TextField from "../../ui/material-kit/TextField";
import {useInput} from "../../hooks/useInput";
import StyledButton, {CustomButton} from "../../ui/StyledButton";
import {appAPI} from "../../api/app/appApi";
import {useActions} from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";

interface helperModalProps {
    isOpen: boolean,
    isDesktop: boolean,
    onClose: () => void
}

const HelpModal = (props: helperModalProps) => {
    const {isOpen, isDesktop, onClose} = props
    const [isComplete, setComplete] = useState(false)
    const {error} = useTypedSelector(state => {
        return {
            error: state.app.alert.message,
        }
    })
    const {t} = useTranslation();
    const fields = {
        title: t(`common.help.help`),
        name: t(`common.help.name`),
        phone: t(`common.help.email`),
        message: t(`common.help.message`),
        thanks: t(`common.help.thanks`),
        we_will_contact: t(`common.help.we_will_contact`),
        send: t(`common.help.send`),
    }
    const {updateAlert} = useActions();

    const [state, setState] = useState({
        name: '',
        phone: '',
        message: ''
    })

    const [errorState, setErrorState] = useState({
        name: '',
        phone: '',
        message: ''
    })

    const handleSend = () => {
        let errors = {
            name: '',
            phone: '',
            message: ''
        }
        if (!state.name.match(/^.{1,100}$/)) {
            errors.name = t("common.errors.name_must_contain");
        }
        if (!state.message.match(/^.{1,500}$/)) {
            errors.message = t("common.errors.message_must_contain");
        }
        if (!state.phone.includes("+")) {
            errors.phone = t("common.errors.phone_must_contain_plus");
        }
        if (!state.phone.match(/^.{11,13}$/)) {
            errors.phone = t("common.errors.phone_must_contain_digits");
        }



        if (Object.values(errors).some(item => item !== "")) {
            setErrorState(errors);
        } else {
            appAPI.sendFeedback({name: state.name, phone: state.phone, messageContent: state.message}).then(() => {
                updateAlert({
                    status: '',
                    message: '',
                    isOpen: false
                })
                setComplete(true)
                setState({name: '', phone: '', message: ''})
            })
        }
    }

    return (
        <Modal
            open={isOpen}
            isFullScreen={!isDesktop}
            onClose={() => {
                setComplete(false)
                onClose()
            }}>
            <Background isDesktop={!isDesktop}>
                <ModalWrapper>
                    {!isComplete ? <>
                            <TitleContainer>
                                <Title>{fields.title}</Title> <ExitIcon url={CrossIcon} onClick={() => {
                                setComplete(false)
                                props.onClose()
                            }}/>
                            </TitleContainer>
                            <Container>
                                <div style={{position: "relative", width: "100%"}}>
                                    {errorState.name !== "" && <ErrorBlock>
                                        {errorState.name}
                                    </ErrorBlock>}
                                    <TextField placeholder={fields.name} value={state.name} extra={InputStyle}
                                               onChange={(e) => {
                                                   setState({...state, name: e.target.value});
                                                   setErrorState({...errorState, name: "" })
                                               }}/>
                                </div>
                                <div style={{position: "relative", width: "100%"}}>
                                    {errorState.phone !== "" && <ErrorBlock>
                                        {errorState.phone}
                                    </ErrorBlock>}
                                    <TextField placeholder={fields.phone} value={state.phone} extra={InputStyle}
                                               onChange={(e) => {
                                                   setState({...state, phone: e.target.value});
                                                   setErrorState({...errorState, phone: "" })
                                               }}/>
                                </div>
                                <div style={{position: "relative", width: "100%"}}>
                                    {errorState.message !== "" && <ErrorBlock>
                                        {errorState.message}
                                    </ErrorBlock>}
                                    <TextAreaSpan isDesktop={isDesktop}>
                                        <TextArea placeholder={fields.message} value={state.message}
                                                  onChange={(e) => {
                                                      setState({...state, message: e.target.value});
                                                      setErrorState({...errorState, message: "" });
                                                  }}/>
                                    </TextAreaSpan>

                                </div>


                                <StyledButton isDisabled={Object.values(errorState).some(item => item !== "")} extra={`width: 264px`} onClick={() => {
                                    handleSend()
                                }}>{fields.send}</StyledButton>
                            </Container>
                        </>
                        :
                        <>
                            <TitleContainer style={{height: '80px'}}>
                                <ExitIcon url={CrossIcon} onClick={() => {
                                    setComplete(false)
                                    props.onClose()
                                }}/>
                            </TitleContainer>
                            <CompleteContainer>
                                <div style={{fontWeight: 700}}>{fields.thanks}</div>
                                <div>{fields.we_will_contact}</div>
                            </CompleteContainer>
                        </>
                    }
                </ModalWrapper>
            </Background>
        </Modal>
    );
};

export default HelpModal;

const TextAreaSpan = styled.span<{ isDesktop?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;
  background-color: ${props => props.theme.palette.primary.main};
  border: unset;
  padding: unset;
  border-radius: 4px;
  border: 1px solid #434550;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  :hover {
    border: 1px solid #dadada;
  }

  height: 200px;
`

const TextArea = styled.textarea<{ isDesktop?: boolean }>`
  resize: none;

  &:focus {
    outline: none;
  }

  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  margin: 0;
  font-size: inherit;
  color: white;
  border: unset;
  background: transparent;
  box-sizing: border-box;
  padding: 12px 10px;
`

const ExitIcon = styled.div<{ url: string }>`
  position: absolute;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  top: 20px;
`

const Background = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  width: ${props => props.isDesktop ? "100%" : "700px"};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  overflow: hidden;
  border: ${props => !props.isDesktop ? "3px solid #263154" : "none"};
  box-sizing: border-box;
`

const ModalWrapper = styled.div<{ isDesktop?: boolean }>`
  padding: 22px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
          max-height: 70vh;
          width: 1074px;
          box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);         
          `
    }
  }}
`

const TitleContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  margin-top: -35px;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 125px;
`

const Title = styled("p")<{ isDesktop?: boolean }>`
  color: white;
  font-size: 26px;
  text-align: center;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 25px;
  max-width: 1000px;
`

const CompleteContainer = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: ${props => !props.isDesktop ? "20px" : "28px"};
  background: ${props => props.theme.palette.primary.main};
  border: 1px solid #434550;
  border-radius: 5px;
  padding: 30px 0px;
`

const Container = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
      align-items: center;
      justify-content: center;
      flex-direction: column;     
          `
    }
  }}
`

const InputStyle = `
  height: 75px;
  @media (max-width: 700px) {
    height: 54px;
  }
`

const ErrorBlock = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  z-index: 1000;
  color: red;
`
