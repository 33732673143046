import React from 'react';
import styled from 'styled-components';
import {useHallOfFame} from "../model/hall-of-fame.store";
import {FameItem} from "./fame-item";

export const List = () => {
    const hallOfFameList = useHallOfFame();
    return (
        <Wrapper>
            {
                hallOfFameList.map((item, index) => (
                    <React.Fragment key={`${item.createdAt}-hallOfFame-${index}`}>
                        <FameItem item={item} index={index + 1}/>
                    </React.Fragment>
                ))
            }
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 50px ;
`
