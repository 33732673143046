import React from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useActions} from "hooks/useActions";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useTranslation} from "react-i18next";
import SocialLinks from "ui/SocialLinks";
import {ReactComponent as PrivacyIcon} from "assets/images/drawer_privacy_icon.svg";

import {getStorage} from "../../../hooks/useStorage";
import routing from "../../../constants/routing";
import {RoutingList} from "../../../constants/types";

interface Link {
    title: string,
    path: string,
    auth: boolean
}

type ListProps = {
    title: string,
    isAuth: boolean,
    links: Link[]
}

const List = (props: ListProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {updateAlert} = useActions();
    const openAlert = () => {
        updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_bet_error`)}`})
    }
    return (
        <FlexContainer>
            <Title>
                {`${props.title}`}
            </Title>
            {
                props.links.map((item, index) => (
                    <React.Fragment key={`${index}-${item.title}-list`}>
                        <Item title={item.title} onClick={() => {
                            if (!item.auth) {
                                navigate(item.path)
                            } else {
                                if (props.isAuth) {
                                    navigate(item.path)
                                } else {
                                    openAlert();
                                }
                            }
                        }}>
                            {`${item.title}`}
                        </Item>
                    </React.Fragment>
                ))
            }
        </FlexContainer>
    );
};


const FooterDesktop = () => {
    const {t, i18n} = useTranslation();

    const {user, pages} = useTypedSelector(state => {
        return {
            user: state.user.user,
            pages: state.app.pages.filter(item => item.lang === i18n.language).map((item) => ({
                title: item.title,
                path: `/static/${item.url}/${item.lang}`,
                auth: false
            })),
        }
    })
    const APP_ACCESS = getStorage("APP_ACCESS");
    const APP_OTHER = getStorage("APP_OTHER");
    const APP_SEO = getStorage("APP_SEO");
    const SOCIAL_LINKS = APP_OTHER.SOCIAL_LINKS;
    const socialLinksArr: any = Object.values(SOCIAL_LINKS).filter((item: any) => item.VISIBLE);
    const TITLE = APP_SEO.TITLE;
    const SITE_NAME = APP_SEO.SITE_NAME;
    const HEADER = getStorage("APP_OTHER.HEADER");
    const FOOTER = getStorage("APP_OTHER.FOOTER");
    const currency = APP_OTHER.CURRENCY.KEY;
    const LINKS = HEADER.MENU.LINKS;

    const getCur = () : {amount: number, currency: string} => {
        const curCur =  user?.currency ?? currency;
        const amount = curCur === "TND" ? 150000 : curCur === "USD" ? 50000 : 180000;

        return {amount, currency: curCur}
    }
    return (
        <Wrapper>
            <Container>
                <ContainerBar>
                    {
                        APP_ACCESS.STATIC_PAGES.VISIBLE &&
                        <List title={t("common.info.info")} links={pages} isAuth={!!user}/>
                    }

                    <List title={t("common.info.bets")}
                          links={LINKS.map((it: string) => routing.find(item => item.key === it)).map((item: RoutingList, index: number) => ({
                              title: t(`common.navbar.nav_${item.lang_key}`),
                              path: item.to,
                              auth: item.isAuth
                          }))} isAuth={!!user}/>
                    <FlexContainer>
                        <Title>
                            {t(`common.info.responsible_bets`)}
                        </Title>
                        <DesktopPrivacyIcon/>
                        <Description>
                            {t(`common.info.attention_2`, {title: SITE_NAME})}

                        </Description>
                    </FlexContainer>
                    {socialLinksArr.length !== 0 && <FlexContainer>
                        <Title>
                            {t(`common.info.social_media`)}
                        </Title>
                        <SocialLinks isDesktop/>
                    </FlexContainer>}
                </ContainerBar>
                <Copyright>
                    {
                        FOOTER.ATTENTION_MAX_BALANCE.VISIBLE && <Attention style={{color: "#FFB401"}}>
                            *** {t(`common.info.attention_max_balance`, getCur())} ***
                        </Attention>
                    }
                    <Attention style={{color: "white"}}>
                        {t(`common.options.copyright`, {title: TITLE, year: new Date().getFullYear()})}
                    </Attention>
                </Copyright>

            </Container>
        </Wrapper>
    );
};

export default FooterDesktop;

const Attention = styled.div`
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  border-top: 2px solid #39466D;
  background: ${props => props.theme.palette.primary.dark};
`

const DesktopPrivacyIcon = styled(PrivacyIcon)`
  height: 100px;
  width: 100px;

  & > path {
    stroke-width: 1
  }

`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1920px;
  padding: 20px 80px;
`
const ContainerBar = styled.div`
  display: flex;
  justify-content: space-between;
`


const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 320px;
`

const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
`

const Copyright = styled.div`
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

`


const Description = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  max-width: 317px;
`

const Item = styled.div`
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  text-overflow: ellipsis;
  line-height: 25px;
  max-width: 317px;
  overflow: hidden;

  :hover {
    color: rgba(255, 255, 255, 0.7);
  }

  :active {
    color: rgba(255, 255, 255, 1);
  }
`
