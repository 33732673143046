import React from 'react';
import {format} from 'date-fns'
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import formatDate from "../helpers/formatDate";


type HistoryItemProps = {
    provider: string,
    game: string
    amount: string,
    date: string,
    status: string,
    access?: boolean
}

const HistoryItem = (props: HistoryItemProps): JSX.Element => {
    const {t, i18n} = useTranslation();
    return (
        <Container id={`history-item`}>
            <ContainerRow>
                <ContainerColumn>
                    <TitleContainer>
                        {`${t(`common.transaction_card.provider`)}`}
                    </TitleContainer>
                    <TextContainer>
                        {`${props.provider}`} & {`${props.game}`}
                    </TextContainer>
                </ContainerColumn>
                <ContainerColumn>
                    <TitleContainer style={{textAlign: "right"}}>
                        {t(`common.transaction_card.amount`)}
                    </TitleContainer>
                    <TextContainer style={{textAlign: "right"}}>
                        {`${props.amount}`}
                    </TextContainer>
                </ContainerColumn>
            </ContainerRow>
            <ContainerRow>
                <ContainerColumn>
                    <TitleContainer>
                        {t(`common.transaction_card.date`)}
                    </TitleContainer>
                    <TextContainer>
                        {`${formatDate({
                            date: new Date(props.date),
                            dateFormat: `dd.MM.yyyy HH:mm`,
                            lang: i18n.language
                        })}`}
                    </TextContainer>
                </ContainerColumn>
                <ContainerColumn>
                    <TitleContainer style={{textAlign: "right"}}>
                        {t(`common.transaction_card.status`)}
                    </TitleContainer>
                    <TextContainer style={props.access ? {color: "#FDD115", textAlign: "right"} : {
                        color: "#ffffff",
                        textAlign: "right"
                    }}>
                        {`${props.status}`}
                    </TextContainer>
                </ContainerColumn>

            </ContainerRow>
        </Container>
    );
};

export default HistoryItem;


const Container = styled(`div`)((props) => ({
    background: props.theme.palette.primary.main,
    borderRadius: 5,
    padding: "10px 16px",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10
}));

const ContainerRow = styled(`div`)((props) => ({
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
}));
const ContainerColumn = styled(`div`)((props) => ({
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",

}));

const TitleContainer = styled(`div`)((props) => ({
    fontSize: 12,
    fontWeight: "bold",
    color: "#666873",
}));

const TextContainer = styled(`div`)((props) => ({
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    color: "#FFFFFF"
}));
