import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import settings from "./settings";

const languages: {
    "VISIBLE": boolean,
    "TITLE": string,
    "PATH": string,
    "DIRECTION": string,
    "ICON_PATH": string,
    "KEY": string
}[] = Object.values(settings.SETTINGS.OTHER.LANGUAGES);

const resources = languages.filter(item => item.VISIBLE).reduce((a: any, {KEY, PATH}, index) => {
    a[KEY] = {translation: require(`./${PATH}`)};
    return a
}, {})



i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: languages.filter(item => item.VISIBLE)[0].KEY,
        react: {
            useSuspense: false
        },
        debug: true,
        detection: {
            order: ['queryString', 'cookie'],
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        }
    }).then(r => {
    console.log("initialize language success", r)
})

export default i18n;
