import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Arrow from "assets/images/desktop_menu_down_arrow_icon.svg"
import useOutsideClick from "../../hooks/useOutsideClick";

type AccordionProps = {
    otherProps?: any,
    title: string,
    open?: boolean,
    onChange?: () => void,
    extra?: string,
    extraContent?: string,
    effect?: any,
    children: React.ReactNode
}

const Accordion = (props: AccordionProps) => {

    const {extra = ``, onChange = () => {}, open = false, extraContent = ``, title = `` } = props;
    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState("0px");

    useEffect(() => {
        setHeight(!open ? "0px" : `${contentRef.current?.scrollHeight}px`);

    }, [open, props.effect])

    return (
        <AccordionSection id={`accordion-${title}`} {...props.otherProps }>
            <AccordionHead
                extra={extra}
                onClick={onChange}
            >
                <Title>{props.title}</Title>
                <span style={{margin: "5px", transform: `rotate(${open ? "180deg" : "0deg"})`}}>
                        <ArrowIcon src={Arrow} alt={`arrow-icon`}/>
                    </span>
            </AccordionHead>
            <AccordionContent

                ref={contentRef}
                style={{maxHeight: `${height}`}}
            >
                <ExtraContent  extra={extraContent}>
                    {props.children}
                </ExtraContent>
            </AccordionContent>
        </AccordionSection>
    );
}


export default Accordion;


const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto Condensed;
`

const Title = styled.div`

`

const ExtraContent = styled.div<{ extra: string }>`
  box-sizing: border-box;
  width: 100%;
  ${({extra}) => extra};
`

const AccordionContent = styled.div`
  background-color: ${({theme}) => theme.palette.primary.main};
  overflow: hidden;
  transition: max-height 0.3s ease;
  width: 100%;
`

const ArrowIcon = styled.img`

`

const AccordionHead = styled.div<{ isActive?: boolean, extra: string }>`
  background-color: ${({theme}) => theme.palette.primary.main};
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  :hover {
    background-color: ${({theme}) => theme.palette.primary.dark};
  }

  transition: background-color 0.3s ease;

  ${({extra}) => extra};
`
