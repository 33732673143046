import React from 'react';

import styled from 'styled-components';
import formatDate from "../helpers/formatDate";
import {useTranslation} from "react-i18next";

type OperationItemProps = {
    type?: string,
    amount: string,
    date: string,
    profit?: string,
    limit?: string,
    totalDeposit?: string,
    isCashback?: boolean
}

const OperationItem = (props: OperationItemProps): JSX.Element => {
    const {t, i18n} = useTranslation();
    return (
        <Container id={`operator-items`}>
            <ContainerRow>
                <ContainerColumn>
                    {
                        props.type ?
                            <OperationType>
                                {`${props.type}`}
                            </OperationType>
                            :
                            <TitleContainer>
                                {t("common.transaction_card.date")}
                            </TitleContainer>
                    }

                    <TextContainer>
                        {`${formatDate({
                            date: new Date(props.date),
                            dateFormat: `dd.MM.yyyy HH:mm`,
                            lang: i18n.language
                        })}`}
                    </TextContainer>
                </ContainerColumn>
                {
                    props.isCashback && <>
                        <ContainerColumn>
                            <TitleContainer style={{textAlign: "right"}}>
                                {t("common.transaction_card.profit")}
                            </TitleContainer>
                            <TextContainer style={{textAlign: "right"}}>
                                {`${props.profit}`}
                            </TextContainer>
                        </ContainerColumn>
                        <ContainerColumn>
                            <TitleContainer style={{textAlign: "right"}}>
                                {t("common.transaction_card.total_deposit")}
                            </TitleContainer>
                            <TextContainer style={{textAlign: "right"}}>
                                {`${props.totalDeposit}`}
                            </TextContainer>
                        </ContainerColumn>
                        <ContainerColumn>
                            <TitleContainer style={{textAlign: "right"}}>
                                {t("common.transaction_card.limit")}
                            </TitleContainer>
                            <TextContainer style={{textAlign: "right"}}>
                                {`${props.limit}`}
                            </TextContainer>
                        </ContainerColumn>

                    </>
                }
                <ContainerColumn>
                    <TitleContainer style={{textAlign: "right"}}>
                        {t("common.transaction_card.amount")}
                    </TitleContainer>
                    <TextContainer style={{textAlign: "right"}}>
                        {`${props.amount}`}
                    </TextContainer>
                </ContainerColumn>
            </ContainerRow>
        </Container>
    );
};

export default OperationItem;


const Container = styled(`div`)((props) => ({
    background: props.theme.palette.primary.main,
    borderRadius: 5,
    boxSizing: "border-box",
    padding: "10px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10
}));

const ContainerRow = styled(`div`)((props) => ({
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "space-between",
}));
const ContainerColumn = styled(`div`)((props) => ({
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "center",

}));
const TitleContainer = styled(`div`)((props) => ({
    fontSize: 12,
    fontWeight: "bold",
    color: "#666873",
    "@media (max-width: 768px)": {
        fontSize: 10,
    }
}));

const OperationType = styled(TitleContainer)((props) => ({
    fontSize: 18,
    fontFamily: "Roboto Condensed",
    fontWeight: 600,
    color: "#ffffff",
}));

const TextContainer = styled(`div`)((props) => ({
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    color: "#FFFFFF",
    "@media (max-width: 768px)": {
        fontSize: 14,
    }
}));
