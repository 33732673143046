import {ProvidersImages} from "./providersImages";


type ProviderImageProps = {
    provider: string,
    isTvProvider?: boolean
}
const getProviderImage = (props: ProviderImageProps) => {
    const Obj = ProvidersImages.find(item => item.name.toLowerCase() === props.provider.toLowerCase());
    if (!Obj) {
        return undefined
    }
    return require(`../../assets/providersImages/${Obj.image}`)

}


export default getProviderImage;
