import {format} from 'date-fns'
import {enGB, fr, he, tr} from 'date-fns/locale'

const locales = {en: enGB, fr: fr, he: he, tr: tr}

const formatDate = ({date, dateFormat, lang}: {
    date?: Date, dateFormat?: string, lang?: string
}): string | undefined => {

    let curDate = date ?? new Date();
    let curFormat = dateFormat ?? "MM/dd/yyyy";
    let curLang = lang ?? "en";


    return format(curDate, curFormat, {
        // @ts-ignore
        locale: locales[curLang]
    })

}

export default formatDate;
