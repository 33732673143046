import {gamesAPI} from "api/games/gamesApi";
import {Dispatch} from "redux";
import {
    gameAction,
    GameActionTypes as types,
    ICategoryGame,
    IGame,
    IProviderGame,
    IsFavoriteGame, ITvGame
} from "../types/gamesTypes";
import {IGetGames, IGetTvGames} from "api/games/gamesTypes";


////////////////////////////////////////////////////////
//LOAD//GET

const loadGames_ = (): gameAction => {
    return {
        type: types.LOAD_GAMES
    }
}

const loadTvGames_ = (): gameAction => {
    return {
        type: types.LOAD_TV_GAMES
    }
}

const loadGamesFailed = (error: string): gameAction => {
    return {
        type: types.LOAD_GAMES_FAIL,
        error
    }
}

const loadTvGamesFailed = (error: string): gameAction => {
    return {
        type: types.LOAD_TV_GAMES_FAIL,
        error
    }
}

const loadGamesSuccess = (games: IProviderGame[]): gameAction => {
    return {
        type: types.LOAD_GAMES_SUCCESS,
        games
    }
}

const loadGameListSuccess = (games: IGame[], action: string, count: number): gameAction => {
    return {
        type: types.LOAD_GAMELIST_SUCCESS,
        games,
        action,
        count,
    }
}

const loadTvGamesSuccess = (tvGames: ITvGame[], action: string, count: number): gameAction => {
    return {
        type: types.LOAD_TV_GAMES_SUCCESS,
        tvGames,
        action,
        count,
    }
}

const loadProviderGamesSuccess = (games: IProviderGame, action: string): gameAction => {
    return {
        type: types.LOAD_PROVIDER_GAMES_SUCCESS,
        action,
        games,
    }
}


const loadCategories_ = (): gameAction => {
    return {
        type: types.LOAD_CATEGORIES
    }
}

const loadCategoriesFailed = (error: string): gameAction => {
    return {
        type: types.LOAD_CATEGORIES_FAIL,
        error
    }
}

const loadCategoriesSuccess = (categories: ICategoryGame[]): gameAction => {
    return {
        type: types.LOAD_CATEGORIES_SUCCESS,
        categories

    }
}


const loadProviders_ = (): gameAction => {
    return {
        type: types.LOAD_PROVIDERS
    }
}

const loadProvidersFailed = (error: string): gameAction => {
    return {
        type: types.LOAD_PROVIDERS_FAIL,
        error
    }
}

const loadProvidersSuccess = (providers: string[]): gameAction => {
    return {
        type: types.LOAD_PROVIDERS_SUCCESS,
        providers

    }
}


const loadTvProviders_ = (): gameAction => {
    return {
        type: types.LOAD_TV_PROVIDERS
    }
}

const loadTvProvidersFailed = (error: string): gameAction => {
    return {
        type: types.LOAD_TV_PROVIDERS_FAIL,
        error
    }
}

const loadTvProvidersSuccess = (tvProviders: string[]): gameAction => {
    return {
        type: types.LOAD_TV_PROVIDERS_SUCCESS,
        tvProviders

    }
}

interface IGetThunkGames extends IGetGames {
    action: string;
}

interface IGetThunkTvGames extends IGetTvGames {
    action: string;
}

//THUNK
export const getGames = ({page = 1, size = 10, title = "", provider = "", category = "all"}: IGetGames) => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadGames_());
        return gamesAPI.getGames({page, size, title, provider, category}).then(pld => {
            dispatch(loadGamesSuccess(pld));
        }).catch(error => {
            let res = error.response;
            dispatch(loadGamesFailed(res.data.message))
        })
    }
}

export const getProviderGames = ({
                                     page = 1,
                                     size = 10,
                                     title = "",
                                     category = "all",
                                     provider = "",
                                     action = "update"
                                 }: IGetThunkGames) => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadGames_());
        return gamesAPI.getProviderGames({page, size, title, category, provider}).then(pld => {
            dispatch(loadProviderGamesSuccess(pld, action));
        }).catch(error => {
            let res = error.response;
            dispatch(loadGamesFailed(res.data.message))
        })
    }
}


export const getGameList = ({
                                page = 1,
                                size = 16,
                                title = "",
                                provider = "",
                                category = "all",
                                action = "update"
                            }: IGetThunkGames) => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadGames_());
        return gamesAPI.getGameList({page, size, title, provider, category}).then(pld => {
            dispatch(loadGameListSuccess(pld.Games, action, pld.Count));
        }).catch(error => {
            let res = error.response;
            dispatch(loadGamesFailed(res.data.message))
        })
    }
}

export const getTvGames = ({
                               page = 1,
                               size = 20,
                               title = "",
                               provider = "",
                               action = "update"
                           }: IGetThunkTvGames) => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadTvGames_());
        return gamesAPI.getTvGames({page, size, title, provider}).then(pld => {
            dispatch(loadTvGamesSuccess(pld.Games, action, pld.Count));
        }).catch(error => {
            let res = error.response;
            dispatch(loadTvGamesFailed(res.data.message))
        })
    }
}


export const getProviders = () => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadProviders_());
        return gamesAPI.getProviders().then(pld => {
            dispatch(loadProvidersSuccess(pld));
        }).catch(error => {
            let res = error.response;
            dispatch(loadProvidersFailed(res.data.message))
        })
    }
}

export const getTvProviders = () => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadTvProviders_());
        return gamesAPI.getTvProviders().then(pld => {
            dispatch(loadTvProvidersSuccess(pld));
        }).catch(error => {
            let res = error.response;
            dispatch(loadTvProvidersFailed(res.data.message))
        })
    }
}


export const getCategories = () => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(loadCategories_());
        return gamesAPI.getCategories().then(pld => {
            dispatch(loadCategoriesSuccess(pld));
        }).catch(error => {
            let res = error.response;
            dispatch(loadCategoriesFailed(res.data.message))
        })
    }
}

////////////////////////////////////////////////////////
//UPDATE

const updateGames_ = (): gameAction => {
    return {
        type: types.UPDATE_GAME
    }
}

const updateGamesFailed = (error: string): gameAction => {
    return {
        type: types.UPDATE_GAME_FAIL,
        error
    }
}

const updateFavoriteGamesSuccess = (game: IsFavoriteGame, isGameList: boolean, isTvGames: boolean): gameAction => {
    return {
        type: types.UPDATE_FAVORITE_GAME_SUCCESS,
        game,
        isGameList,
        isTvGames
    }
}


export const toggleFavoriteGames = (GameId: string, isGameList: boolean = false, isTvGames: boolean = false) => {
    return (dispatch: Dispatch<gameAction>) => {
        dispatch(updateGames_());
        return gamesAPI.toggleFavoriteGameession(GameId).then(pld => {
            dispatch(updateFavoriteGamesSuccess(pld, isGameList, isTvGames));
        }).catch(error => {
            let res = error.response;
            dispatch(updateGamesFailed(res.data.message))
        })
    }
}
