import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Odometer from './Odometer';
import axios from 'api';
import {useTranslation} from 'react-i18next';
import {useSocket} from "../services/SocketService";
import {getStorage} from "../hooks/useStorage";


type JackPotMobileProps = {
    isDesktop?: boolean
}

const initialNumber = 50.12;
const initialNickname = "unknown";
const initialCurrency = "TND";

const Jackpot = ({isDesktop = false}: JackPotMobileProps) => {
    const [number, setCount] = useState(initialNumber);
    const [nickName, setNickName] = useState(initialNickname);
    const [currencyInfo, setCurrencyInfo] = useState(initialCurrency);
    const socket = useSocket();
    const {t} = useTranslation();

    const handleJackpot = (data: any) => {
        const {currentJackpot, lastWinner, currency} = data;
        setNickName(lastWinner);
        setCount(currentJackpot);
        setCurrencyInfo(currency);
    };

    useEffect(() => {
        axios.get("api/info/jackpot").then((pld) => {
            handleJackpot(pld.data);
        })
        socket.on("jackpot", data => {
            handleJackpot(data);

        })
    }, []);


    return (
        <Wrapper>
            <JackpotText isDesktop={isDesktop}>
                {t(`common.info.jackpot`)}
            </JackpotText>
            <Odometer isDesktop={isDesktop} number={number}/>
            <Text isDesktop={isDesktop} style={{color: "#FFB401"}}>
                {currencyInfo}
            </Text>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                justifyContent: "center",
            }}>
                <Text isDesktop={isDesktop}>{t(`common.info.winner`)}:</Text>
                <Text isDesktop={isDesktop} style={{color: "#FFB401"}}>
                    {`${nickName}`}
                </Text>
            </div>
        </Wrapper>
    );
};

export default Jackpot;


const Wrapper = styled("div")((props) => ({
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    gap: 9,
}))

const JackpotText = styled("div")<{ isDesktop?: boolean }>`
  text-transform: uppercase;
  font-size: ${props => props.isDesktop ? 20 : 16}px;
  font-weight: 400;
  font-family: "Roboto Condensed", sans-serif;
  @media (max-width: 376px) {
    display: ${props => props.isDesktop ? "block" :"none"};
  };

`


const Text = styled("div")<{ isDesktop?: boolean }>((props) => ({
    fontFamily: "Roboto Condensed",
    fontWeight: 400,
    fontSize: props.isDesktop ? 20 : 16,
    textTransform: "uppercase",
    "@media (max-width: 1750px)": {
        fontSize: props.isDesktop ? 15 : 16,
    }
}))

