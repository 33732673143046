import React from 'react';
import styled from 'styled-components';


type CustomChipProps = {
    color: string,
    text: string,
    iconJSX: JSX.Element,
    isDesktop?: boolean,
}

const CustomChip = (props: CustomChipProps) => {
    return (
        <Wrapper id={`custom-chip`} isDesktop={props.isDesktop} color={props.color}>
            <IconContainer isDesktop={props.isDesktop} color={props.color}>
                {props.iconJSX}
            </IconContainer>
            <Text isDesktop={props.isDesktop}>{`${props.text}`}</Text>
        </Wrapper>
    );
};

export default CustomChip;


const IconContainer = styled(`div`)<{ color: string, isDesktop?: boolean }>((props) => ({
    width: !props.isDesktop ? 16 : 32,
    height: !props.isDesktop ? 16 : 32,
    background: "#ffffff",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > svg": {
        width: !props.isDesktop ?  10 : 18,
        height: !props.isDesktop ?  10 : 18,
    },
}));


const Text = styled(`div`)<{ isDesktop?: boolean }>((props) => ({
    color: "#ffffff",
    textTransform: "uppercase",
    fontFamily: "Roboto Condensed",
    fontWeight: 500,
    fontSize: !props.isDesktop ? 12: 26
}));

const Wrapper = styled(`div`)<{ color: string, isDesktop?: boolean }>((props) => ({
    padding: "6px 5px",
    display: "flex",
    gap: 2,
    borderRadius: !props.isDesktop ? 16 : 5,
    alignItems: "center",
    justifyContent: "center",
    background: props.color,
}));
