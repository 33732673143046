import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import HeaderDesktop from "components/wrapper/header/HeaderDesktop";
import SecondHeaderDesktop from "components/wrapper/header/SecondHeaderDesktop";
import styled, {createGlobalStyle} from "styled-components";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from 'hooks/useActions';
import BackgroundImage from "assets/images/desktop_background-image.svg";
import StaticPage from "../pages/StaticPage";
import {useSocket} from "../services/SocketService";
import ScrollButton from "../components/scrollButton";
import Sport from "../pages/Sport";
import getCorrectRoutes from 'helpers/getCorrectRoutes';
import {getStorage} from "../hooks/useStorage";
import SingIn from "../pages/SingIn";
import {useTranslation} from "react-i18next";

const DesktopApp = () => {
    const {updateModal, updateAlert} = useActions();
    const {t, i18n} = useTranslation();
    const access = getStorage("APP_ACCESS");
    const languages = getStorage("APP_OTHER.LANGUAGES");
    const isAccessLoginPage = access.LOGIN_PAGE.VISIBLE;
    const isAccessSport = access.SPORT.VISIBLE;
    const isAccessLiveSport = access.LIVE_SPORT.VISIBLE;
    const isAccessStaticPages = access.STATIC_PAGES.VISIBLE;
    const {pathname} = useLocation();
    const socket = useSocket()
    const [betBy, setBetBy] = useState<any>(null);
    const {user, pages} = useTypedSelector((state => {
        return {
            pages: state.app.pages,
            user: state.user.user
        }
    }))

    let isAuth = user !== null;
    const lang: any = Object.values(languages)!.find((it: any) => it.KEY === i18n.language);
    const GlobalStyle = createGlobalStyle`
      body {
        direction: ${lang?.DIRECTION};
        background: ${pathname === "/" || pathname.includes("/static") || pathname === "/promotions" ? `#121931` : "#000000"};
        min-width: 1200px;
        color: white;
        background-size: cover;
        background-image: ${pathname === "/" || pathname === "/promotions" ? `url(${BackgroundImage})` : "inherit"};
      }
    `;

    useEffect(() => {
        if (isAccessSport || isAccessLiveSport) {
            let b = document.getElementById("betby");
            if (pathname !== "/sport" && pathname !== "/live-sport") {
                b!.hidden = true;
                if (betBy) {
                    betBy.clean()
                }

            } else {
                b!.hidden = false
            }
        }

    }, [pathname])

    useEffect(() => {
        socket.on('connect', () => {
            console.log("connection successful")
        })
        socket.on('notification', (pld) => {
            const {type, content} = pld;
            const curContent = content.find((item: any) => item.lang === i18n.language);
            updateAlert({isOpen: true, status: type === "success" ? "warning" : "error", message: curContent.message})
        })
    }, [])

    return (
        <>
            <GlobalStyle/>
            {!isAuth && isAccessLoginPage ? <>
                    <Routes>
                        <Route path={`/`} element={<SingIn/>}/>
                        <Route path={`/*`} element={<Navigate to={`/`}/>}/>
                    </Routes>
                </> :
                <>
                    {!pathname.includes(`/game/`) && <HeaderContainer>
                        <HeaderDesktop user={user} setOpenLogin={() => updateModal(true)}/>
                        <SecondHeaderDesktop isAuthUser={isAuth}/>
                    </HeaderContainer>}
                    <MainContainer>
                        <Routes>
                            {getCorrectRoutes(isAuth, ["SPORT", "LIVE_SPORT", "STATIC_PAGES"], true)}

                            {isAccessSport && <Route path={`/sport`}
                                                     element={<Sport type={"sport"} isDesktop setBetBy={setBetBy}
                                                                     betBy={betBy}/>}/>}
                            {isAccessLiveSport && <Route path={`/live-sport`}
                                                         element={<Sport type={"live-sport"} isDesktop
                                                                         setBetBy={setBetBy}
                                                                         betBy={betBy}/>}/>}
                            {isAccessStaticPages &&
                                pages.map((item, index) => (
                                    <React.Fragment key={`${index}-${item.id}`}>
                                        <Route path={`/static/${item.url}/${item.lang}`}
                                               element={<StaticPage id={item.id}/>}/>
                                    </React.Fragment>
                                ))
                            }
                            <Route path={`/*`} element={<Navigate to={`/`}/>}/>
                        </Routes>
                        <ScrollButton isDesktop/>
                    </MainContainer>
                </>

            }
        </>
    );
};

export default DesktopApp;

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  z-index: 10000;
`

const MainContainer = styled.div`

`
