import React from 'react';
import styled from "styled-components";
import { HallOfFame as HallOfFameFrame } from "features/hall-of-fame";


const HallOfFameDesktop = () => {
    return (
        <MainContainer>
            <HallOfFameFrame/>
        </MainContainer>
    );
};

export default HallOfFameDesktop;


const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
`
