import {createEffectorField, useCreateMutation} from "helpers/effector";
import {IHallOfFame} from "./hall-of-fame.types";
import {hallOfFameFX, hallOfFameMonthFX} from "./hall-of-fame.effects";
import {useStore} from "effector-react";
import {createEvent, sample} from "effector";


export const [$hallOfFame, hallOfFameChanged] = createEffectorField<IHallOfFame[]>({
    defaultValue: []
});

$hallOfFame.on(hallOfFameFX.doneData, (_, payload) => payload);


export const [$hallOfFameMonth, hallOfFameMonthChanged] = createEffectorField<string[]>({
    defaultValue: []
});

$hallOfFameMonth.on(hallOfFameMonthFX.doneData, (_, payload) => payload);




export const ChangeEvent = createEvent<string>();



sample({
    clock: ChangeEvent,
    target: hallOfFameFX,
    fn: (month) => ({month})
})

export const useHallOfFame = () => useStore($hallOfFame);
export const useHallOfFameMonths = () => useStore($hallOfFameMonth);


export const useHallOfFameMutation = () => useCreateMutation(hallOfFameFX);
export const useHallOfFameMonthMutation = () => useCreateMutation(hallOfFameMonthFX);

