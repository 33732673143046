import {OrderedMap} from "immutable";

export interface IGame {
    Id: string,
    Name: string,
    Description: string,
    SectionId: string,
    Type: string,
    IsFavorite: boolean,
    Thumbnail: string,
    Format: string,
}

export interface ITvGame {
    Id: string,
    Name: string,
    Description: string,
    SectionId: string,
    IsFavorite: boolean,
    Thumbnail: string,
}

export interface IsFavoriteGame {
    SectionId: string,
    Id: string,
    IsFavorite: boolean
}

export interface IProviderGame {
    SectionId: string,
    Count: number,
    Games: IGame[]
}

export interface ICategoryGame {
    id: number,
    img_url: string,
    createdAt: string,
    content: {
        lang: string,
        title: string
    }[]
}


export interface IGameState {
    tvGames: OrderedMap<string, ITvGame>,
    tvGamesCount: number,
    games: OrderedMap<string, IProviderGame>,
    gameList: OrderedMap<string, IGame>,
    gameListCount: number,

    providers: string[],
    tvProviders: string[],
    categories: ICategoryGame[],
    error: string | undefined,
    loading: boolean
}


export enum GameActionTypes {
    LOAD_TV_PROVIDERS = "LOAD_TV_PROVIDERS",
    LOAD_PROVIDERS = "LOAD_PROVIDERS",
    LOAD_CATEGORIES = "LOAD_CATEGORIES",
    LOAD_TV_GAMES = "LOAD_TV_GAMES",
    LOAD_GAMES = "LOAD_GAMES",
    UPDATE_GAME = "UPDATE_GAME",

    LOAD_CATEGORIES_FAIL = "LOAD_CATEGORIES_FAIL",
    LOAD_TV_PROVIDERS_FAIL = "LOAD_TV_PROVIDERS_FAIL",
    LOAD_PROVIDERS_FAIL = "LOAD_PROVIDERS_FAIL",
    LOAD_TV_GAMES_FAIL = "LOAD_TV_GAMES_FAIL",
    LOAD_GAMES_FAIL = "LOAD_GAMES_FAIL",
    UPDATE_GAME_FAIL = "UPDATE_GAME_FAIL",

    LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS",
    LOAD_TV_PROVIDERS_SUCCESS = "LOAD_TV_PROVIDERS_SUCCESS",
    LOAD_PROVIDERS_SUCCESS = "LOAD_PROVIDERS_SUCCESS",
    LOAD_PROVIDER_GAMES_SUCCESS = "LOAD_PROVIDER_GAMES_SUCCESS",
    LOAD_GAMELIST_SUCCESS = "LOAD_GAMELIST_SUCCESS",
    LOAD_TV_GAMES_SUCCESS = "LOAD_TV_GAMES_SUCCESS",
    LOAD_GAMES_SUCCESS = "LOAD_GAMES_SUCCESS",
    UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS",
    UPDATE_FAVORITE_GAME_SUCCESS = "UPDATE_FAVORITE_GAME_SUCCESS"
}


/////////////////////Actions/////////////////////////

interface ILoadGame {
    type: GameActionTypes.LOAD_GAMES;
}

interface ILoadTvGame {
    type: GameActionTypes.LOAD_TV_GAMES;
}

interface ILoadTvProviders {
    type: GameActionTypes.LOAD_TV_PROVIDERS;
}

interface ILoadProviders {
    type: GameActionTypes.LOAD_PROVIDERS;
}

interface ILoadCategories {
    type: GameActionTypes.LOAD_CATEGORIES;
}

interface IUpdateGame {
    type: GameActionTypes.UPDATE_GAME;
}


/////////////////////Fail/////////////////////////

interface ILoadCategoriesFail {
    type: GameActionTypes.LOAD_CATEGORIES_FAIL;
    error: string;
}


interface ILoadProvidersFail {
    type: GameActionTypes.LOAD_PROVIDERS_FAIL;
    error: string;
}

interface ILoadTvProvidersFail {
    type: GameActionTypes.LOAD_TV_PROVIDERS_FAIL;
    error: string;
}

interface ILoadGamesFail {
    type: GameActionTypes.LOAD_GAMES_FAIL;
    error: string;
}

interface ILoadTvGamesFail {
    type: GameActionTypes.LOAD_TV_GAMES_FAIL;
    error: string;
}

interface IUpdateGameFail {
    type: GameActionTypes.UPDATE_GAME_FAIL;
    error: string;
}

/////////////////////Success/////////////////////////

interface ILoadProvidersSuccess {
    type: GameActionTypes.LOAD_PROVIDERS_SUCCESS;
    providers: string[];
}

interface ILoadTvProvidersSuccess {
    type: GameActionTypes.LOAD_TV_PROVIDERS_SUCCESS;
    tvProviders: string[];
}

interface ILoadCategoriesSuccess {
    type: GameActionTypes.LOAD_CATEGORIES_SUCCESS;
    categories: ICategoryGame[];
}

interface ILoadGamesSuccess {
    type: GameActionTypes.LOAD_GAMES_SUCCESS;
    games: IProviderGame[];
}

interface ILoadProviderGamesSuccess {
    type: GameActionTypes.LOAD_PROVIDER_GAMES_SUCCESS;
    action: string;
    games: IProviderGame;
}

interface ILoadTvGamesSuccess {
    type: GameActionTypes.LOAD_TV_GAMES_SUCCESS;
    action: string;
    tvGames: ITvGame[];
    count: number;
}

interface ILoadGameListSuccess {
    type: GameActionTypes.LOAD_GAMELIST_SUCCESS;
    action: string;
    games: IGame[];
    count: number;
}

interface IUpdateGameSuccess {
    type: GameActionTypes.UPDATE_GAME_SUCCESS;
    game: IProviderGame;
}

interface IUpdateFavoriteGameSuccess {
    type: GameActionTypes.UPDATE_FAVORITE_GAME_SUCCESS;
    game: IsFavoriteGame;
    isGameList: boolean;
    isTvGames: boolean;
}


export type gameAction =
    ILoadCategories
    | ILoadProviders
    | ILoadTvProviders
    | ILoadGame
    | ILoadTvGame
    | IUpdateGame
    |
    ILoadCategoriesFail
    | ILoadProvidersFail
    | ILoadTvProvidersFail
    | ILoadTvGamesFail
    | ILoadGamesFail
    | IUpdateGameFail
    |
    ILoadCategoriesSuccess
    | ILoadProvidersSuccess
    | ILoadTvProvidersSuccess
    | ILoadTvGamesSuccess
    | ILoadGamesSuccess
    | ILoadGameListSuccess
    | ILoadProviderGamesSuccess
    | IUpdateGameSuccess
    | IUpdateFavoriteGameSuccess
