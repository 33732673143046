import axios, {} from "axios";
import {logoutUser} from "store/actions/userActions";
import { store } from "store";
import settings from "../settings";
const {SETTINGS} = settings;
const mirror = Object.keys(SETTINGS.MIRRORS).find(item => item === window.location.host);
export const BASE_URL = settings.NODE_ENV === "development" ? SETTINGS.API_DEV_URL : mirror ? SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL :
    SETTINGS.API_PROD_URL;

const {dispatch} = store;


const UNAUTHORIZED = 401;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Content-Type']  = 'application/json';
axios.defaults.withCredentials = true;



axios.interceptors.response.use((response) => {
    return response;
}, error => {
    if (error.response.status === UNAUTHORIZED) {
        dispatch<any>(logoutUser());
    }
    return Promise.reject(error);
})


export default axios;
