import React from 'react';
import styled from "styled-components";
import {ReactComponent as Arrow} from "assets/images/btn_up_arrow_icon.svg"

type ArrowButtonProps = {
    onClick: () => void,
    styles?: object
}

const ArrowButton = (props: ArrowButtonProps) => {
    return (
        <Wrapper id={`arrow-button`} style={props.styles} onClick={() => props.onClick()}>
                <Arrow/>
        </Wrapper>
    );
};

export default ArrowButton;


const Wrapper = styled("div")((props) => ({
    maxWidth: 35,
    maxHeight: 35,
    minWidth: 35,
    minHeight: 35,
    cursor: "pointer",
    background: "#273154",
    border: "1px solid #434550",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "6px 8px",
    filter: "drop-shadow(0px 0px 16px #000000)",
    "&:hover": {
        background: "#222a49",
    },
    "&:active": {
        background: "#1c233b",
    }
}));
