const theme = {
    palette: {
        mode: "dark",
        primary: {
            light: '#39466D',
            main: '#263154',
            dark: '#121931',
        },
        secondary: {
            light: '#829FF5',
            main: '#263154',
            dark: '#121931'
        },
        error: {
            main: '#ff0000',
        }
    },

};

export default theme;
