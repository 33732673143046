import React from 'react';
import styled from 'styled-components';
import {IHighRoller} from '../model/high-roller.types';
import openGame from "../../../helpers/gamesHelper/openGame";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useTranslation} from "react-i18next";
import useMediaQuery from "../../../hooks/useMediaQuery";
import {sizes} from "../../../constants/breakpoints";

type HighRollerItemProps = {
    highRoller: IHighRoller;
    className?: string;
}

export const HighRollerItem = (props: HighRollerItemProps) => {
    const {highRoller, className} = props;
    const {setLoading, updateAlert} = useActions();
    const {t} = useTranslation();
    const mobile = useMediaQuery(`(max-width: ${sizes.tablet}px)`);
    const user = useTypedSelector((state) => state.user.user);

    const handleClick = () => {
        if (!user) {
            updateAlert({
                isOpen: true,
                status: "error",
                message: `${t(`common.alert.text_error`)}`
            })
        } else {
            openGame({
                isDesktop: !mobile,
                providerId: highRoller.game.SectionId,
                gameId: highRoller.game.Id,
                setLoading: () => {
                    setLoading(true);
                }
            })
        }


    }

    let title = `x${(highRoller.coeff  ?? "").toFixed()} ${highRoller.game.Name} ${highRoller.login} ${(highRoller.value  ?? "").toLocaleString()} ${(highRoller.currency  ?? "").toLocaleString()}`

    return (
        <Container className={className}
                   title={title}>
            <Multiplier>x{(highRoller.coeff  ?? "").toFixed()}</Multiplier>
            <Text onClick={handleClick} style={{cursor: "pointer"}}>{
                <Dashed>
                    {highRoller.game.Name}
                </Dashed>
            }</Text>
            <Text style={{textAlign: "center", width: 46}}>{highRoller.login.slice(0, 7)}</Text>
            <Text style={{
                textAlign: "right",
                width: 60
            }}>{`${(highRoller.value  ?? "").toLocaleString()} ${(highRoller.currency  ?? "").toLocaleString()}`}</Text>
        </Container>
    );
};


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Multiplier = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: #F3EBA8;
  text-align: left;
  width: 50px;
`
const Dashed = styled.div`
  width: 100%;
  border-bottom: 1px dashed #829FF5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Text = styled.div`
  font-weight: 400;
  width: 80px;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  color: #829FF5;

`
