import {appAction, AppTypes, IAppState} from "../types/appTypes";


const initialState: IAppState = {
    alert: {
        status: "error",
        message: "",
        isOpen: false
    },
    error: {
        message: "",
        isOpen: false
    },
    sport: undefined,
    pages: [],
    promotions: [],
    banners: [],
    modal: false,
    loading: false
}


const appReducer = (state = initialState, action: appAction): IAppState => {
    switch (action.type) {
        case AppTypes.UPDATE_ALERT:
            return {
                ...state, alert: action.alert
            }
        case AppTypes.UPDATE_ERROR:
            return {
                ...state, error: action.error
            }
        case AppTypes.SET_SPORT:
            return {
                ...state, sport: action.sport
            }
        case AppTypes.GET_STATIC_PAGES:
            return {
                ...state, pages: action.pages
            }
        case AppTypes.GET_PROMOTIONS:
            return {
                ...state, promotions: action.promotions
            }
        case AppTypes.GET_BANNERS:
            return {
                ...state, banners: action.banners
            }
        case AppTypes.UPDATE_MODAL:
            return {
                ...state, modal: action.open
            }
        case AppTypes.SET_LOADING:
            return {
                ...state, loading: action.loading
            }
        default:
            return state;
    }
}


export default appReducer;
