declare const window: any;

export const TawkService = {

    initialize() {
        window.Tawk_API.onLoad = function () {
            window.Tawk_API.hideWidget();
        };
    },

    hideWidget() {
        window.Tawk_API.minimize();
    },

    openWidget() {
        window.Tawk_API.maximize();
    },
    toggleWidget() {
        if (window.Tawk_API.isChatMaximized()) {
            window.Tawk_API.minimize();
        } else {
            window.Tawk_API.maximize();
        }
    }
}
