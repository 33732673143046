import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as ArrowIcon} from 'assets/images/profile_back-arrow_icon.svg';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

type BackButtonProps = {
    href: string
    title: string
}


const BackButton = ({href, title}: BackButtonProps): JSX.Element => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <BackStyledButton id={`back-button`} onClick={() => {
            navigate(-1)
        }}>
            <IconContainer>
                <ArrowIcon/>
            </IconContainer>
            <Text>
                {t(`common.options.back_to_profile`)}
            </Text>
        </BackStyledButton>
    );
};

export default BackButton;

const IconContainer = styled(`div`)((props) => ({
    height: "100%",
    boxSizing: "border-box",
    padding: 16,
    borderRight: "1px solid #121931"
}))

const Text = styled(`div`)((props) => ({
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    boxSizing: "border-box",
    fontWeight: 700
}))

const BackStyledButton = styled("div")((props) => ({
    width: "100%",
    borderRadius: 0,
    boxSizing: "border-box",
    cursor: "pointer",
    color: "white",
    textTransform: "capitalize",
    background: "#263154",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "inherit",
    gap: 21,
    padding: 0,
    height: 50,
    "&:hover": {
        background:  props.theme.palette.primary.light
    }
}))
