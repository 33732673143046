import React from 'react';
import styled from 'styled-components';
import LazyImage from '../LazyImage';
import {useTranslation} from "react-i18next";

type DesktopCardProps = {
    onClick: () => void,
    icon: string,
    backgroundImage: string,
    title: string,
    description: string,
    width: number
}
const DesktopCard = (props: DesktopCardProps) => {
    const {t} = useTranslation();
    return (
        <Wrapper width={props.width} id={`desktop-card-${props.title}`} onClick={props.onClick}>
            <Image width={props.width} src={require(`../../${props.backgroundImage}`)}
                   alt={`nice-card-${props.title}`}/>
            <Content width={props.width}>
                <InfoContainer>
                    <SVG>
                        <img style={{maxHeight: 40, maxWidth: 120}} src={require(`../../${props.icon}`)}
                             alt={props.title}/>
                    </SVG>
                    <div></div>
                    <div style={{maxWidth: 160}}>
                        <Title>
                            {props.title}
                        </Title>
                        <Description>
                            {props.description}
                        </Description>
                    </div>
                </InfoContainer>
                <InfoContainer>
                    <div/>
                    <PlayButton width={props.width / 3}>
                        <div style={{color: "white", fontWeight: 600}}>
                            {t("pages.promotions.play")}
                        </div>

                    </PlayButton>
                </InfoContainer>
            </Content>
        </Wrapper>
    );
};

export default DesktopCard;


const SVG = styled.div`
  position: absolute;
  & > svg {
    height: 50px;
    max-width: 100px;
  }
`


const PlayButton = styled.div<{ width: number }>`
  font-size: 18px;
  text-transform: uppercase;
  background: #FFB401;
  box-sizing: border-box;
  padding: 4px 0px;
  width: ${props => props.width ?? 100}px;
  border-radius: 50px;
  color: #5E6F9E;
  text-align: center;
`

const Wrapper = styled.div<{ width: number }>`
  width: ${props => props.width ?? 320}px;
  height: 176px;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid #142144;
  position: relative;
  margin-top: 10px;

`

const Image = styled(LazyImage)<{ width: number }>`
  z-index: 0;
  width: 100%;
  height: 176px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
`

const Content = styled.div<{ width: number }>`
  display: flex;
  position: absolute;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: space-between;
`

const InfoContainer = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;

`
const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5E6F9E
`

