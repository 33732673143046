import {devices} from 'constants/breakpoints';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import HallOfFameBackground from '../assets/hall-of-fame_background.png';
import {Calendar} from "./calendar";
import {ChangeEvent, useHallOfFameMonthMutation, useHallOfFameMutation} from "../model/hall-of-fame.store";
import {useTranslation} from 'react-i18next';


export const TopHallOfFame = () => {
    const {t} = useTranslation();
    const [getHallOfFame] = useHallOfFameMutation();
    const [getMonths] = useHallOfFameMonthMutation();
    const [month, setMonth] = React.useState<string>('');

    useEffect(() => {
        getMonths().then((pld) => {
            getHallOfFame({month: pld[0]}).then();
            setMonth(pld[0]);
        });
    }, [window.location.pathname]);

    return (
        <Wrapper>
            <Image src={HallOfFameBackground} alt='top-hall-of--fame'/>
            <Title>{t('pages.hall_of_fame.title')}</Title>
            <StyledCalendar month={month} onClickMonth={(m) => {
                setMonth(m);
                ChangeEvent(m);
            }}/>
        </Wrapper>
    );
};

const Image = styled.img`
  width: 100%;


  ${devices.mobileL} {
    height: 180px;
    object-fit: cover;
  }
`;


const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #4D2608;
  max-width: 140px;

  ${devices.tablet} {
    font-size: 12px;
    line-height: 14px;
    max-width: 56px;
  }

  ${devices.mobile} {
    font-size: 8px;
  }
`;
