import React, {useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {ReactComponent as PersonLogo} from 'assets/images/profile_person_icon.svg';
import {ReactComponent as ArrowDrop} from '../../../assets/images/desktop_menu_down_arrow_icon.svg';

import Jackpot from 'ui/Jackpot';
import {IUser} from 'store/types/userTypes';
import {useNavigate} from "react-router-dom";
import DesktopProfileMenu from "ui/DesktopProfileMenu";
import InfoContainer from './InfoContainer';
import useOutsideClick from "hooks/useOutsideClick";
import {useTranslation} from 'react-i18next';
import {getStorage} from "../../../hooks/useStorage";
import GoldenButton from 'ui/GoldenButton';
import CashbackButton from "./CashbackButton";

type HeaderDesktopProps = {
    user: IUser | null
    setOpenLogin: () => void,
}
const HeaderDesktop = (props: HeaderDesktopProps) => {
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    const logo = require(`../../../${PATH}`)


    const PROFILE_CASHBACK = getStorage("APP_OTHER.HEADER.MENU.CASHBACK.VISIBLE");
    const {t} = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const refMenu = useRef(null);
    useOutsideClick(refMenu, () => setOpen(false));
    return (
        <Wrapper>
            <Container>
                <div style={{cursor: "pointer"}}>
                    <LogoImg src={logo} alt={"desktop-logo"}
                             onClick={() => {
                                 navigate("/")
                             }}/>
                </div>


                <ContainerJack>
                    <Jackpot isDesktop/>
                </ContainerJack>


                {
                    !props.user ? <GoldenButton onClick={() => {
                            props.setOpenLogin();
                        }}>
                            {t(`common.navbar.signin_button`)}
                        </GoldenButton> :
                        <PersonInfo ref={refMenu}>
                            {
                                PROFILE_CASHBACK && <CashbackButton/>
                            }
                            <IdsContainer>
                                ID: {props.user.id}
                            </IdsContainer>
                            <DropdownPerson onClick={(e) => {
                                setOpen(!open)
                            }}>
                                <PersonLogo/>
                                <ArrowDrop/>
                            </DropdownPerson>
                            <DesktopProfileMenu onClose={() => setOpen(false)} open={open} login={props.user.login}
                                                id={`${props.user.id}`}/>
                            <InfoContainer isDesktop/>
                        </PersonInfo>

                }

            </Container>
        </Wrapper>
    );
};

export default HeaderDesktop;

const LogoImg = styled.img`
  height: 80px;
`

const IdsContainer = styled.div`
  padding: 5px 13px;
  font-size: 24px;
  border-radius: 5px;
  background: ${props => props.theme.palette.primary.main};
  @media (max-width: 1540px) {
    font-size: 18px;
  }
`

const Wrapper = styled.div`
  height: 88px;
  background: #060912;
  width: 100%;
  box-sizing: border-box;
`
const PersonInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
`

const ContainerJack = styled.div`
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  display: inline-block;
  text-align: center;
  @media (max-width: 1700px) {
    transform: translate(-60%, -50%);
  }
  @media (max-width: 1500px) {
    transform: translate(-80%, -50%);
  }

`
const DropdownPerson = styled("button")`
  width: 87px;
  background: ${props => props.theme.palette.primary.main};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px 13px;
  font-size: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
  cursor: pointer;
  @media (max-width: 1540px) {
    height: 32px;
  }

  :hover {
    background-color: #1f2744;
  }

  :active {
    background-color: #191f36;
  }
`


const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;
  margin-left: 18px;

  & > svg {
    width: 140px;
    height: 57px;
  }
`


