import DesktopGame from 'ui/DesktopGame';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {useActions} from "hooks/useActions";
import openGame from "helpers/gamesHelper/openGame";

interface IGame {
    name: string;
    id: string;
    providerId: string;
    url: string;
    isFavorite: boolean;
}

type GameBlockProps = {
    title: string;
    link: string;
    handleCallback: () => boolean;
    games: IGame[];
    fullWidth: number;
    isTvGames?: boolean;
    addFavorite?: boolean;
}

const GameBlock = (props: GameBlockProps): JSX.Element => {
    const navigate = useNavigate();
    const {toggleFavoriteGames, setLoading} = useActions();

    const allGames = !props.isTvGames ?props.games.slice(0, 16): props.games.slice(0, 10);
    return (
        <>
            <Divider/>
            <Title onClick={() => {
                if (props.link) {
                    navigate(props.link);
                }
            }}>
                {`${props.title}`}
            </Title>
            <Divider/>
            <Container isTvGames={props.isTvGames}>
                {
                    allGames.map((item, index) => (
                        <React.Fragment key={`${props.title}-game-card-${index}-${item.name}`}>
                            <DesktopGame isFavorite={item.isFavorite}
                                         addFavorite={props.addFavorite}
                                         width={props.isTvGames ? Math.trunc((props.fullWidth - 160) / 5) : Math.trunc((props.fullWidth - 274) / 8)}
                                         height={props.isTvGames ? Math.trunc(160) : Math.trunc((props.fullWidth - 274) / 8)}
                                         name={item.name}
                                         onClick={() => {
                                             if (props.handleCallback()) {
                                                 openGame({
                                                     isDesktop: true,
                                                     providerId: item.providerId,
                                                     gameId: item.id,
                                                     setLoading: (bool: boolean) => {
                                                         setLoading(bool);
                                                     }
                                                 })
                                             }
                                         }}
                                         url={item.url}
                                         onFavoriteClick={() => {
                                             if (props.handleCallback()) {
                                                 toggleFavoriteGames(item.id, true)
                                             }
                                         }}/>
                        </React.Fragment>
                    ))
                }
            </Container>
        </>
    );
};

export default GameBlock;


const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: linear-gradient(270deg, rgba(57, 70, 109, 0) 0%, #39466D 19.37%, #39466D 80.19%, rgba(57, 70, 109, 0) 100%);
`

const Title = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  font-family: Roboto Condensed;
  font-weight: bold;
  cursor: pointer;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  color: #FFFFFF;

  :hover {
    background: linear-gradient(270deg, rgba(57, 70, 109, 0) 0%, #39466D 19.37%, #39466D 30.19%, rgba(57, 70, 109, 0) 100%);
  }
`


const Container = styled.div<{isTvGames?: boolean}>`
  margin-top: 42px;
  display: grid;

  grid-template-columns: ${props => props.isTvGames? "repeat(5, 1fr)": "repeat(8, 1fr)"} ;
  grid-column-gap: 15px;
  grid-row-gap: 10px;


`
