import React, {useRef} from 'react';
import styled from 'styled-components';
import PersonIcon from "assets/images/profile_personal-data_icon.svg";
import CasinoBetHistoryIcon from "assets/images/profile_casino-bet-history_icon.svg";
import CashBackHistoryIcon from "assets/images/profile_cashback-history_icon.svg";
import HistoryIcon from "assets/images/profile_history-of-operation_icon.svg";
import ChangePasswordIcon from "assets/images/profile_change-password_icon.svg";
import Copy from "assets/images/copy_icon.svg"
import ExitIcon from "assets/images/profile_sign-out_icon.svg";

import SelectButton from "ui/SelectButton";
import {useNavigate} from "react-router-dom";
import {useActions} from "hooks/useActions";
import {getStorage} from 'hooks/useStorage';
import routing from "../constants/routing";
import StyledMobileButton from "./StyledMobileButton";
import {useTranslation} from "react-i18next";

type DesktopProfileMenuProps = {
    onClose: () => void,
    login: string,
    id: string,
    open: boolean,
}


const DesktopProfileMenu = (props: DesktopProfileMenuProps) => {
    const navigate = useNavigate();
    const {logoutUser, updateAlert} = useActions();
    const {t, i18n} = useTranslation();
    const APP_ACCESS = getStorage("APP_ACCESS");
    const handleClickProfile = async () => {
        await (() => {
            navigator.clipboard.writeText(`ID:  ${props.id}`);
        })()
        props.onClose();
        updateAlert({
            isOpen: true,
            status: "warning",
            message: "Code has been copied"
        })
    }
    const access = getStorage("APP_ACCESS");
    const objEnt: [string, { VISIBLE: boolean, PERMISSIONS: {} }][] = Object.entries(access);
    const correctAccess = objEnt.reduce((acc: string[], [key, val]) => val.VISIBLE ? [...acc, key] : acc, []);
    return (
        <>
            {props.open && <Wrapper id={`desktop-profile-menu`}>
                <ContentProfile>
                    <div style={{margin: "0 5px"}}>
                        <Profile>
                            {`${props.login}`}
                        </Profile>
                        <CopyContent onClick={handleClickProfile}>
                            <div style={{color: "#FFB401"}}>{`ID: ${props.id}`}</div>
                            |
                            <img src={Copy} alt={`copy element`} style={{transform: "scale(-1, 1)", width: 16}}/>
                        </CopyContent>
                    </div>
                    <ContentMenu>
                        {routing.filter(item => (item.key.includes("PROFILE_")) && correctAccess.includes(item.key)).map((item, index) => (
                            <React.Fragment key={`${index}-${item.key}-menu-profile`}>
                                <SelectButton onClick={() => {
                                    navigate(item.to);
                                    props.onClose();
                                }} title={t(`common.profile.${item.lang_key}`)} isImage jsx={item.iconJsx} img={""}/>
                            </React.Fragment>
                        ))}

                        <React.Fragment key={`logout-menu-profile`}>
                            <SelectButton onClick={() => {
                                logoutUser();
                                props.onClose();
                            }} title={t("common.info.log_out")} isImage img={ExitIcon}/>
                        </React.Fragment>
                    </ContentMenu>
                </ContentProfile>
            </Wrapper>}
        </>

    );
};

export default DesktopProfileMenu;

const ContentProfile = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 3px;
`

const Profile = styled.div`
  text-transform: uppercase;
  font-family: Roboto Condensed;
  font-size: 18px;
  font-weight: 400;
`

const CopyContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: -3px;
  align-items: center;
  gap: 7px;

  :hover {
    background: #313f6c;
  }

  :active {
    background: #2f3d69;
  }
`

const Wrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 260px;
  height: auto;
  background: ${props => props.theme.palette.primary.main};
  border-radius: 5px 0 5px 5px;
  padding: 5px;
  top: 40px;
  left: 50px;
  z-index: 100001;
`


const ContentMenu = styled(ContentProfile)`
  & > div > div {
    font-family: Roboto Condensed;
    line-height: 21px;
  }

`

