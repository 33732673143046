import React from 'react';
import {Spring} from "react-spring/renderprops";
import numeral from "numeral";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

type OdometerProps = {
    isDesktop?: boolean,
    number: number
}

const Odometer = ({number, isDesktop = false}: OdometerProps): JSX.Element => {

    const {t, i18n} = useTranslation();
    const chars = numeral(number).format("0,0.00").split("");

    return (
        <div style={{filter: "drop-shadow(0px 0px 25.8824px #FFB500) drop-shadow(0px 0px 41.2663px #FFB500)"}}>
            <OdometerCustom isDesktop={isDesktop}>
                {chars.map((digit, i) => {
                    if (isNaN(+digit)) {
                        return (
                            <Digit isLetter={![".", ","].includes(digit)}
                                   key={`digit-${i}`}>
                                {digit}
                            </Digit>
                        );
                    }
                    return (
                        <Spring
                            key={`digit-${i}`}
                            from={{translateY: 0, opacity: 0}}
                            to={{translateY: -digit, opacity: 1}}
                        >
                            {(props) => (
                                <Digit style={isDesktop ? {} : i18n.language === "en" ? {} : {
                                    fontSize: 30,
                                    height: "1em",
                                    lineHeight: "1em",
                                }} className="digit" isLetter>
                                    <div style={{
                                        opacity: props.opacity,
                                        transform: `translateY(${props.translateY}em)`
                                    }}
                                    >
                                        0 1 2 3 4 5 6 7 8 9 0
                                    </div>
                                </Digit>
                            )}
                        </Spring>
                    );
                })}
            </OdometerCustom>
        </div>
    );
}

export default Odometer;


const Digit = styled("div")<{ isLetter: boolean }>((props) => ({
    display: "inline-block",
    width: props.isLetter ? "0.6em" : "0.3em",
    height: "1em",
    overflow: "hidden",
}))

const OdometerCustom = styled("div")<{ isDesktop?: boolean }>((props) => ({
    display: "inline-block",
    height: "1em",
    fontFamily: "Roboto Condensed",
    direction: "ltr",
    color: "#FFB401",
    fontSize: props.isDesktop ? 61 : 38,
    lineHeight: "1em",
    "@media (max-width: 1540px)": {
        fontSize: props.isDesktop ? 40 : 38,
    }
}))
