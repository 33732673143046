import axios from "api";
import {AxiosPromise} from "axios";
import {IHighRoller} from "../model/high-roller.types";


const BASE_URL = '/api/info';


export const getHighRollers = (): AxiosPromise<IHighRoller[]> =>
    axios.get(`${BASE_URL}/high-roll`,);
