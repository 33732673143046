import React from 'react';
import { createPortal } from 'react-dom';
import usePortal from 'hooks/usePortal';

type PortalProps = {
    id: string,
    children: React.ReactNode
}

const Portal = ({id, children}: PortalProps) => {
    let target = usePortal(id);
    return createPortal(
        children,
        target,
    );
};

export default Portal;
