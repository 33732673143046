import {ITransactionState, transactionAction, TransactionActionTypes} from "../types/transactionTypes";


const initialState: ITransactionState = {
    error: undefined,
    loading: false,
    transactionsCount: 0,
    cashbackOperationsCount: 0,
    betsCount: 0,
    cashbackOperations: [],
    transactions: [],
    bets: [],
}


const startLoading = (state: ITransactionState) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state: ITransactionState, error: string) => {
    return {...state, loading: false, error: error}
}


const transactionReducer = (state = initialState, action: transactionAction): ITransactionState => {
    switch (action.type) {

        case TransactionActionTypes.LOAD_CASHBACK_OPERATIONS:
        case TransactionActionTypes.LOAD_TRANSACTIONS:
        case TransactionActionTypes.LOAD_BETS:
            return startLoading(state)

        case TransactionActionTypes.LOAD_CASHBACK_OPERATIONS_FAIL:
        case TransactionActionTypes.LOAD_TRANSACTIONS_FAIL:
        case TransactionActionTypes.LOAD_BETS_FAIL:
            return stopLoading(state, action.error)


        case TransactionActionTypes.LOAD_CASHBACK_OPERATIONS_SUCCESS:
            if (action.action === "add") {
                return {
                    ...state,
                    cashbackOperationsCount: action.count,
                    loading: false,
                    cashbackOperations: [...state.cashbackOperations, ...action.cashbackOperations]
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    cashbackOperationsCount: action.count,
                    cashbackOperations: action.cashbackOperations
                }
            }

        case TransactionActionTypes.LOAD_TRANSACTIONS_SUCCESS:
            if (action.action === "add") {
                return {
                    ...state,
                    transactionsCount: action.count,
                    loading: false,
                    transactions: [...state.transactions, ...action.transactions]
                }
            } else {
                return {
                    ...state,
                    transactionsCount: action.count,
                    loading: false,
                    transactions: action.transactions
                }
            }

        case TransactionActionTypes.LOAD_BETS_SUCCESS:
            if (action.action === "add") {
                return {
                    ...state,
                    betsCount: action.count,
                    loading: false,
                    bets: [...state.bets, ...action.bets]
                }
            } else {
                return {
                    ...state,
                    betsCount: action.count,
                    loading: false,
                    bets: action.bets
                }
            }
        case TransactionActionTypes.INITIAL_TRANSACTIONS:
                return {
                    error: undefined,
                    loading: false,
                    transactionsCount: 0,
                    cashbackOperationsCount: 0,
                    betsCount: 0,
                    cashbackOperations: [],
                    transactions: [],
                    bets: [],
                }
        default:
            return state;
    }
}


export default transactionReducer;
