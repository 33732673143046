import settings from "../settings";
declare const window: any;

declare const BTRenderer: any;

const services = settings.NODE_ENV === "development" ? settings.SETTINGS.SERVICES_DEV : settings.SETTINGS.SERVICES_PROD;
const betby = services["BETBY"];

let betbySettings = {
    lang: "en",
    target: document.getElementById('betby'),
    betSlipOffsetTop: 0,
    betslipZIndex: 999,
    fontFamilies: ['Roboto Condensed, sans-serif'],
}

interface IUpdate {
    betSlipOffsetTop?: number,
    betSlipOffsetBottom?: number,
    betSlipOffsetRight?: number,
    betslipButton?: HTMLElement,
    url?: string,
    stickyTop?: number,
}

export class betbyService {
    private bt: any;

    constructor(token?: string, extra?: any) {
        this.bt = this.initialize(token, extra)
    }

    public clean() {
        this.bt.kill();
    };

    public update(obj: IUpdate) {
        this.bt.updateOptions({
            ...obj
        })
    };

    private initialize(token?: string, extra?: any) {
        try {
            return new window.BTRenderer().initialize({
                ...betbySettings,
                onTokenExpired: () => {},
                onRouteChange: () => {},
                onLogin: () => {},
                onRegister: () => {},
                onSessionRefresh: () => {},
                onBetSlipStateChange: () => {},
                ...extra,
                token: token ?? "",
                brand_id: betby["BRAND_ID"],
                themeName: betby["THEME_NAME"],
            })
        } catch (e) {
            console.warn(e)
        }

    }

}
