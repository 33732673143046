import React, {useEffect, useState} from 'react';
import {IProviderGame} from "store/types/gamesTypes";

import ProviderBlock from "./ProviderBlock";
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

type GamesAreaProps = {
    games: IProviderGame[],
    title: string,
    provider: string,
    category: string,
    isLoading: boolean,

}
const GamesArea = (props: GamesAreaProps): JSX.Element => {
    const {games, title, category, provider} = props;
    const [gamesScroll, setGamesScroll] = useState<IProviderGame[]>(games.slice(0, 5));
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        setGamesScroll(games.slice(0, 5));
        setPage(1);
        setHasMoreItems(true);
    }, [title, provider, category]);


    useEffect(() => {
        setGamesScroll(games.slice(0, (page + 1) * 5));
        setHasMoreItems(true);
    }, [games]);

    const loadMore = async () => {
        if (gamesScroll.length === games.length) {
            setHasMoreItems(false);
            return;
        }
        setPage(page + 1);
        setGamesScroll(games.slice(0, (page + 1) * 5))
        setHasMoreItems(true);
    };

    return (
        <Wrapper>
            <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                hasMore={hasMoreItems}
                loader={<></>}
            >
                <ProviderContainer>
                    {
                        gamesScroll.map((item, index) => (
                            <React.Fragment key={`games-with-providers-${index}-${item.SectionId}`}>
                                <ProviderBlock title={title}
                                               category={category}
                                               providerGame={item}
                                               isLoading={props.isLoading}/>
                            </React.Fragment>
                        ))
                    }

                </ProviderContainer>
            </InfiniteScroll>
        </Wrapper>
    );
};

export default GamesArea;


const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.palette.primary.main};
`

const ProviderContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`

