import React from 'react';
import {ReactComponent as MenuIcon} from "assets/images/header_menu_icon.svg";
import {ReactComponent as SignInIcon} from "assets/images/header_signin_icon.svg";
import {useNavigate} from "react-router-dom";
import CustomDrawer from "../drawer";
import {IUser} from "store/types/userTypes";
import InfoContainer from "components/wrapper/header/InfoContainer";
import styled from "styled-components";
import {getStorage} from "../../../hooks/useStorage";
import CashbackButton from './CashbackButton';

type HeaderProps = {
    user: IUser | null
    isOpenDrawer: boolean,
    setOpenDrawer: (open: boolean) => void,
    setOpenLogin: () => void,
}
const Header = (props: HeaderProps) => {
    const navigate = useNavigate();
    const handleDrawer = () => {
        props.setOpenDrawer(!props.isOpenDrawer);
    };
    const PATH = getStorage("APP_SEO.LOGO_PATH");
    const PROFILE_CASHBACK = getStorage("APP_OTHER.HEADER.MENU.CASHBACK.VISIBLE");
    const logo = require(`../../../${PATH}`)
    return (
        <>
            <CustomAppBar>

                <CustomToolBar>
                    <div
                        style={{padding: 0, cursor: "pointer"}}
                        onClick={handleDrawer}
                    >
                        <MenuIcon style={{width: 27}}/>
                    </div>

                    <Logo>
                        <LogoIcon src={logo} alt={"desktop-logo-mobile"} onClick={() => {
                            navigate("/");
                        }}/>
                    </Logo>

                    {!props.user ?
                        <div
                            style={{cursor: "pointer"}}
                            onClick={() => props.setOpenLogin()}
                        >
                            <SignInIcon style={{width: 27}}/>
                        </div> :
                        <>
                            {PROFILE_CASHBACK && <CashbackButton/>}
                            <InfoContainer/>
                        </>

                    }
                </CustomToolBar>
            </CustomAppBar>
            <CustomDrawer open={props.isOpenDrawer} setClosed={() => {
                props.setOpenDrawer(false);
            }}/>

        </>
    );
};

export default Header;

const CustomAppBar = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0;
  left: auto;
  right: 0;
  z-index: 1201;
  height: 60px;
  color: #fff;
  padding: 0;
  margin: 0;
  background: ${props => props.theme.palette.primary.dark};
  box-shadow: 0px 0px 30px rgb(0 0 0 / 51%);
`

const CustomToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  padding: 8px 13px;
  width: 100%;
  min-height: 60px;
`


const Logo = styled.div`
  justify-content: center;
  display: flex;
  height: 100%;
  text-align: center;
  align-items: center;
`

const LogoIcon = styled.img`
  cursor: pointer;
  z-index: 1000;
  width: auto;
  height: 46px;
`
