import React, {useEffect, useState } from "react";

const useTimeCounter = () => {
    const [time, setTime] = useState();



    return time;
}

export default useTimeCounter;
