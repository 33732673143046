import { createEffect } from "effector"
import {getHallOfFame, getHallOfFameMonths} from "../api/hall-of-fame.api";

export const hallOfFameFX = createEffect(async (params?: {month: string}) => {
    const response = await getHallOfFame(params);
    return response.data
})

export const hallOfFameMonthFX = createEffect(async () => {
    const response = await getHallOfFameMonths();
    return response.data
})
