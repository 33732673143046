import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {MobileContainer} from 'ui/containers';
import BackButton from 'ui/BackButton';
import {ReactComponent as CashbackHistoryIcon} from "assets/images/profile_logo_cashback-history_icon.svg"
import FilterDateCustom from 'ui/FilterDateCustom';
import OperationItem from "ui/OperationItem";

import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from "hooks/useActions";
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from "react-i18next";

const CashbackHistory = (): JSX.Element => {
    const [page, setPage] = useState(1);
    const {t, i18n} = useTranslation();

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [calendar, setCalendar] = useState({
        range_start: new Date().setDate(new Date().getDate() + 7),
        range_end: +new Date(),
    });

    const {cashbackOperationsCount, cashbackOperations, isLoading, error} = useTypedSelector((state) => {
        return {
            error: state.transaction.error,
            isLoading: state.transaction.loading,
            cashbackOperationsCount: state.transaction.cashbackOperationsCount,
            cashbackOperations: state.transaction.cashbackOperations
        }
    })

    const {getCashbackOperations} = useActions();

    useEffect(() => {
        setIsLoadingData(true);
        handleSearch().then(() => {
            setPage(1);
            setHasMoreItems(true);
            setIsLoadingData(false);
        })
    }, [calendar]);


    const handleSearch = async () => {
        await getCashbackOperations({
            range_start: calendar.range_start,
            range_end: calendar.range_end,
        });

    }

    const loadMore = () => {
        if (cashbackOperations.length >= cashbackOperationsCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getCashbackOperations({
            page: page + 1,
            range_start: calendar.range_start,
            range_end: calendar.range_end,
            action: "add",
        })

    };

    return (
        <>
            <BackButton href={'/profile'} title={t(`common.options.back_to_profile`)}/>
            <MobileContainer>
                <Content>
                    <FlexContent style={{gap: 20}}>
                        <CashbackHistoryIcon/>
                        <Title
                            style={i18n.language === "en" ? {} : {fontSize: 18}}>{t(`pages.cashback_history.title`)}</Title>
                    </FlexContent>
                    <div style={{width: "100%", padding: "20px 50px", boxSizing: "border-box"}}>
                        <FilterDateCustom isLoading={isLoadingData}
                                          onSave={(from: Date, to: Date) => {
                                              setCalendar({range_start: +from, range_end: +to})
                                          }}/>
                    </div>
                </Content>
                <InfiniteScroll
                    dataLength={cashbackOperations.length}
                    next={loadMore}
                    hasMore={hasMoreItems}
                    loader={<></>}
                >
                    <ContainerHistory>
                        {
                            cashbackOperations.map((item, index) => (
                                <React.Fragment key={`bet-history-${index}-${item.createdAt}`}>
                                    <OperationItem isCashback
                                                   profit={`${item?.profit?.toFixed(2)}`}
                                                   limit={`${item?.limit?.toFixed(2)}`}
                                                   totalDeposit={`${item?.totalDeposit?.toFixed(2)}`}
                                                   amount={`${item.amount.toFixed(2)}`}
                                                   date={item.createdAt}/>
                                </React.Fragment>
                            ))

                        }
                    </ContainerHistory>
                </InfiniteScroll>
            </MobileContainer>
        </>
    );
};

export default CashbackHistory;


const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

}))

const Content = styled(FlexContent)((props) => ({
    paddingTop: "50px",
}))

const Title = styled(`div`)((props) => ({
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: 600,
    fontFamily: "Roboto Condensed"
}))


const ContainerHistory = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
    paddingTop: 24,
}))


