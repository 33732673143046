export const sizes = {
  mobile: 375,
  mobileL: 576,
  tablet: 768,
  laptopS: 1024,
  laptop: 1280,
  laptopL: 1400,
  desktopS: 1600,
  desktop: 1920,
  desktopL: 2560,
};

export const devices = {
  mobile: `@media screen and (max-width: ${sizes.mobile - 1}px)`,
  mobileL: `@media screen and (max-width: ${sizes.mobileL - 1}px)`,
  tablet: `@media screen and (max-width: ${sizes.tablet - 1}px)`,
  laptopS: `@media screen and (max-width: ${sizes.laptopS - 1}px)`,
  laptop: `@media screen and (max-width: ${sizes.laptop - 1}px)`,
  laptopL: `@media screen and (max-width: ${sizes.laptopL - 1}px)`,
  desktopS: `@media screen and (max-width: ${sizes.desktopS - 1}px)`,
  desktop: `@media screen and (max-width: ${sizes.desktop - 1}px)`,
  desktopL: `@media screen and (min-width: ${sizes.desktopL}px)`,
};

export class BreakPoints {
  static up(size: keyof typeof sizes) {
      return `@media screen and (min-width: ${sizes[size]}px)`
  };

  static down(size: keyof typeof sizes) {
    return `@media screen and (max-width: ${sizes[size]}px)`
  };
}