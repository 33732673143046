import React from 'react';
import routingLinks from "constants/routing";
import {getStorage, putStorage} from "hooks/useStorage";
import {Route} from 'react-router-dom';
import {RoutingList} from "../constants/types";

const GetCorrectRoutes = (isAuth: boolean, exclude: string[], isDesktop?: boolean) => {
    const access = getStorage("APP_ACCESS");
    const objEnt: [string, { VISIBLE: boolean, PERMISSIONS: {} }][] = Object.entries(access);
    const correctAccess = objEnt.reduce((acc: string[], [key, val]) => val.VISIBLE ? [...acc, key] : acc, []);
    putStorage("CLEAR_PAGE_ACCESS", correctAccess, {silent: true});
    const correctRoutes = routingLinks.filter(route => correctAccess.some(item => route.key === item));
    const deviceRoutes: RoutingList[] = isDesktop ? correctRoutes.filter(route => Object.keys(route).includes('component')).map(it => ({
            key: it.key,
            to: it.to,
            isAuth: it.isAuth,
            lang_key: it.lang_key,
            component: it.component
        })) :
        correctRoutes.filter(route => Object.keys(route).includes('mobileComponent')).map(it => ({
            key: it.key,
            to: it.to,
            isAuth: it.isAuth,
            lang_key: it.lang_key,
            component: it.mobileComponent
        }))
    const finalRoutes = deviceRoutes.filter(item => (!exclude.includes(item.key) && (!item.isAuth || isAuth)))
    return (
        <>
            {
                finalRoutes.map((item, index) => (
                    <React.Fragment key={`${item.key}-route-${index}`}>
                        <Route path={item.to} element={item.component}/>
                    </React.Fragment>
                ))
            }
        </>


    );
};

export default GetCorrectRoutes;
