import React, {useEffect, useState} from 'react';
import {ReactComponent as Arrow} from "assets/images/games_provider_arrow_icon.svg"
import {MobileContainer} from 'ui/containers';
import GameSwitcher from "components/gameSwitcher";
import {useTypedSelector} from "hooks/useTypedSelector";
import CustomSearch from 'ui/CustomSearch';
import {useActions} from "hooks/useActions";
import GameProviderCard from "ui/GameProviderCard";
import {useNavigate, useParams} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDebounce} from "hooks/useDebounce";
import styled from "styled-components";
import openGame from "helpers/gamesHelper/openGame";
import {useTranslation} from "react-i18next";


const Provider = (): JSX.Element => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const {providerId} = urlParams;
    const {t} = useTranslation();
    const {getProviderGames, toggleFavoriteGames, updateAlert, setLoading} = useActions();
    const [titleGame, setTitleGame] = useState("");

    const [page, setPage] = useState(1);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const [isFirstLoading, setIsFirstLoading] = useState(false);


    const {isLoading, games, user} = useTypedSelector((state) => {
        return {
            user: state.user.user,
            isLoading: state.game.loading,
            games: state.game.games.toArray().map(item => item[1]),
        }
    })
    const handleSearch = async () => {
        await getProviderGames({
            title: debouncedSearchTerm,
            action: "update",
            provider: providerId,
        });

    }

    useEffect(() => {
        setIsFirstLoading(true);
        handleSearch().then(() => {
            setPage(1);
            setHasMoreItems(true);
            setIsFirstLoading(false);
        });

    }, [providerId, debouncedSearchTerm]);


    const loadMore = () => {
        if (games.find(item => item.SectionId === providerId)?.Games.length! >= games.find(item => item.SectionId === providerId)?.Count!) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getProviderGames({
            title: titleGame,
            provider: providerId,
            action: "add",
            page: newPage
        })

    };

    const handleAlert = () => {
        updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_error`)}`})
    }

    const providerGames = games.find(item => item.SectionId === providerId)?.Games;
    return (
        <>
            <GameSwitcher/>
            <div style={{margin: "10px 5px"}}>
                <CustomSearch value={titleGame} OnCloseClick={() => {
                    setTitleGame("");
                }} onChange={(e) => {
                    setTitleGame(e.target.value)
                }} placeholder={`${t(`common.options.search_game`)}`}/>
            </div>
            <BackContainer onClick={() => navigate("/games")}>
                <Arrow/> <Text>{`${providerId}`}</Text>
            </BackContainer>
            <MobileContainer>
                {isFirstLoading || !providerGames?.length ? null : <InfiniteScroll
                    dataLength={providerGames.length}
                    next={loadMore}
                    hasMore={hasMoreItems && titleGame === ""}
                    loader={<></>}
                >

                    <ContainerGames>
                        {
                            providerGames.map((item, index) => (
                                <React.Fragment key={`game-${item.Id}-${index}`}>
                                    <GameProviderCard text={`${item.Name}`} favoriteClick={() => {
                                        if (!user) {
                                            handleAlert();
                                        } else {
                                            toggleFavoriteGames(item.Id, false);
                                        }
                                    }} isFavorite={item.IsFavorite} url={item.Thumbnail} onClick={() => {
                                        if (!user) {
                                            handleAlert();
                                        } else {
                                            openGame({
                                                providerId: providerId,
                                                gameId: item.Id,
                                                setLoading: (bool: boolean) => {
                                                    setLoading(bool);
                                                }
                                            })
                                        }
                                    }}/>
                                </React.Fragment>
                            ))
                        }
                    </ContainerGames>
                </InfiniteScroll>}
            </MobileContainer>
        </>
    );

};

export default Provider;


const ContainerGames = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px
`

const BackContainer = styled.div`
  display: flex;
  margin: 0 10px 10px 14px;
  width: fit-content;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Text = styled.div`
  text-transform: uppercase;
  font-family: Roboto Condensed;
  font-size: 18px;
  font-weight: 400;
`


const ContainerSuper = styled.div`
  position: relative;
  height: calc(100vh - 300px);
  width: 100%;
`
