import React from 'react';
import InstImg from "assets/images/drawer_instagram_img.png";
import FaceImg from "assets/images/drawer_facebook_img.png";
import DiscImg from "assets/images/drawer_dicord_img.png";
import YoutubeImg from "assets/images/drawer_youtube_img.png";
import InstImgDesktop from "assets/images/drawer_desktop_instagram_img.svg";
import FaceImgDesktop from "assets/images/drawer_desktop_facebook_img.svg";
import DiscImgDesktop from "assets/images/drawer_desktop_dicord_img.svg";
import YoutubeImgDesktop from "assets/images/drawer_desktop_youtube_img.svg";
import styled from 'styled-components';
import {getStorage} from "../hooks/useStorage";


type SocialLinksProps = {
    isDesktop?: boolean
}

const SocialLinks = (props: SocialLinksProps) => {
    const socialLinks = getStorage("APP_OTHER.SOCIAL_LINKS");
    let socialLinksArr: any = Object.values(socialLinks).filter((item: any) => item.VISIBLE);

    const data = socialLinksArr.map((item: any) => ({
        link: item.LINK,
        url: require(`../${item.ICON_PATH}`)
    }))

    return (
        <ImageContainer id={`social-links`}>
            {
                data.map((item: any, index: number) => (
                    <React.Fragment key={`social-link-${index}`}>
                        <a id={`social-links-${index}`} rel="noreferrer" target="_blank" href={`${item.link}`}>
                            <Images isDesktop={props.isDesktop} url={item.url}/>
                        </a>

                    </React.Fragment>
                ))
            }

        </ImageContainer>
    );
};

export default SocialLinks;


const Images = styled('div')<{ url: string, isDesktop?: boolean }>((props) => ({
    width: !props.isDesktop ? 26 : 40,
    height: !props.isDesktop ? 26 : 40,
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    backgroundImage: `url(${props.url})`,
    backgroundPosition: "center",
    backgroundSize: "contain"
}))

const ImageContainer = styled("div")((props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
}))
