import React, {useEffect, useState} from 'react';
import TextField from "./TextField";
import SearchIcon from "../../assets/images/input_search_icon.svg";
import {useDebounce} from "../../hooks/useDebounce";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {FixedSizeList as List} from "react-window";
import InfiniteLoader from 'react-window-infinite-loader';
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useActions} from "../../hooks/useActions";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import openGame from "../../helpers/gamesHelper/openGame";

type SmartSearchSelectProps = {
    onClick: (value: string) => void;
    onLoad: (page: number, size: number, value: string) => void;
}

const SmartSearchSelect = (props: SmartSearchSelectProps) => {
    const [titleGame, setTitleGame] = useState("");
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const {i18n, t} = useTranslation();
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const {getGameList} = useActions();
    const {
        gameList,
        gameListCount
    } = useTypedSelector((state) => {
        return {
            gameList: state.game.gameList.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }
            }),
            gameListCount: state.game.gameListCount,
        }
    })

    const isItemLoaded = (index: number) => !!gameList[index];

    const loadMoreItems = (startIndex: number, stopIndex: number) => {
        if (gameList.length === gameListCount) {
            setIsLoading(false)
        }
        let newPage = page + 1;
        setPage(newPage);
        getGameList({
            title: debouncedSearchTerm,
            size: 30,
            action: "add",
            page: newPage
        })
    };

    useEffect(() => {
        getGameList({
            title: debouncedSearchTerm,
            size: 30,
            action: "update"
        });
        setPage(1);
        setIsLoading(true);

    }, [debouncedSearchTerm]);

    const inputStyles = `
    &::placeholder {
    color: white;
  }`
    const extraInput = `
    height: 30px;
    width: 200px;
    background: transparent;
    border-color: white;
    font-size: 16px
    `
    return (
        <div style={{position: "relative"}}>
            <TextField placeholder={`${t(`common.options.search_game`)}`} rightIcon={{
                url: titleGame !== "" ? CrossIcon :SearchIcon,
                extra: `width: 16px; height: 16px;`,
                onClick: () => {
                    if (titleGame !== "") {
                        setTitleGame("")
                    }
                }
            }} extra={extraInput}
                       inputExtra={inputStyles} value={titleGame} onChange={(e) => {
                setTitleGame(e.target.value)
            }}/>
            {(debouncedSearchTerm !== "" && gameList.length !== 0) &&
                <ContainerSelect>
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={gameListCount}
                        loadMoreItems={loadMoreItems}
                    >
                        {({onItemsRendered, ref}) => (
                            <List
                                itemData={gameList}
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                                itemCount={gameList.length}
                                itemSize={30}
                                width={200}
                                height={200}
                            >
                                {Row}
                            </List>
                        )}
                    </InfiniteLoader>
                </ContainerSelect>
            }

        </div>
    );
};


const ContainerSelect = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 190px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
`

type RowProps = {
    index: number,
    style: any,
    data: any
}


const Row = (props: RowProps) => {
    const {setLoading} = useActions();
    const {data, index, style} = props;


    return (
        <SelectLi title={data[index]?.name} onClick={() => {

            openGame({
                isDesktop: true,
                providerId: data[index]?.providerId,
                gameId: data[index]?.id,
                setLoading: (bool: boolean) => {
                    setLoading(bool);
                }
            })
        }} style={style}>
            {data[index]?.name}
        </SelectLi>
    );
};

export default SmartSearchSelect;


const SelectLi = styled.div`
  color: black;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke;
  }
`
