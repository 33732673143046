import {combine, createEffect, createEvent, createStore, Effect, Event, restore, sample, Store} from 'effector'
import { Equal, useUnit } from 'effector-react';

import {t} from 'i18next';


type OptionsField<T> = {
    defaultValue: T
    reset?: Event<any>,
    isErrorReset?: boolean,
}

export const createEffectorField = <T>(options: OptionsField<T>): [Store<T>, Event<T>] => {
    const changeEvent = createEvent<T>()
    const $store = restore(changeEvent, options.defaultValue)
    if (options.reset) $store.reset(options.reset)
    return [$store, changeEvent]
}


export interface IStoreStatus<Res> {
    isLoading: boolean;
    error: Error | null;
    data: Res | null;
}

export const useCreateMutation = <Req, Res, Err extends Error = Error>(
    effect: Effect<Req, Res>,
    $store?: Store<Res>,
): [Equal<Req, void> extends true ? () => Promise<Res> : (payload: Req) => Promise<Res>, IStoreStatus<Res>] => {
    const $storeStatus = combine({
        isLoading: effect.pending,
        error: restore<Error>(effect.failData, null),
        data: restore<Res>(effect.doneData, null),
    });

    return useUnit([effect, $storeStatus]);
};
