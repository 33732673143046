import React from 'react';
import JackPot from "../ui/Jackpot";
import styled from "styled-components";
import { HallOfFame as HallOfFameFrame } from "features/hall-of-fame";
import {Container} from "../ui/containers";

const HallOfFame = () => {
    return (
        <>
            <JackpotContainer>
                <JackPot/>
            </JackpotContainer>
            <HallContainer>
                <HallOfFameFrame/>
            </HallContainer>
        </>
    );
};

export default HallOfFame;

const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
`

const HallContainer = styled(Container)`
  width: 100%;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 0px 14px;
`
