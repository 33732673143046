import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import background from "assets/images/background_sign_in_img.png";
import loginIcon from "assets/images/sign_in_login_input_icon.svg";
import passwordIcon from "assets/images/sign_in_password_input_icon.svg";

import TextField from "../ui/material-kit/TextField";
import StyledButton from "../ui/StyledButton";
import {useInput} from "../hooks/useInput";
import {userLogin} from "../store/actions/userActions";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {useActions} from "../hooks/useActions";
import {getStorage} from "../hooks/useStorage";
import LanguageController from "../components/language";
import useKeyPress from 'hooks/useKeyPress';

const SignIn = () => {
    const PATH: string = getStorage("APP_SEO.LOGO_PATH");
    const logo = require(`../${PATH}`)

    const {t} = useTranslation();
    const userName = useInput('');
    const password = useInput('');
    const [state, setState] = useState(false);
    const {userLogin} = useActions();

    useKeyPress("Enter", async () => {
        await handleClick()
    }, [userName.value, password.value]);

    const {loading, error, user} = useTypedSelector(state => {
        return {
            user: state.user.user,
            loading: state.user.loading,
            error: state.user.error || ""
        }
    })

    const fields = {
        title: t(`common.login.title`),
        start_winning: t(`common.login.start_winning`),
        username: t(`common.login.username`),
        password: t(`common.login.password`),
        loginButton: t(`common.login.login_button`),
    }

    const handleClick = async () => {
        if (userName.value !== "" && password.value !== "") {
            await userLogin({login: userName.value, password: password.value});
            setState(true)
        }

    }

    return (
        <Container id={"sing-in"} url={background}>
            <div style={{position: "absolute", top: 10, right: 20}}>
                <LanguageController isDesktop/>
            </div>
            <Form>
                <Icon url={logo}/>
                <CredentialsContainer>
                    <Row>
                        <Line/>{fields.title}<Line/>
                    </Row>
                    <TextField leftIcon={{url: loginIcon, extra: iconInputsStyle}}
                               placeholder={fields.username} {...userName} extra={InputStyle}/>
                    <TextField placeholder={fields.password} extra={InputStyle} type={'password'} {...password}
                               leftIcon={{url: passwordIcon, extra: iconInputsStyle}}
                           />
                    <StyledButton onClick={handleClick} isDisabled={loading}
                                  extra={ButtonStyle}>
                        {
                            loading ? "..." : fields.loginButton
                        }
                    </StyledButton>
                    {error !== "" && state && <ErrorBlock>
                        {error === "incorrect login or password" ? t("common.errors.incorrect_login_or_password") : error}
                    </ErrorBlock>}
                </CredentialsContainer>
            </Form>
        </Container>
    );
};

export default SignIn;

const Container = styled.div<{ url: string }>`
  background: url("${props => props.url}") repeat;
  background-color: ${props => props.theme.palette.primary.dark};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Form = styled.div`
  overflow: hidden;
  z-index: 10000;
  max-width: 761px;
  width: 100%;
  height: 100%;
  min-height: 500px;
  height: fit-content;
  min-width: 340px;
  background-color: ${props => props.theme.palette.primary.dark};
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 50px;
  margin: 20px;
  box-sizing: border-box;
  @media (max-width: 700px) {
    padding: 20px 20px 30px;
  }
`

const Icon = styled.div<{ url: string }>`
  background: url("${props => props.url}");
  height: 200px;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px 40px;
  @media (max-width: 558px) {
    height: 120px;
  }
`

const CredentialsContainer = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  max-width: 630px;
  @media (max-width: 558px) {
    gap: 24px;
  }
`

const ErrorBlock = styled.div`
  position: absolute;
  bottom: 58px;
  color: red;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 40px;
  gap: 25px;
  width: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 700px) {
    font-size: 30px;
    gap: 15px;
  }
  @media (max-width: 558px) {
    font-size: 20px;
  }
`

const Line = styled.div`
  color: #FFFFFF;
  width: 100%;
  height: 0px;
  margin: 5px;
  box-sizing: border-box;
  border: 1px solid #FFB401;
  background-color: #FFB401;
`

const iconInputsStyle = `
    width: 25px;
    height: 25px;
    position: relative;    
    margin: 0px 15px
`

const InputStyle = `
  height: 75px;
  @media (max-width: 700px) {
    height: 54px;
  }
`

const ButtonStyle = `
    height: 54px; 
    width: 100%; 
    max-width: 300px; 
    font-weight: 700;
`
