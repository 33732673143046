import React from 'react';
import styled from 'styled-components';
import {MobileContainer} from 'ui/containers';
import BackButton from 'ui/BackButton';
import {ReactComponent as PersonalDataIcon} from "assets/images/profile_logo_personal-data_icon.svg";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from "hooks/useActions";
import {useTranslation} from "react-i18next";
import TextField from "ui/material-kit/TextField";
import CopyIcon from "assets/images/copy_icon.svg";

const PersonalData = (): JSX.Element => {
    const {updateAlert} = useActions();
    const {t,i18n} = useTranslation();
    const {user} = useTypedSelector((state) => {
        return {
            user: state.user.user
        }
    });

    const handleClickProfile = async () => {
        await (() => {
            navigator.clipboard.writeText(`ID:  ${user?.id}`);
        })()
        updateAlert({
            isOpen: true,
            status: "warning",
            message: "Code has been copied"
        })
    }
    return (
        <>

            <BackButton href={'/profile'} title={t(`common.options.back_to_profile`)}/>
            <MobileContainer>
                <Content>
                    <FlexContent style={{gap: 20}}>
                        <PersonalDataIcon/>
                        <Title style={i18n.language === "en"?{}: {fontSize: 18}}>{t(`pages.personal_data.title`)}</Title>
                    </FlexContent>

                    <FlexContent style={{gap: 10, padding: "20px 40px"}}>

                        <TextField readOnly extra={`height: 40px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.id_field`)}:  ${user?.id}`} rightIcon={{
                            url: CopyIcon,
                            onClick: async () => {await handleClickProfile()}
                        }}/>
                        <TextField readOnly extra={`height: 40px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.username_field`)}:  ${user?.login}`} />

                        <TextField readOnly extra={`height: 40px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.balance_field`)}:  ${user?.balance.toLocaleString()}`} />

                        <TextField readOnly extra={`height: 40px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.currency_field`)}:  ${user?.currency.toUpperCase()}`} />
                    </FlexContent>
                </Content>
            </MobileContainer>
        </>
    );
};

export default PersonalData;


const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
}))


const Content = styled(FlexContent)((props) => ({
    paddingTop: "50px",
}))

const Title = styled(`div`)((props) => ({
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: 600,
    fontFamily: "Roboto Condensed"
}))

