import React, {useEffect, useState} from 'react';
import Modal from "../../ui/material-kit/Modal";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import styled from "styled-components";
import LottieWorkLoader from "../../ui/loaders/LottieWorkLoader";
import formatDate from "../../helpers/formatDate";
import {useTranslation} from "react-i18next";
import {formatDistanceToNow, formatDistanceToNowStrict} from 'date-fns';
import {enGB, fr} from "date-fns/locale";

type WorkModalProps = {
    isDesktop?: boolean,
    open: boolean,
    onClose: () => void,
}

const locales: any = {en: enGB, fr: fr}

const Work = (props: WorkModalProps) => {
    const timeout = 1658718000000;
    const [dateState, setDateState] = useState<string>();
    const {i18n} = useTranslation();
    useEffect(() => {
        setInterval(() => setDateState(formatDistanceToNowStrict(
            new Date(timeout), {locale: locales[`${i18n.language}`]}
        )), 1000);
    }, []);

    return (
        <Modal
            open={props.open}
            isFullScreen={!props.isDesktop}
            onClose={props.onClose}>
            <Background>
                <IconContainer>
                    <ExitIcon url={CrossIcon} onClick={props.onClose}/>
                </IconContainer>
                <Container isDesktop={props.isDesktop}>
                    <LottieWorkLoader/>
                    <Time>{dateState && dateState}</Time>
                    {
                        i18n.language === "fr" ? <Label>
                            <b style={{color: "#FFB401", fontSize: 26}}>Chers utilisateurs!</b>
                            <p style={{fontSize: 20}}>
                                Nous souhaitons vous informer que la maintenance sera en cours <br/> le lundi 2022-07-25 de 04h00 à 13h00.<br/>
                                Le temps d'arrêt prévu est de 9 heures.
                                Pendant cette période, le site peut ne pas être disponible.
                                Nous nous excusons pour tout inconvénient que cela pourrait causer.
                                Votre compréhension et votre patience sont très appréciées.<br/>
                                Cordialement, l'équipe de Koora365.
                            </p>
                        </Label> : <Label>
                            <b style={{color: "#FFB401", fontSize: 26}}>Dear users!</b>
                            <p style={{fontSize: 20}}>
                                We’d like to inform you that koora365.org will have maintenance in production <br/> on Monday 2022-07-25 at 04:00-13:00 (Tunisia time). <br/>
                                The expected downtime is 9 hours. During this stated time site may not be available.
                                We apologize for any inconvenience it may cause.
                                Your understanding and patience is highly appreciated.<br/>
                                Best regards, Koora365 team.
                            </p>
                        </Label>
                    }

                </Container>
            </Background>
        </Modal>
    );
};

export default Work;

const Background = styled.div`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;

`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Container = styled.div<{ isDesktop?: boolean }>`
  width: ${props => props.isDesktop ? "740px" : "100%"};
  height: ${props => props.isDesktop ? "480px" : "calc(100% - 74px)"};
  margin-top: -80px;
  padding: ${props => props.isDesktop ? "50px" : "10px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 18px;
  justify-content: center;

`


const ExitIcon = styled.div<{ url: string }>`
  position: relative;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 24px;
  height: 24px;
  margin: 15px;
  cursor: pointer;
`
const Label = styled.span`
  color: #FFFFFF;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
`

const Time = styled.span`
  color: #FFB401;
  margin-top: -80px;
  font-weight: 600;
  font-size: 24px;
`
