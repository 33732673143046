import React from 'react';
import {ReactComponent as Star} from "assets/images/games_star_icon.svg"
import LazyImage from './LazyImage';
import styled from 'styled-components';
import {ReactComponent as Play} from "../assets/images/game_play_button_img.svg";

type GameCardProps = {
    onClick: () => void,
    isTvGame?: boolean,
    TvGameWidth?: string,
    TvGameHeight?: string,
    isFavorite: boolean,
    favoriteClick: (e: React.MouseEvent<HTMLElement>) => void,
    url: string
}
const GameCard = (props: GameCardProps): JSX.Element => {
    const {width, height} = {
        width: props.TvGameWidth ? props.TvGameWidth : undefined,
        height: props.TvGameHeight ? props.TvGameHeight : undefined
    }
    return (
        <Wrapper id={`game-card`} width={width} height={height} onClick={props.onClick}>
            <Image width={width} height={height} src={props.url} alt={`game-${props.url}`}/>
            <GamePlaceholder>
                <SlotIconPlay>
                    <PlayIcon/>
                </SlotIconPlay>
            </GamePlaceholder>
            {!props.isTvGame &&
                <IconCustomContainer onClick={props.favoriteClick}>
                    <CustomIconButton isFavorite={props.isFavorite}>
                        <Star/>
                    </CustomIconButton>
                </IconCustomContainer>}
        </Wrapper>
    );
};

export default GameCard;

const PlayIcon = styled(Play)`
  margin-left: 5px;
  width: 20px;
  display: none;
  height: 20px
`

const SlotIconPlay = styled.div`
  width: 0;
  height: 0;
  border-radius: 50%;
  background: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all 0.3s;

  :hover {
    width: 4rem;
    height: 4rem;
    
  }
`


const GamePlaceholder = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 100px;
  display: flex;
`


const Wrapper = styled.div<{ width?: string, height?: string }>`
  width: ${props => props.width ? props.width : "93px"};
  height: ${props => props.height ? props.height : "93px"};
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  background-origin: border-box;
  cursor: pointer;

  :hover {
    ${SlotIconPlay} {
      width: 4rem;
      height: 4rem;
    }
    ${PlayIcon} {
      display: block;
    }
  }
`


const Image = styled(LazyImage)<{ width?: string, height?: string }>`
  width: ${props => props.width ? props.width : "93px"};
  height: ${props => props.height ? props.height : "93px"};
  border-radius: 4px;
  background-origin: border-box;
  z-index: 0;
  object-fit: cover;
  object-position: center;
`


const IconCustomContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`


const CustomIconButton = styled("div")<{ isFavorite: boolean }>`
  margin: 0;
  cursor: pointer;

  & > svg > path {
    fill: ${props => props.isFavorite ? "#FFB401" : "#263154"}
  }
`
