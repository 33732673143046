import React, {useRef, useState} from 'react';
import styled from "styled-components";
import FullSmallScreenIcon from "../../assets/images/game_small-full-screen_icon.svg";
import {ReactComponent as ArrowIcon} from 'assets/images/profile_back-arrow_icon.svg';
import ExitFullSmallScreenIcon from "../../assets/images/game_exit-small-full-screen_icon.svg";
import FullScreenIcon from "../../assets/images/game_fullscreen_icon.svg";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import formatDate from "../../helpers/formatDate";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import useTime from "../../hooks/useTime";
import {useTranslation} from "react-i18next";
import {getStorage} from "../../hooks/useStorage";
import SmartSearchSelect from "../../ui/material-kit/SmartSearchSelect";

type DesktopGameProps = {
    gameInfo: any,
    dataGame: string
}
const DesktopGame = (props: DesktopGameProps) => {

    const {gameInfo, dataGame} = props;
    const {i18n, t} = useTranslation();
    const [frameState, setFrameState] = useState<"screen" | "largeScreen">("screen")
    const navigate = useNavigate();
    const {user} = useTypedSelector(state => {
        return {
            user: state.user.user,

        }
    })
    const time = useTime();
    const frameRef = useRef<HTMLIFrameElement>(null);
    const picture = (gameInfo.AnimationThumbnail ?? gameInfo.Thumbnail) || "";

    return (
        <div style={{overflow: "hidden"}}>
            <Image src={picture}/>
            <header>
                <Header style={{background: picture === "" ? "rgba(138,138,138,0.07)" : "rgba(0, 0, 0, 0.2)"}}>
                    <HeaderContent>
                        <Text style={{cursor: "pointer"}} onClick={() => {
                            navigate('/games')
                        }}>
                            <ArrowIcon style={{width: 16, height: 16}}/> {t(`common.alert.back_to_games`)}
                        </Text>
                        <Text style={{fontWeight: 600}}>
                            {getStorage("APP_SEO.TITLE")}
                        </Text>
                        <SmartSearchSelect onClick={(value) => {

                        }} onLoad={(page, size, value) => {

                        }}/>
                    </HeaderContent>
                </Header>
            </header>
            <SingleGame>
                <SingleGameWrapper style={{
                    width: window.innerWidth * 0.7,
                    height: ((window.innerHeight - 180) >= (window.innerWidth * 0.7)) ? window.innerWidth * 0.5 : window.innerHeight - 180,
                    backgroundColor: picture === "" ? "rgba(138,138,138,0.07)" : "#ffffff"
                }}>
                    <SingleGameHeader>
                        <GameTitle>{gameInfo.Name}</GameTitle>
                        <GamesControl>
                            {frameState === "screen" ? <Icon url={FullSmallScreenIcon} onClick={() => {
                                setFrameState("largeScreen");
                                document.body.requestFullscreen();
                            }}/> : <Icon url={ExitFullSmallScreenIcon} onClick={() => {
                                setFrameState("screen");
                                document.exitFullscreen();
                            }}/>}

                            <Icon url={FullScreenIcon} onClick={() => {
                                setFrameState("screen");
                                frameRef.current?.requestFullscreen();
                            }}/>
                            <Icon url={CrossIcon} onClick={() => {
                                navigate("/");
                            }}/>
                        </GamesControl>
                    </SingleGameHeader>
                    <SingleGameMain>
                        <FrameContainer>
                            <Frame ref={frameRef} src={dataGame} frameBorder={0} allowFullScreen={true}
                                   height={"100%"} width={"100%"}/>
                        </FrameContainer>
                    </SingleGameMain>
                </SingleGameWrapper>
            </SingleGame>
            <Footer style={{background: picture === "" ? "rgba(138,138,138,0.07)" : "rgba(0, 0, 0, 0.2)"}}>
                <div>

                </div>
                <div style={{display: "flex", gap: 10, margin: 10}}>
                    <Text style={{fontWeight: "bold"}}>
                        ID: {user?.id}
                    </Text>
                    <Text>
                        {`${formatDate({
                            date: time,
                            dateFormat: 'dd.MM.yyyy HH:mm O',
                            lang: i18n.language
                        })}`}
                    </Text>
                </div>

            </Footer>

        </div>
    );
};

export default DesktopGame;


const Icon = styled.div<{ url: string }>`
  position: relative;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 5px;

  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`
const Text = styled.div`
  font-size: 18px;
`;

const Footer = styled.div`
  border: 1px solid #3b3b3e;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-width: 1028px;
  height: 44px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GamesControl = styled.div`
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
`;

const GameTitle = styled.h1`
  color: #fff;
  font-size: 24px;
  line-height: 1em;
  padding: 0;
  display: inline-block;
  float: left;
  margin: 6px 0 0;
  text-transform: none;
  font-weight: 700;
`;

const Frame = styled.iframe`
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const FrameContainer = styled.div`
  float: left;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  text-align: center;
`;

const SingleGameHeader = styled.div`
  position: relative;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

const SingleGameMain = styled.div`
  height: calc(100% - 47px);
`;

const SingleGame = styled.div`
  text-align: center;
  z-index: 1000;
  margin-top: 20px;
`;

const SingleGameWrapper = styled.div`
  padding: 16px;
  border-radius: 3px;
  display: block;
  text-align: left;
  width: 1032px;
  height: 621.728px;
  z-index: 2;
  margin: 0 auto;
  max-width: 92%;
`;

const HeaderContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;


const Header = styled.div`
  z-index: 3;
  height: 50px;
  position: relative;
  color: white;
  width: 100%;
`;

const Image = styled.div<{ src: string }>`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(60px);
  background-image: url(${props => props.src});
`;


const GamingContainer = styled.div`
  position: relative;
  display: grid;
  border-radius: 10px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0px, 0px, 0px);

  :nth-child(1) {
    display: block;
    grid-area: 1 / 1 / 3 / 3;
  }

  :nth-child(18) {
    grid-area: 3 / 6 / 5 / 8;
  }
`


