import styled, {css} from "styled-components";

export default styled("button")<{ isAnimated?: boolean }>`
  height: 38px;
  width: 175px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;

  font-family: Roboto Condensed;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #000000;
  animation: animate 8s linear infinite;
  background: linear-gradient(90deg, #FFB401, #FFED4F, #FFED4F, #FFB401);
  background-size: 400%;

  ${props => props.isAnimated && css`
    @keyframes animate {
      0% {
        background-position: 400%;
      }
      100% {
        background-position: 0%;
      }
    }
  `}
  :active {
    background: linear-gradient(90deg, #FFB401, #FFB401, #FFB401, #FFB401);
  }

  @media (max-width: 1540px) {
    height: 31px;
  }

  @media (max-width: 500px) {
    height: 37px;
    width: 111px;
    font-size: 15px;
    line-height: 18px;
  }

  @media (max-width: 370px) {
    height: 30px;
    width: 100px;
    font-size: 13px;
    line-height: 8px;
  }
  
`
