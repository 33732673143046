import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const GameSwitcher = () => {
    const {pathname} = useLocation();
    const [value, setValue] = useState('/');
    const navigate = useNavigate();
    const {t} = useTranslation();
    useEffect(() => {
        setValue(pathname);
    }, [pathname]);
    return (
        <Wrapper>
            <StyledButton style={{borderRadius: "5px 0px 0 5px"}} onClick={() => {
                navigate('/games')
            }} isActive={value.includes('/games')}>
                {`${t(`common.navbar.nav_games`)}`}
            </StyledButton>
            <StyledButton isActive={value === '/tv-games'} style={{borderRadius: "0 5px 5px 0"}} onClick={() => {
                navigate('/tv-games')
            }}>
                {`${t(`common.navbar.nav_tv_games`)}`}
            </StyledButton>
        </Wrapper>
    );
};

export default GameSwitcher;


const Wrapper = styled(`div`)((props) => ({
    width: "100%",
    background: props.theme.palette.primary.dark,
    padding: "8px 36px",
    direction: "ltr",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 376px)": {
        padding: "8px",
    },
}));

const StyledButton = styled("button")<{ isActive?: boolean }>((props) => ({
    padding: "8px 48px",
    width: "100%",
    borderRadius: "5px 0px 0 5px",
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    cursor: "pointer",
    height: 30,
    whiteSpace: "nowrap",
    background: props.isActive ? "#FFB401" : "#263154",
    color: props.isActive ? "#121931" : "#ffffff",
    fontSize: 14,
    fontWeight: 400,
    "&:hover": {
        background: props.isActive ? "#d09402" : "#1f2846",
    }
}));

