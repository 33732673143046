import React from 'react';
import StyledMobileButton from "ui/StyledMobileButton";
import {ReactComponent as ExitIcon} from "assets/images/profile_sign-out_icon.svg";
import {useActions} from "hooks/useActions";
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import routing from "../../../constants/routing";
import {getStorage} from "../../../hooks/useStorage";

type NavigationLeftBarProps = {
    heightButton?: string
}

const NavigationLeftBar = (props: NavigationLeftBarProps): JSX.Element => {
    const {logoutUser} = useActions();
    const {t, i18n} = useTranslation();

    const access = getStorage("APP_ACCESS");
    const objEnt: [string, { VISIBLE: boolean, PERMISSIONS: {} }][] = Object.entries(access);
    const correctAccess = objEnt.reduce((acc: string[], [key, val]) => val.VISIBLE ? [...acc, key] : acc, []);

    return (
        <>
            <Content>
                {
                    routing.filter(item => item.key.includes("PROFILE_") && correctAccess.includes(item.key)).map((item, idx) => (
                        <React.Fragment key={`menu-slides-${idx}`}>
                            <StyledMobileButton style={i18n.language === "en" ? {} : {fontSize: 15}}
                                                heightButton={props.heightButton}
                                                title={t(`common.profile.${item.lang_key}`)} src={item.to}
                                                iconJSX={item.iconJsx}/>
                        </React.Fragment>
                    ))
                }
                <div style={{margin: "13px", width: "100%"}}>
                    <MyDivider style={{borderColor: "#666873"}}/>
                </div>
                <div style={{width: "100%"}} onClick={async () => {
                    await logoutUser()
                }}>
                    <StyledMobileButton style={i18n.language === "en" ? {} : {fontSize: 15}}
                                        title={t(`common.info.log_out`)} src={"/"} iconJSX={<ExitIcon/>}/>
                </div>
            </Content>


        </>
    );
};

export default NavigationLeftBar;


const Content = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 7,
}))
const MyDivider = styled(`hr`)((props) => ({}))
