import {combineReducers} from "redux";
import userReducer from "./userReducer";
import gameReducer from "./gameReducer";
import transactionReducer from "./transactionReducer";
import appReducer from "store/reducers/appReducer";

const rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    game: gameReducer,
    transaction: transactionReducer
});



export default rootReducer;
