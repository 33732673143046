import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from 'hooks/useActions';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {getStorage} from "../../../hooks/useStorage";
import routing from "../../../constants/routing";
import {RoutingList} from "../../../constants/types";

type FooterProps = {
    isOpenDrawer: boolean
}

const Footer = (props: FooterProps): JSX.Element => {
    const {t} = useTranslation();

    const {pathname} = useLocation();
    const [value, setValue] = useState('/');
    const {updateAlert, setLoading} = useActions()
    const navigate = useNavigate();
    const {user} = useTypedSelector((state) => {
        return {
            user: state.user.user
        }
    })

    useEffect(() => {
        setValue(pathname);
    }, [pathname]);

    const getCurrentValue = () => {
        if (value.includes("/games")) {
            return "/games"
        } else {
            return value
        }
    }
    const HEADER = getStorage("APP_OTHER.HEADER");
    const LINKS = HEADER.MENU.LINKS;
    const openAlert = () => {
        updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_bet_error`)}`})
    }
    return (
        <>
            <CustomPaper>
                <StyledBottomNavigation>
                    {
                        LINKS.map((it: string) => routing.find(item => item.key === it)).map((item: RoutingList, index: number) => (
                            <React.Fragment key={`footer-links-${index}-${item.lang_key}`}>
                                <StyledBottomNavigationAction selected={value === item.to}
                                                              onClick={() => {
                                                                  if (!item.isAuth) {
                                                                      navigate(item.to)
                                                                  } else {
                                                                      if (user) {
                                                                          navigate(item.to)
                                                                      } else {
                                                                          openAlert();
                                                                      }
                                                                  }
                                                              }}>

                                    {item.iconJsx}
                                    <Label>
                                        {t(`common.navbar.nav_${item.lang_key}`)}
                                    </Label>
                                </StyledBottomNavigationAction>
                            </React.Fragment>


                        ))
                    }
                </StyledBottomNavigation>
            </CustomPaper>
        </>
    );
};

export default Footer;

const CustomPaper = styled.div`
  background-color: #121212;
  color: #fff;
  border-radius: 4px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 51%);
`

const StyledBottomNavigation = styled.div`
  display: flex;
  justify-content: center;
  height: 56px;
  background-color: ${props => props.theme.palette.primary.dark};
  max-height: 72px;
`

const StyledBottomNavigationAction = styled.button<{ selected?: boolean }>`
  color: rgba(255, 255, 255, 0.7);
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  background: ${({theme, selected}) => selected ? "#829FF5" : theme.palette.primary.dark};
  min-width: 56px;
  max-width: 168px;
  cursor: pointer;
  flex: 1;

  :hover {
    background: #829FF5;
  }
`

const Label = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  white-space: nowrap;
`
