import React from 'react';
import styled from "styled-components";
import {ReactComponent as Arrow} from "assets/images/promotions_arrow_icon.svg"

type PromotionCardProps = {
    title?: string,
    content?: string,
    imageUrl?: string | null,
    link?: string,
    withArrow?: boolean,
    isDesktop?: boolean,
    onClick?: () => void,
}

const PromotionCard = (props: PromotionCardProps): JSX.Element => {
    const {title, imageUrl, link, content = '', withArrow = true, onClick} = props;

    return (
        <CardWrapper withClick={!!onClick} id={`promotion-card`}>
            <ContainerCard onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}>
                {imageUrl &&
                    <Image src={imageUrl} alt={`promotion-${title}`}/>}
                {title && <ContainerText>
                    <Title>
                        {`${props.title}`}
                    </Title>
                    {withArrow &&
                        <div>
                            <Arrow/>
                        </div>}

                </ContainerText>
                }
                <TextContent dangerouslySetInnerHTML={{__html: content}}/>
            </ContainerCard>
        </CardWrapper>
    );
};

export default PromotionCard;

const ContainerCard = styled.div`
  padding: 9px 11px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
`


const CardWrapper = styled.div<{ withClick?: boolean }>`
  width: 278px;
  height: auto;
  border-radius: 5px;
  color: white;
  background-color: ${props => props.theme.palette.primary.main};
  ${({withClick}) => {
    if (withClick) {
      return `
            cursor: pointer;
            &:hover {
                background: #1e2741;
            }           
          `
    }
  }}

`

const ContainerText = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`


const Image = styled.img`
  border-radius: 5px;
  width: 256px;
  max-width: 256px;
  height: 192px;
  max-height: 192px;
  object-fit: cover;
  object-position: center;
`

const Title = styled("div")`
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TextContent = styled("div")`
  max-width: 100%;
  max-height: 30px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;

  & > * {
    margin: 0;
    padding: 0;
    font-family: 'Roboto Condensed', 'serif';
    line-height: 30px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

`
