import React from 'react';
import styled from 'styled-components';
import {IHighRoller} from "../model/high-roller.types";
import BackgroundImage from "assets/images/desktop_background-image.svg";
import {HighRollerItem} from "./high-roller-item";
import {ReactComponent as CrossIcon} from "assets/images/cross_top_icon.svg";
import {useTranslation} from 'react-i18next';
import {devices} from "../../../constants/breakpoints";

type TopListProps = {
    highRollers: IHighRoller[],
    onClose: () => void,

}

export const TopList = (props: TopListProps) => {
    const {highRollers, onClose} = props;
    const {t} = useTranslation();
    return (
        <WrapperStyled>
            <Header>
                <Title>
                    {t(`pages.hall_of_fame.top`)} 10
                </Title>
                <CrossIconButton onClick={onClose}/>
            </Header>

            {
                highRollers.slice(0, 10).map((highRoller, index) => (
                    <React.Fragment key={`${highRoller.game.Id}-${highRoller.login}-${index}`}>
                        <StyledHighRollerItem highRoller={highRoller}/>
                    </React.Fragment>

                ))
            }

        </WrapperStyled>
    );
};


const StyledHighRollerItem = styled(HighRollerItem)`
  padding-bottom: 10px;
  border-bottom: 1px solid #263154;
  text-align: right;
`

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`

const WrapperStyled = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 1;
  width: 280px;
  padding: 8px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 8px #F3EBA8;
  border-radius: 5px;
  background-size: cover;
  background: #060912 url(${BackgroundImage});

  ${devices.mobileL} {
    right: 50%;
    transform: translateX(50%);
  }
`

const CrossIconButton = styled(CrossIcon)`
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  margin-top: -15px;
  position: absolute;
  right: 0px;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

`


const Title = styled.div`
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: #F3EBA8;
`
