import styled from "styled-components";


export const Container = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 821px;
  box-sizing: border-box;
`


export const DesktopMainContainer = styled(Container)`
  max-width: 1658px;
  @media (max-width: 1321px) {
    padding: 0 10px;
  }
 
`

export const MobileContainer = styled(Container)`
  @media (max-width: 1321px) {
    padding: 0 10px;
    
  }
`




