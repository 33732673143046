import React, {useState} from 'react';
import {format} from 'date-fns';
import TextField from "./TextField";
import ArrowIcon from "assets/images/desktop_menu_down_arrow_icon.svg";

import styled from "styled-components";
import {ClassNames, DayPicker} from 'react-day-picker';
import styling from 'react-day-picker/dist/style.module.css';
import Modal from "./Modal";
import formatDate from "../../helpers/formatDate";
import { useTranslation } from 'react-i18next';


type DatePickerMomentProps = {
    label?: string,

    value?: Date,
    minDate?: Date,
    maxDate?: Date,
    onChange: React.Dispatch<React.SetStateAction<Date | undefined>>
}


const CustomDatePicker = ({label, value, minDate, maxDate, onChange}: DatePickerMomentProps): JSX.Element => {
    const [openPicker, setOpenPicker] = useState(false);
    const {i18n} = useTranslation();
    let classNames: ClassNames = {
        ...styling,
        day_selected: 'day-selected',
        day: 'day',
        head: 'head',
        caption_dropdowns: 'caption-dropdowns',
        dropdown_month: 'dropdown-month',
        caption_label: 'caption-label',
        dropdown: 'dropdown',
        day_today: 'day-today'

    };

    let footer = <FooterContainer>
        <div onClick={() => {
            setOpenPicker(false)
        }}>
            OK
        </div>
    </FooterContainer>

    return (

        <>
            <div id={'custom-date-picker'} style={{width: "100%", cursor: "pointer"}} onClick={() => {
                setOpenPicker(!openPicker)
            }}>
                <TextField leftContent={`${label}:`}
                           inputExtra={`text-align: right;`}
                           readOnly
                           rightIcon={{
                               url: ArrowIcon,
                               extra: `width: 16px; height: 16px;`,
                               onClick: () => {
                                   setOpenPicker(!openPicker)
                               }
                           }} extra={`height: 40px; font-size: 16px`}
                           value={`${formatDate({date: value || new Date(), dateFormat: 'PPP', lang: i18n.language})}`}
                           onChange={(e) => {
                           }}
                />
            </div>
            <Modal open={openPicker} onClose={() => {
                setOpenPicker(false)
            }}>
                <DatePickerContainer>
                    <StyledDatePicker dropImage={ArrowIcon}
                                      fromYear={2021}
                                      toYear={+format(new Date(), "yyyy")}
                                      mode={"single"}
                                      classNames={classNames}
                                      selected={value}
                                      footer={footer}
                                      onSelect={onChange} captionLayout={"dropdown"}/>
                </DatePickerContainer>

            </Modal>


        </>
    );
};

export default CustomDatePicker;


const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  color: #FFB401;
  cursor: pointer;
  padding: 10px;
`

const DatePickerContainer = styled.div`
  background: ${props => props.theme.palette.primary.main};
  border-radius: 5px;
  margin-top: 20px;
  direction: ltr;
  padding: 10px 10px 30px 10px;
  box-sizing: border-box;
`

const StyledDatePicker = styled(DayPicker)<{ dropImage: string }>`
  .head {
    color: #FFB401;
  }

  .day {
    background: #121931;
    width: 35px;
    height: 35px;
    border-radius: 20px;

    :hover:not([disabled]) {
      background-color: #0c1121
    }

    :focus:not([disabled]) {
      background: #FFB401;
      border: none
    }
  }

  .day-today {
    border: 1px solid #FFB401;

    :hover {
      background-color: #e7a301
    }
  }

  .dropdown {
    width: 120px;
    padding: 5px 35px 5px 5px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    border: none;
    height: 34px;
    appearance: none;
    color: white;
    background: url(${props => props.dropImage}) 96% /10% no-repeat #EEE;
    background-color: transparent;

    ::-ms-expand {
      display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
    }

    > option {
      background: #121931;
      cursor: pointer;
      margin: 100px;
    }
  }

  .caption-dropdowns {
    display: flex;
  }

  .caption-label {
    display: none;
  }

  .day-selected {
    background: #FFB401
  }

`
