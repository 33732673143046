import React from 'react';
import getProviderImage from "helpers/providerHelper";
import {Swiper, SwiperSlide} from "swiper/react";
import {Mousewheel, Navigation} from "swiper";
import styled from 'styled-components';

type ProviderCardsProps = {
    providers: string[],
    isTvProvider?: boolean,
    isDesktop?: boolean,
    widthCard?: number,
    heightCard?: number,
    selectProvider: string,
    setSelectProvider: (cal: string) => void
}


const ProviderCards = (props: ProviderCardsProps): JSX.Element => {
    if (props.isDesktop && props.isTvProvider) {
        return <SwiperContainer>
            <Swiper slidesPerView={3}
                    spaceBetween={2}
                    mousewheel={true}
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Mousewheel, Navigation]}
            >

                {
                    props.providers.map((item, index) => {
                        let image = getProviderImage({
                            provider: item.toLocaleLowerCase(),
                            isTvProvider: props.isTvProvider
                        });

                        if (!image) {
                            return null;
                        } else
                            return (
                                <React.Fragment key={`${index}-provider`}>
                                    <SwiperSlide>
                                        <ProviderCard widthCard={props.widthCard} heightCard={props.heightCard} isTvProvider={props.isTvProvider} isDesktop={props.isDesktop}
                                                      isSelect={item === props.selectProvider} onClick={() => {
                                            if (props.selectProvider === item) {
                                                props.setSelectProvider("");
                                            } else {
                                                props.setSelectProvider(item);
                                            }

                                        }}>
                                            <Image isDesktop={props.isDesktop} src={image}/>
                                        </ProviderCard>
                                    </SwiperSlide>
                                </React.Fragment>
                            )
                    })
                }

            </Swiper>
        </SwiperContainer>
    } else {
        return (
            <SliderWrapperCard isDesktop={props.isDesktop}>
                <SliderBlock isTvProvider={props.isTvProvider && !props.isDesktop}>
                    <Block isTvProvider={props.isTvProvider && !props.isDesktop}>
                        {
                            props.providers.map((item, index) => {
                                let image = getProviderImage({
                                    provider: item.toLocaleLowerCase(),
                                    isTvProvider: props.isTvProvider
                                });

                                if (!image) {
                                    return null;
                                } else
                                    return (
                                        <React.Fragment key={`${index}-provider`}>
                                            <ProviderCard isTvProvider={props.isTvProvider} isDesktop={props.isDesktop}
                                                          isSelect={item === props.selectProvider} onClick={() => {
                                                if (props.selectProvider === item) {
                                                    props.setSelectProvider("");
                                                } else {
                                                    props.setSelectProvider(item);
                                                }

                                            }}>
                                                <Image isDesktop={props.isDesktop} src={image}/>
                                            </ProviderCard>
                                        </React.Fragment>
                                    )
                            })
                        }
                    </Block>
                </SliderBlock>
            </SliderWrapperCard>

        );
    }


};

export default ProviderCards;


const SwiperContainer = styled(`div`)((props) => ({

    boxSizing: "border-box",
    ".swiper-button-prev": {
        color: "#FFB401",
    },

    ".swiper-button-next": {
        color: "#FFB401"
    }
}));

const SliderWrapperCard = styled(`div`)<{ isDesktop?: boolean }>((props) => ({
    overflowX: "scroll",
    background: props.theme.palette.primary.main,
    borderRadius: !props.isDesktop ? 0 : 5,
    "@media (max-width: 768px)": {"&::-webkit-scrollbar": {display: "none"}},
    boxSizing: "border-box",
}));

const SliderBlock = styled(`div`)<{ isTvProvider?: boolean }>((props) => ({
    flexDirection: "row",
    display: "flex",
    boxSizing: "border-box",
    width: "max-content",


}));

export const Block = styled(`div`)<{ isTvProvider?: boolean }>((props) => ({
    width: 'max-content',
    display: 'flex',
    flexFlow: 'row wrap',
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
        justifyContent: props.isTvProvider ? "space-between" : "inherit",
        width: props.isTvProvider ? "100%" : 'max-content',
    },

}));

export const ProviderCard = styled(`div`)<{ isSelect: boolean, isTvProvider?: boolean, isDesktop?: boolean, widthCard?: number, heightCard?: number}>((props) => ({
    width: !props.isDesktop ? 138 : props.widthCard ?? 349,
    height: !props.isDesktop ? 50 : props.heightCard ?? 50,
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: !props.isDesktop ? 0 : 5,
    padding: "13px 29px",
    background: `#263154 ${props.isSelect ? !props.isDesktop ? "linear-gradient(180deg, #121931 0%, #39466D 0.01%, #263154 100%)" : "linear-gradient(180deg, #121931 0.01%, #FFB401 0.01%, #a89c34 100%)" : ""}`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "border-box",
    backgroundSize: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
        background: `#202945 ${props.isSelect ? !props.isDesktop ? "linear-gradient(180deg, #121931 0%, #39466D 0.01%, #263154 100%)" : "linear-gradient(180deg, #121931 0.01%, #FFB401 0.01%, #a89c34 100%)" : ""}`,

    },
    "& > img": {
        filter: props.isSelect ? "inherit" : "grayscale(100%)"
    },

    "@media (min-width: 768px)": {
        width: props.isTvProvider && !props.isDesktop ? "16vw" : !props.isDesktop ? 107 : props.widthCard ?? 349,
    },


}));

export const Image = styled(`img`)<{ isDesktop?: boolean, widthCard?: number, heightCard?: number }>((props) => ({
    width: !props.isDesktop ? "100%" : props.widthCard ?? 349,
    height: !props.isDesktop ? "100%" : props.widthCard ?? 50,
    objectPosition: "center",
    objectFit: "contain",
}));
