import axios from "../index"

export const appAPI = {

    async getStaticPages() {
        let pld = (await axios.get(`/api/pages/client`)).data;
        return pld;
    },

    async getStaticPage(id: number) {
        let pld = (await axios.get(`/api/pages/client/${id}`)).data;
        return pld;
    },

    async getPromotions(lang: string) {
        let pld = (await axios.get(`/api/promotions`, {
            params: {
                lang
            },
        })).data;
        return pld;
    },

    async getBanners(lang: string) {
        let pld = (await axios.get(`/api/banners`, {
            params: {
                lang
            },
        })).data;
        return pld;
    },

    async sendFeedback(obj: { name: string, phone: string, messageContent: string }) {
        let pld = (await axios.post(`/api/support/send-feedback`, obj)).data;
        return pld;
    },

}
