import * as UserActionCreators from '../actions/userActions'
import * as GameActionCreators from '../actions/gameActions'
import * as TransactionActionCreators from '../actions/transcationActions'
import * as AppActionCreators from '../actions/appActions'

export default {
    ...UserActionCreators,
    ...GameActionCreators,
    ...TransactionActionCreators,
    ...AppActionCreators
}
