import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {MobileContainer} from 'ui/containers';
import BackButton from 'ui/BackButton';
import HistoryItem from 'ui/HistoryItem';
import {ReactComponent as BetHistoryIcon} from "assets/images/profile_logo_bet-history_icon.svg"
import FilterDateCustom from 'ui/FilterDateCustom';
import {useTypedSelector} from 'hooks/useTypedSelector';
import {useActions} from 'hooks/useActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';


const BetHistory = (): JSX.Element => {
    const [page, setPage] = useState(1);
    const {t,i18n} = useTranslation();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [calendar, setCalendar] = useState({
        range_start: new Date().setDate(new Date().getDate() + 7),
        range_end: + new Date(),
    });

    const {bets, betsCount, isLoading, error} = useTypedSelector((state) => {
        return {
            error: state.transaction.error,
            isLoading: state.transaction.loading,
            bets: state.transaction.bets,
            betsCount: state.transaction.betsCount
        }
    })

    const {getBets} = useActions();


    useEffect(() => {
        setIsLoadingData(true);
        handleSearch().then(() => {
            setPage(1);
            setHasMoreItems(true);
            setIsLoadingData(false);
        })
    }, [calendar]);


    const handleSearch = async () => {
        await getBets({
            range_start: calendar.range_start,
            range_end: calendar.range_end,
        });

    }

    const loadMore = () => {
        if (bets.length >= betsCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getBets({
            page: page + 1,
            range_start: calendar.range_start,
            range_end: calendar.range_end,
            action: "add",
        })

    };


    return (
        <>
            <BackButton href={'/profile'} title={'Back to profile'}/>
            <MobileContainer>
                <Content>
                    <FlexContent style={{gap: 20}}>
                        <BetHistoryIcon/>
                        <Title style={i18n.language === "en"?{}: {fontSize: 18}}>{t(`pages.bet_history.title`)}</Title>
                    </FlexContent>
                    <div style={{width: "100%", padding: "20px 50px", boxSizing: "border-box"}}>
                        <FilterDateCustom
                            isLoading={isLoadingData}
                            onSave={(from: Date, to: Date) => {
                                setCalendar({range_start: +from, range_end: +to})
                            }}/>
                    </div>
                </Content>
                <InfiniteScroll
                    dataLength={bets.length}
                    next={loadMore}
                    hasMore={hasMoreItems}
                    loader={<></>}
                >
                    <ContainerHistory>
                        {
                            bets.map((item, index) => (
                                <React.Fragment key={`bet-history-${index}-${item.providerId}`}>
                                    <HistoryItem access={item.type === "win"} amount={`${item.amount.toFixed(2)}`}
                                                 status={item.type} game={item.gameId}
                                                 date={item.createdAt} provider={item.providerId}/>
                                </React.Fragment>
                            ))

                        }
                    </ContainerHistory>
                </InfiniteScroll>
            </MobileContainer>
        </>
    );
};

export default BetHistory;


const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
}))

const Content = styled(FlexContent)((props) => ({
    paddingTop: "50px",

}))

const Title = styled(`div`)((props) => ({
    textTransform: "uppercase",
    fontSize: 23,
    fontWeight: 600,
    boxSizing: "border-box",
    fontFamily: "Roboto Condensed"
}))


const ContainerHistory = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
    boxSizing: "border-box",
    paddingTop: 24,
}))


