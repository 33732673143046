import {devices, sizes} from 'constants/breakpoints';
import React from 'react';
import styled, {css} from 'styled-components';
import {IHallOfFame} from '../model/hall-of-fame.types';
import BackGroundStar from '../assets/background_star.png';
import Belt from '../assets/belt.png';
import Star from 'assets/images/star.png';
import {getStorage} from "../../../hooks/useStorage";
import openGame from "../../../helpers/gamesHelper/openGame";
import {useActions} from "../../../hooks/useActions";
import useMediaQuery from 'hooks/useMediaQuery';
import {updateAlert} from "../../../store/actions/appActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useTranslation} from 'react-i18next';

type FameItemProps = {
    item: IHallOfFame;
    index: number;
}

export const FameItem = (props: FameItemProps) => {
    const {item, index} = props;
    const {t} = useTranslation();
    const currency = getStorage("APP_OTHER.CURRENCY.KEY");
    const {setLoading, updateAlert} = useActions();
    const mobile = useMediaQuery(`(max-width: ${sizes.tablet}px)`);
    const user = useTypedSelector((state) => state.user.user);

    const handleClick = () => {
        if (!user) {
            updateAlert({
                isOpen: true,
                status: "error",
                message: `${t(`common.alert.text_error`)}`
            })
        } else {
            openGame({
                isDesktop: !mobile,
                providerId: item.game.SectionId,
                gameId: item.game.Id,
                setLoading: () => {
                    setLoading(true);
                }
            })
        }


    }

    return (
        <Container>
            <BackgroundImage src={BackGroundStar} alt={'background-left'} type='left'/>
            <BackgroundImage src={BackGroundStar} alt={'background-right'} type='right'/>
            <FlexContainer>
                <StarImage>
                    <Text>
                        {index}
                    </Text>
                </StarImage>
                <Content title={item.login.slice(0, 7) + '***'}>
                    {item.login.slice(0, 7) + '***'}
                </Content>
            </FlexContainer>
            <GameContainer onClick={handleClick} title={"Play " + item.game.Name}>
                <Content style={{textAlign: "right"}}>
                    {item.game.Name}
                </Content>
                <CircleImage src={item.game.Thumbnail} alt={`image-${item.game.Name}`}/>
            </GameContainer>
            <BeltContainer>
                <BeltImage>
                    <BeltText>
                        {item.value.toLocaleString()} {item.currency.toUpperCase()}
                    </BeltText>
                </BeltImage>
            </BeltContainer>

        </Container>
    );
};


const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  z-index: 0;
  width: 200px;
  height: 100%;
  padding-left: 16px;

  ${devices.tablet} {
    padding-left: 4px;
    width: 100px;
    gap: 4px;
  }
`

const BeltContainer = styled(FlexContainer)`

  ${devices.tablet} {
    width: 140px;
  }
`

const GameContainer = styled(FlexContainer)`
  cursor: pointer;
  width: 300px;
  justify-content: space-between;


  :hover {
    scale: 1.1;
    opacity: 0.8;
  }

  ${devices.tablet} {
    width: 100px;
  }
`

const CircleImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;


  ${devices.tablet} {
    width: 22px;
    height: 22px;
  }
`

const StarImage = styled.div`
  position: relative;
  background-image: url(${Star});
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;

  ${devices.tablet} {
    width: 32px;
    height: 32px;
  }
`

const Content = styled.div`
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  ${devices.tablet} {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

`

const Text = styled.div`
  position: absolute;
  z-index: 10000;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4D2608;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;

  ${devices.tablet} {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
`

const BeltText = styled.div`
  z-index: 10000;
  color: #4D2608;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin-left: 30px;
  ${devices.tablet} {
    font-weight: 700;
    line-height: 14px;
    margin-left: 0;
    font-size: 10px;
    top: 50%;
    width: 100px;
  }
  text-align: right;
`

const BeltImage = styled.div`
  background-image: url(${Belt});
  width: 100%;
  height: 100%;
  background-position: left;
  background-clip: border-box;
  overflow: hidden;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    scale: 1.2;
  }

`

const BackgroundImage = styled.img<{ type: 'left' | 'right' }>`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);

  ${({type}) => type === 'right' && css`
    left: inherit;
    right: 0;
    transform: translate(50%, -52%);
    width: 80%;

    ${devices.tablet} {
      display: none;
    }
  `}
`
const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 77px;
  display: flex;
  background: #263154;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;


  ${devices.tablet} {
    height: 32px;
  }
`
