import axios from "../index"
import {IGetGames, IGetTvGames} from "./gamesTypes";

export const gamesAPI = {
    async getProviders() {
        let pld = (await axios.get(`/api/json-rpc/game-providers`)).data;
        return pld;
    },

    async getTvProviders() {
        let pld = (await axios.get(`api/json-rpc/tv-game-providers`)).data;
        return pld;
    },

    async getCategories() {
        let pld = (await axios.get(`/api/game-category`)).data;
        return pld;
    },

    async getGames(obj?: IGetGames) {
        let pld = (await axios.get(`/api/json-rpc/game-list`, {
            params: {
                title: obj?.title,
                page: obj?.page,
                size: obj?.size,
                provider: obj?.provider,
                category: obj?.category

            },
        })).data;
        return pld;
    },
    async getGameList(obj?: IGetGames) {
        let pld = (await axios.get(`/api/json-rpc/game-list-clear`, {
            params: {
                title: obj?.title,
                page: obj?.page,
                size: obj?.size,
                provider: obj?.provider,
                category: obj?.category
            },
        })).data;
        return pld;
    },

    async getTvGames(obj?: IGetTvGames) {
        let pld = (await axios.get(`/api/json-rpc/tv-game-list`, {
            params: {
                title: obj?.title,
                page: obj?.page,
                size: obj?.size,
                provider: obj?.provider,
            },
        })).data;
        return pld;
    },

    async getProviderGames(obj?: IGetGames) {
        let pld = (await axios.get(`/api/json-rpc/${obj?.provider}/games`, {
            params: {
                title: obj?.title,
                page: obj?.page,
                size: obj?.size,
                category: obj?.category,
            },
        })).data;
        return pld;
    },
    async createSession(GameId: string) {
        let pld = (await axios.post(`/api/json-rpc/create-session`, {GameId})).data;
        return pld;
    },

    async getSportToken() {
        let pld = (await axios.get(`/api/json-rpc/create-session/sport`)).data;
        return pld;
    },

    async toggleFavoriteGameession(GameId: string) {
        let pld = (await axios.put(`/api/user/favorite-games`, {Id: GameId})).data;
        return pld;
    },
}
