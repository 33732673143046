import React, {useEffect, useState} from 'react';
import DrawerContent from "./DrawerContent";
import DrawerFooter from "./DrawerFooter";
import Drawer from 'ui/material-kit/Drawer';
import styled from 'styled-components';
import Toolbar from 'ui/material-kit/Toolbar';
import {useTranslation} from "react-i18next";

const drawerWidth = "75vw";

type CustomDrawerProps = {
    open: boolean,
    setClosed: () => void,
}

const CustomDrawer = (props: CustomDrawerProps): JSX.Element => {
    const {i18n, t} = useTranslation();

    return (
        <Drawer
            width={drawerWidth}
            backgroundColor={"#121931"}
            zIndex={100}
            isRight={getComputedStyle(document.querySelector("body")!).direction === "rtl"}
            open={props.open}
            onClose={props.setClosed}
        >
            <Toolbar/>
            <ContainerDrawer>
                <DrawerContent onCloseDrawer={ () => props.setClosed()}/>
                <DrawerFooter/>
            </ContainerDrawer>
        </Drawer>
    );
};

export default CustomDrawer;




const ContainerDrawer = styled("div")((props) => ({
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
}))

