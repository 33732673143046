import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDocumentTitle = (title: string) => {
    const { pathname } = useLocation();
    useEffect(() => {
        try {
            document.title = `${title}`;
        } catch (error: any) {
            document.title = `Программа молодёжного и студенческого туризма`;
        }
        return () => {
            document.title = `Программа молодёжного и студенческого туризма`;
        };
    }, [pathname]);
};

export default useDocumentTitle;

