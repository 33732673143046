import React from 'react';
import {useNavigate} from "react-router-dom";
import LanguageController from "components/language";
import styled from 'styled-components';

type ListMenuProps = {
    icon?: React.ReactElement,
    title: string,
    href?: string
    isLanguage?: boolean,
    disabled?: boolean
}

const ListMenu = (props: ListMenuProps) => {
    const navigate = useNavigate();

    return (
        <Container id={`list-menu`} disabled={props.disabled} style={{cursor: !props.isLanguage ? "pointer" : "inherit"}} onClick={() => {
            if (!props.isLanguage && props.href) {
                navigate(`${props.href}`)
            }
        }}>
            <TitleContainer>
                {!props.icon ? null : props.icon}
                <Text>
                    {`${props.title}`}
                </Text>
            </TitleContainer>
            {props.isLanguage && <LanguageContainer>
                <LanguageController/>
            </LanguageContainer>}
        </Container>
    );
};

export default ListMenu;


const Container = styled(`div`)<{disabled?: boolean}>((props) => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
    alignItems: "center",
    textOverflow: "ellipsis",
    background: props.theme.palette.primary.main,
    borderBottom: `1px solid ${props.theme.palette.primary.dark}`,
    height: 41,
    padding: "10px 8px",
    cursor: "pointer",
    color: "white",
    outline: "none",
    borderRadius: "inherit",
    "&:hover": {
        background: !props.disabled ? "#212a48": props.theme.palette.primary.main,
    },
}))

const Text = styled("div")((props) => ({
    fontSize: 13,
    overflow: "hidden",
    maxWidth: 180,
    textOverflow: "ellipsis",
    fontWeight: 600,
    textTransform: "uppercase",
}))


const TitleContainer = styled("div")((props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 11
}))

const LanguageContainer = styled("div")((props) => ({}))
