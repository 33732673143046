import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled, {keyframes} from 'styled-components';
import {useActions} from "hooks/useActions";
import {useTranslation} from "react-i18next";
import {TawkService} from "services/tawkService";
import {getStorage} from "hooks/useStorage";
import HelpModal from "../../modal/HelpModal";
import {useTypedSelector} from "hooks/useTypedSelector";
import routing from "constants/routing";
import {RoutingList} from 'constants/types';
import HallOfFameBelt from "assets/images/belt-hall-of-fame.png";

type NavbarProps = {
    isAuthUser?: boolean
}

const Navbar = (props: NavbarProps) => {
    const {pathname} = useLocation();
    const {updateAlert, setLoading} = useActions();
    const [value, setValue] = useState('/');
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const HEADER = getStorage("APP_OTHER.HEADER");
    const LINKS = HEADER.MENU.LINKS
    const isVisibleHelp = HEADER.MENU.HELP.VISIBLE;
    const isTawk = HEADER.MENU.HELP.TAWK;
    const isFeedback = HEADER.MENU.HELP.FEED_BACK;
    const isFastGames = HEADER.MENU.FAST_GAMES.VISIBLE;

    const openAlert = () => {
        updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_bet_error`)}`})
    }
    const {open} = useTypedSelector((state => {
        return {
            open: state.app.modal
        }
    }))
    const {updateModal} = useActions();

    useEffect(() => {
        setValue(pathname);
    }, [pathname]);

    const getCurrentValue = () => {
        if (value.includes("/games")) {
            return "/games"
        } else {
            return value
        }
    }
    return (
        <Wrapper>
            {
                LINKS.map((it: string) => routing.find(item => item.key === it)).map((item: RoutingList, index: number) => (
                    <React.Fragment key={`navbar-element-${index}-${item.lang_key}`}>
                        <NavbarItem isSelected={getCurrentValue() === item.to} onClick={() => {
                            if (!item.isAuth) {
                                navigate(item.to)
                            } else {
                                if (props.isAuthUser) {
                                    navigate(item.to)
                                } else {
                                    openAlert();
                                }
                            }
                        }}>
                            {t(`common.navbar.nav_${item.lang_key}`)}
                        </NavbarItem>

                    </React.Fragment>
                ))
            }
            {isVisibleHelp && <React.Fragment key={`navbar-element-help-button`}>
                <NavbarItem isSelected={false} onClick={() => {
                    {
                        isTawk && TawkService.toggleWidget()
                    }
                    {
                        isFeedback && updateModal(true)
                    }
                }}>
                    {t(`common.navbar.nav_help`)}
                </NavbarItem>
            </React.Fragment>}
            {isFastGames &&
                <React.Fragment key={`navbar-element-crash-games-button`}>
                    <NavbarItem
                        isSelected={false}
                        isFire={true}
                        onClick={() => {
                            navigate("/games?category=Jeux+turbo")
                        }}>
                        {t(`common.navbar.fast_game`)}
                    </NavbarItem>
                </React.Fragment>
            }

            {isFeedback && <HelpModal isDesktop={true} onClose={() => updateModal(false)} isOpen={open}/>}
        </Wrapper>
    );
};

export default Navbar;




const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

const fadeIn = keyframes`
  0% {
    background-position: 80% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0%;
  }
`

const NavbarItem = styled.div<{ isSelected?: boolean, isFire?: boolean }>`
  display: flex;
  cursor: pointer;
  line-height: 33px;
  background: ${props => !props.isSelected ? props.isFire ? 'linear-gradient(149deg, #fcd82f 0%, #ffcc00 42%, #ffd700 72%, #FFB401 100%)' : `transparent` : '#FFB401'};
  display: flex;
  gap: 10px;
  padding: 8px 15px;
  font-family: Roboto Condensed;
  font-size: 24px;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  animation: ${fadeIn} 4s infinite linear;
  background-size: 400%;
  @media (max-width: 1540px) {
    font-size: 20px;
    padding: 8px 10px;
  }

  &:hover {
    background: ${props => !props.isSelected ? props.isFire ? 'linear-gradient(149deg, #f5ba30 0%, #f5ba30 42%, #f5ba30 72%, #f5ba30 100%)' : '#1a2246' : '#ffd700'};
  }
`
