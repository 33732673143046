import React, {useEffect, useRef} from 'react';
import Portal from "services/PortalService";
import useOutsideClick from "hooks/useOutsideClick";
import styled, {css} from "styled-components";
import {CSSTransition} from 'react-transition-group';
import {useLocation} from "react-router-dom";

type DrawerProps = {
    onClose?: () => void,
    open: boolean,
    isRight?: boolean,
    children: React.ReactNode,
    extra?: string,
    width?: string,
    zIndex?: number,
    backgroundColor?: string,
}

const Drawer = (props: DrawerProps) => {

    const {
        open,
        onClose = () => {
        },
        children,
        backgroundColor = `#FFFFFF`,
        zIndex = 10000,
        isRight = false,
        width = `300px`,
        extra = ``
    } = props;

    const location = useLocation();
    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        onClose();
    })

    useEffect(() => {
        onClose();

    }, [location.pathname])

    useEffect(() => {
        let body = document.getElementsByTagName("body");
        let root = document.getElementById("root");
        if (open) {
            root!.ariaHidden = "false";
            body.item(0)!.style.overflow = "hidden";
        } else {
            root!.ariaHidden = "false";
            body.item(0)!.style.overflow = "auto";
        }

    }, [open])


    return (
        <>
            <CSSTransition
                in={open}
                timeout={200}
                classNames={isRight ? "drawer-right" : "drawer"}
                unmountOnExit
            >
                <Portal id="drawer-root">
                    <ModalWrapper zIndex={zIndex} extra={extra}>
                        <Background open={open} onClick={onClose}/>
                        <Container backgroundColor={backgroundColor} zIndex={zIndex} width={width}>
                            {children}
                        </Container>
                    </ModalWrapper>
                </Portal>
            </CSSTransition>
        </>
    );
};

export default Drawer;

const Container = styled.div<{ width: string, backgroundColor: string, zIndex: number }>`
  box-sizing: border-box;
  background: ${({backgroundColor}) => backgroundColor};
  padding-bottom: 74px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: ${({zIndex}) => zIndex};
  position: fixed;
  width: ${({width}) => width};
`


const ModalWrapper = styled.div<{ extra: string, zIndex: number }>`
  flex-shrink: 0;
  z-index: ${({zIndex}) => zIndex};
  position: fixed;
  inset: 0;
  top: 0;
  outline: 0;


  ::before, ::after {
    box-sizing: inherit;
  }
;
  ${({extra}) => extra}



`

const Background = styled.div<{ open: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: -1;

  opacity: ${({open}) => !open ? 0 : 1};
  transition: opacity ${({open}) => open ? 225 : 195}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`




