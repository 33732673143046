import React, {useEffect, useState} from 'react';
import JackPot from "ui/Jackpot";
import GameSwitcher from "components/gameSwitcher";
import DiscoverCards from 'components/cards/discoverCards';
import {useTypedSelector} from "hooks/useTypedSelector";
import ProviderCards from 'components/cards/providerCards';
import CustomSearch from 'ui/CustomSearch';
import GamesArea from "components/games";
import {useActions} from "hooks/useActions";
import {useDebounce} from 'hooks/useDebounce';
import {Container} from "ui/containers";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import GameCard from "../ui/GameCard";
import openGame from "../helpers/gamesHelper/openGame";
import useDimensions from "react-cool-dimensions";
import InfiniteScroll from 'react-infinite-scroll-component';


const Games = (): JSX.Element => {
    const {t, i18n} = useTranslation();
    const {getGames, getGameList, updateAlert, toggleFavoriteGames, setLoading} = useActions();
    const [initialize, setInitialize] = useState(true);
    const [selectCategoryCard, setSelectCategoryCard] = useState<string>("");
    const [selectProviderCard, setSelectProviderCard] = useState<string>("");
    const [titleGame, setTitleGame] = useState("");
    const debouncedSearchTerm = useDebounce(titleGame, 500);
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [page, setPage] = useState(1);

    const {
        providers,
        categories,
        allCategories,
        isLoading,
        games,
        gameList,
        gameListCount,
        user
    } = useTypedSelector((state) => {
        return {
            allCategories: state.game.categories,
            categories: state.game.categories.filter(item => item.content.some(item => item.lang === i18n.language)).map((item) => {
                let titleCategories = item.content.find(item => item.lang === i18n.language)?.title || "";
                return ({
                    id: item.id,
                    name: titleCategories,
                    img_url: item.img_url,
                    createdAt: item.createdAt
                })
            }),
            gameList: state.game.gameList.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }

            }),
            gameListCount: state.game.gameListCount,
            providers: state.game.providers,
            user: state.user.user,
            isLoading: state.game.loading,
            games: state.game.games.toArray().map(item => item[1]),
        }
    })


    const getEnCorrectCategory = (): string => {
        let categoriesContent = allCategories.find(item => item.content.some(con => con.title === selectCategoryCard));
        if (categoriesContent) {
            return categoriesContent.content.find(con => con.lang === "en")?.title!;
        } else {
            return ""
        }
    }

    const handleSearch = async () => {
        await getGames({
            category: selectCategoryCard === "" ? undefined : selectCategoryCard,
            title: debouncedSearchTerm,
            provider: selectProviderCard === "" ? undefined : selectProviderCard,
        });
    }

    const isSelect = () => (selectCategoryCard !== "" && getEnCorrectCategory() !== "all") || debouncedSearchTerm !== "" || selectProviderCard !== "";


    useEffect(() => {
        if (initialize) {
            let params = {
                provider: searchParams.get("provider"),
                title: searchParams.get("title"),
                category: searchParams.get("category"),
            }

            let categoriesContent = allCategories.find(item => item.content.some(con => con.title === params.category));
            setSelectCategoryCard((params.category === null || !categoriesContent) ? "" : categoriesContent.content.find(con => con.lang === i18n.language)?.title || "");
            setSelectProviderCard(params.provider === null ? "" : params.provider);
            setTitleGame(params.title === null ? "" : params.title);
            setInitialize(false);
        }
    }, [])


    useEffect(() => {
        if (!initialize) {
            if (!isSelect()) {
                handleSearch().then(() => {
                });
            } else {
                setPage(1);
                setHasMoreItems(true);
                getGameList({
                    category: selectCategoryCard === "" ? undefined : selectCategoryCard,
                    title: debouncedSearchTerm,
                    size: 27,
                    provider: selectProviderCard === "" ? undefined : selectProviderCard,
                    action: "update"
                });
            }

        }

    }, [selectProviderCard, selectCategoryCard, debouncedSearchTerm, initialize])

    const {observe, height, width} = useDimensions({});


    const loadMore = () => {
        if (gameList.length >= gameListCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getGameList({
            title: debouncedSearchTerm,
            size: 27,
            provider: selectProviderCard === "" ? undefined : selectProviderCard,
            category: selectCategoryCard === "" ? undefined : selectCategoryCard,
            action: "add",
            page: newPage
        })

    };

    return (
        <>
            <JackpotContainer>
                <JackPot/>
            </JackpotContainer>
            <GameSwitcher/>
            <Container style={{height: "100%"}}>
                <ProviderCards selectProvider={selectProviderCard}
                               setSelectProvider={(val: string) => {
                                   setSelectProviderCard(val);
                                   setSearchParams({...Object.fromEntries(searchParams), provider: val});
                               }} providers={providers}/>
                <div style={{margin: "10px 0 0 5px", boxSizing: "border-box"}}>
                    <DiscoverCards
                        categories={categories}
                        selectCard={selectCategoryCard}
                        setSelectCard={(val: string) => {
                            setSelectCategoryCard(val);
                            setSearchParams({...Object.fromEntries(searchParams), category: val});
                        }}/>
                </div>
                <div style={{margin: "10px 5px"}}>
                    <CustomSearch value={titleGame} OnCloseClick={() => {
                        setSearchParams({...Object.fromEntries(searchParams), title: ""});
                        setTitleGame("");
                    }} onChange={(e) => {
                        setSearchParams({...Object.fromEntries(searchParams), title: e.target.value});
                        setTitleGame(e.target.value)
                    }} placeholder={'Search game'}/>
                </div>
                {
                    !isSelect() ? <GamesArea
                            provider={selectProviderCard}
                            category={selectCategoryCard}
                            title={debouncedSearchTerm}
                            games={games.filter(item => item.Games.length !== 0)}
                            isLoading={isLoading}/> :
                        <ContainerGameArea ref={observe}>
                            <InfiniteScroll
                                dataLength={gameList.length}
                                hasMore={hasMoreItems}
                                next={loadMore}
                                loader={<></>}
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    boxSizing: "border-box",
                                    flexFlow: "wrap",
                                    rowGap: 10,
                                    columnGap: 8
                                }}>


                                    {gameList.map((item, index) => (
                                        <React.Fragment key={`${index}-${item.id}-games`}>
                                            <GameCard TvGameWidth={Math.trunc((width - 16) / 3) + "px"}
                                                      TvGameHeight={Math.trunc((width - 16) / 3) + "px"}
                                                      favoriteClick={(e) => {
                                                          if (!user) {
                                                              updateAlert({
                                                                  isOpen: true,
                                                                  status: "error",
                                                                  message: `${t(`common.alert.text_error`)}`
                                                              })
                                                          } else {
                                                              e.stopPropagation();
                                                              toggleFavoriteGames(item.id)
                                                          }
                                                      }} isFavorite={item.isFavorite} url={item.url} onClick={() => {
                                                if (!user) {
                                                    updateAlert({
                                                        isOpen: true,
                                                        status: "error",
                                                        message: `${t(`common.alert.text_error`)}`
                                                    })
                                                } else {
                                                    openGame({
                                                        providerId: item.providerId,
                                                        gameId: item.id,
                                                        setLoading: (bool: boolean) => {
                                                            setLoading(bool);
                                                        }
                                                    })
                                                }
                                            }}/>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </ContainerGameArea>
                }

            </Container>
        </>
    );
};

export default Games;

const JackpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
`

const ContainerGameArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  padding: 16px;
`


