import React, {useState} from 'react';
import {ReactComponent as ChangePasswordIcon} from "assets/images/profile_logo_change-password_icon.svg"
import {useInput} from "hooks/useInput";
import axios from 'api';
import {useActions} from "hooks/useActions";
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import TextField from "ui/material-kit/TextField";



const SettingsDesktop = (): JSX.Element => {
    let oldPassword = useInput("");
    let newPassword = useInput("");
    let repeatPassword = useInput("");
    const {t} = useTranslation();
    const {updateAlert} = useActions();
    const [errorState, setErrorState] = useState({
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
    });

    const errorFields = {
        oldPassword: t(`pages.settings.old_password_error`),
        newPassword: t(`pages.settings.new_password_error`),
        newRepeatPassword: t(`pages.settings.new_repeat_password_error`),
        repeatPassword: t(`pages.settings.repeat_password_error`)
    }

    const changePassword = async () => {
        let errorInfo = {
            oldPassword: "",
            newPassword: "",
            repeatPassword: ""
        };

        if (oldPassword.value === "") {
            errorInfo.oldPassword = errorFields.oldPassword;
        }
        if (newPassword.value === "") {
            errorInfo.newPassword = errorFields.newPassword;
        }
        if (newPassword.value !== repeatPassword.value) {
            errorInfo.repeatPassword = errorFields.repeatPassword;
        }
        if (repeatPassword.value === "") {
            errorInfo.repeatPassword = errorFields.newRepeatPassword;
        }

        if (!Object.values(errorInfo).some(x => (x !== ""))) {
            await axios.put("api/me/password", {
                password: oldPassword.value,
                newPassword: newPassword.value,
                repeatPassword: repeatPassword.value
            })
                .then((x) => x.data)
                .then(pld => {
                    if (pld.error) {
                        errorInfo.oldPassword = `${t(`pages.settings.invalid_password`)}`;
                        setErrorState(errorInfo);
                        updateAlert({isOpen: true, status: "error", message: `${t(`pages.settings.invalid_password`)}`});
                    } else {
                        updateAlert({isOpen: true, status: "warning", message: "Operation successful"});
                    }
                })
                .catch((pld) => {
                    errorInfo.oldPassword = `${t(`pages.settings.invalid_password`)}`;
                    setErrorState(errorInfo);
                    updateAlert({isOpen: true, status: "error", message: `${t(`pages.settings.invalid_password`)}`});
                })
        } else {
            setErrorState(errorInfo)
        }
    }
    return (
            <MainContainer>
                <div style={{width: 339}}>
                    <NavigationLeftBar heightButton={"65px"}/>
                </div>
                <FlexContent style={{gap: 28}}>

                    <ChangePasswordIcon/>
                    <FlexContent style={{gap: 10, width: 338}}>
                        <TextField type={"password"} extra={`height: 37px`} onChange={(e) => {
                            setErrorState({...errorState, oldPassword: ""})
                            oldPassword.onChange(e)
                        }} value={oldPassword.value} placeholder={t(`pages.settings.old_password_field`)}/>
                        <TextField type={"password"} extra={`height: 37px`} onChange={(e) => {
                            setErrorState({...errorState, newPassword: ""})
                            newPassword.onChange(e)
                        }} value={newPassword.value} placeholder={t(`pages.settings.new_password_field`)}/>
                        <TextField type={"password"} extra={`height: 37px`} onChange={(e) => {
                            setErrorState({...errorState, repeatPassword: ""})
                            repeatPassword.onChange(e)
                        }} value={repeatPassword.value} placeholder={t(`pages.settings.repeat_password_field`)}/>
                        <div style={{
                            padding: "12px 20px 20px 20px", width: "100%", display: "flex",
                            justifyContent: "center", flexDirection: "column", alignItems: "center"
                        }}>

                            <StyledButton  onClick={async () => {
                                await changePassword();
                            }}  disabled={Object.values(errorState).some(x => (x !== ""))}>
                                {t(`pages.settings.save_button`)}
                            </StyledButton>
                            {Object.values(errorState).some(x => (x !== "")) && <ErrorText>
                                {Object.values(errorState).find(item => item !== "")}
                            </ErrorText>
                            }
                        </div>

                    </FlexContent>

                </FlexContent>

            </MainContainer>
    );
};

export default SettingsDesktop;

const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
`

const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "69%",
}))

const ErrorText = styled(`div`)((props) => ({
    color: "#C01414",

}))

const StyledButton = styled("button")<{disabled?: boolean}>((props) => ({
    textTransform: "none",
    height: 38,
    width: "100%",
    fontFamily: "Roboto Condensed",
    color: "white",
    fontWeight: 400,
    fontSize: 16,
    background: "#829FF5",
    "&:hover": {
        background: "#6a86d3"
    }
}));
