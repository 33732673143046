import {IUserState, userAction, UserActionTypes} from "../types/userTypes";

const initialState: IUserState = {
    error: undefined,
    loading: false,
    user: null,
    cashbackInfo: null,
    loadingCashbackInfo: false
}


const startLoading = (state: IUserState) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state: IUserState, error: string) => {
    return {...state, loading: false, error: error}
}


const userReducer = (state = initialState, action: userAction): IUserState => {
    switch (action.type) {

        case UserActionTypes.LOAD_USER:
        case UserActionTypes.UPDATE_USER:
        case UserActionTypes.LOGOUT_USER:
            return startLoading(state)

        case UserActionTypes.LOAD_CASHBACK_INFO: {
            return {...state, loadingCashbackInfo: true, loading: true, error: undefined}
        }


        case UserActionTypes.LOAD_USER_FAIL:
        case UserActionTypes.UPDATE_USER_FAIL:
        case UserActionTypes.LOGOUT_USER_FAIL:
            return stopLoading(state, action.error)

        case UserActionTypes.LOAD_CASHBACK_INFO_FAIL: {
            return {...state, loadingCashbackInfo: false, loading: false, error: action.error}
        }

        case UserActionTypes.LOAD_CASHBACK_INFO_SUCCESS:
            return {...state, loading: false, cashbackInfo: action.cashbackInfo, loadingCashbackInfo: false}

        case UserActionTypes.LOAD_USER_SUCCESS:
            return {
                ...state, loading: false, user: action.user
            }
        case UserActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state, loading: false, user: action.user
            }
        case UserActionTypes.LOGOUT_USER_SUCCESS:
            return {
                error: undefined,
                loading: false,
                user: null,
                cashbackInfo: null,
                loadingCashbackInfo: false
            }
        default:
            return state;
    }
}


export default userReducer;
