import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from 'assets/lotties/button_loader.json'

const LottieDotsLoader = () => {
    return (

        <Lottie
            loop
            animationData={lottieJson}
            play
            speed={0.5}
            segments={[10, 90]}
            style={{maxWidth: 320, height: 40}}
        />

    );
};

export default LottieDotsLoader;


