import React from 'react';
import styled from 'styled-components';
import CopyIcon from "assets/images/copy_icon.svg";
import {ReactComponent as PersonalDataIcon} from "assets/images/profile_logo_personal-data_icon.svg";
import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from "hooks/useActions";
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";
import {useTranslation} from "react-i18next";
import TextField from "ui/material-kit/TextField";


const PersonalDataDesktop = (): JSX.Element => {
    const {updateAlert} = useActions();
    const {t} = useTranslation();
    const {user} = useTypedSelector((state) => {
        return {
            user: state.user.user
        }
    });

    const handleClickProfile = async () => {
        await (() => {
            navigator.clipboard.writeText(`ID:  ${user?.id}`);
        })()
        updateAlert({
            isOpen: true,
            status: "warning",
            message: "Code has been copied"
        })
    }
    return (
        <>
            <MainContainer>
                <div style={{width: 339}}>
                    <NavigationLeftBar heightButton={"65px"}/>
                </div>
                <FlexContent style={{gap: 28}}>
                    <PersonalDataIcon/>
                    <FlexContent style={{gap: 10, width: 300}}>
                        <TextField readOnly extra={`height: 44px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.id_field`)}:  ${user?.id}`} rightIcon={{
                            url: CopyIcon,
                            onClick: async () => {await handleClickProfile()}
                        }}/>
                        <TextField readOnly extra={`height: 44px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.username_field`)}:  ${user?.login}`} />

                        <TextField readOnly extra={`height: 44px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.balance_field`)}:  ${user?.balance.toLocaleString()}`} />

                        <TextField readOnly extra={`height: 44px; font-size: 14px`} onChange={(e) => {
                        }} value={`${t(`pages.personal_data.currency_field`)}:  ${user?.currency.toUpperCase()}`} />

                    </FlexContent>


                </FlexContent>

            </MainContainer>
        </>
    );
};

export default PersonalDataDesktop;

const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
`

const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "69%",
}))

