export interface IAlert {
    status: string,
    message: string,
    isOpen: boolean
}


export interface IError {
    message: string,
    isOpen: boolean
}


export interface IStaticPage {
    id: number,
    title: string,
    priority: number,
    url: string,
    lang: string,
    content: string
}

export interface IPromotion {
    id: number,
    title: string,
    languageId: number,
    imageUrl: string,
    link: string,
    priority: number,
    disabled: boolean,
    content: string,
    createdAt: string,
    lang: string
}

export interface IBanner {
    id: number,
    title: string,
    languageId: number,
    imageUrl: string,
    link: string,
    priority: number,
    disabled: boolean,
    lang: string
}

export interface IAppState {
    alert: IAlert,
    modal: boolean,
    sport: any,
    error: IError,
    pages: IStaticPage[],
    promotions: IPromotion[],
    banners: IBanner[],
    loading: boolean
}


export enum AppTypes {
    GET_BANNERS = "GET_BANNERS",
    GET_STATIC_PAGES = "GET_STATIC_PAGES",
    GET_PROMOTIONS = "GET_PROMOTIONS",
    UPDATE_ERROR = "UPDATE_ERROR",
    UPDATE_ALERT = "UPDATE_ALERT",
    UPDATE_MODAL = "UPDATE_MODAL",
    SET_LOADING = "SET_LOADING",
    SET_SPORT = "SET_SPORT"
}


interface IUpdateError {
    type: AppTypes.UPDATE_ERROR;
    error: IError
}

interface IUpdateAlert {
    type: AppTypes.UPDATE_ALERT;
    alert: IAlert
}

interface IGetStaticPages {
    type: AppTypes.GET_STATIC_PAGES;
    pages: IStaticPage[]
}

interface IGetPromotions {
    type: AppTypes.GET_PROMOTIONS;
    promotions: IPromotion[]
}

interface IGetBanners {
    type: AppTypes.GET_BANNERS;
    banners: IBanner[]
}

interface IUpdateModal {
    type: AppTypes.UPDATE_MODAL;
    open: boolean
}

interface ISetSport {
    type: AppTypes.SET_SPORT;
    sport: any
}

interface ISetLoading {
    type: AppTypes.SET_LOADING;
    loading: boolean
}


export type appAction =
    IGetStaticPages
    | IGetBanners
    | IUpdateError
    | IGetPromotions
    | IUpdateAlert
    | ISetSport
    | IUpdateModal
    | ISetLoading;


