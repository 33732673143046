import React from 'react';
import {IPromotion} from "../../store/types/appTypes";
import Modal from "../../ui/material-kit/Modal";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import styled from "styled-components";
import {CustomButton} from "../../ui/StyledButton";
import PromotionCard from 'ui/PromotionCard';
import {useTranslation} from 'react-i18next';

type PromotionModalProps = {
    isDesktop?: boolean,
    promotion: IPromotion | undefined,
    open: boolean,
    onClose: () => void
}
const PromotionModal = (props: PromotionModalProps) => {
    const {t} = useTranslation();
    return (
        <Modal
            open={props.open}
            isFullScreen={!props.isDesktop}
            onClose={props.onClose}>
            <Background isDesktop={props.isDesktop}>
                {
                    props.promotion && <ModalWrapper>
                        <TitleContainer>
                            <Title>{props.promotion.title}</Title> <ExitIcon url={CrossIcon} onClick={() => {
                            props.onClose()
                        }}/>
                        </TitleContainer>
                        <Container isDesktop={props.isDesktop}>
                            <CardContainer isDesktop={props.isDesktop}>
                                <PromotionCard
                                    imageUrl={props.promotion.imageUrl} withArrow={false}/>
                            </CardContainer>
                            <ContentContainer dangerouslySetInnerHTML={{__html: props.promotion.content}}/>
                        </Container>

                        <Container>
                            <CustomButton extra={`width: 264px`} onClick={() => {
                                if (props.promotion?.link) {
                                    window.location.replace(props.promotion?.link);
                                }
                                props.onClose()
                            }}> {t(`pages.promotions.play`)}</CustomButton>
                        </Container>

                    </ModalWrapper>
                }

            </Background>
        </Modal>
    );
};


const ExitIcon = styled.div<{ url: string }>`
  position: absolute;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  top: 20px;
`

const Background = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  width: ${props => !props.isDesktop ? "100%" : "850px"};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  overflow: auto;
  max-height: 100vh;
  border: ${props => props.isDesktop ? "3px solid #263154" : "none"};
  box-sizing: border-box;
`

const ModalWrapper = styled.div<{ isDesktop?: boolean }>`
  padding: 22px 30px;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
          max-height: 70vh;
          width: 1074px;
          box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);         
          `
    }
  }}
`

const TitleContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const Title = styled.div<{ isDesktop?: boolean }>`
  color: white;
  font-size: 26px;
  text-align: center;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  line-height: 25px;
  max-width: 1000px;
  overflow: hidden;
  
`

const Container = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
      align-items: center;
      justify-content: center;
      flex-direction: column;     
          `
    }
  }}
`

const CardContainer = styled.div<{ isDesktop?: boolean }>`

  ${({isDesktop}) => {
    if (isDesktop) {
      return `
  align-self: flex-start;
          `
    }
  }}
`


const ContentContainer = styled.div<{ isDesktop?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-height: ${props => props.isDesktop ? "600px" : "300px"};

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  font-family: 'Roboto Condensed', 'serif';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  & > * {
    word-break: break-all;
    line-height: 1em;
  }

  & > p {
    margin: 0.5em;
    padding: 0;
  }

  ${({isDesktop}) => {
    if (isDesktop) {
      return `
          max-height: 70vh;
          max-width: 1074px;
          box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);
          `
    }
  }}
`

export default PromotionModal;
