import {transactionApi} from "api/transactions/transactionApi";
import {IGetBets, IGetTransactions} from "api/transactions/transactionTypes";
import {Dispatch} from "redux";
import {
    IBet,
    ICashBack,
    ITransaction,
    transactionAction,
    TransactionActionTypes as types
} from "../types/transactionTypes";


////////////////////////////////////////////////////////
//LOAD//GET

export const InitialTransactions_ = (): transactionAction => {
    return {
        type: types.INITIAL_TRANSACTIONS
    }
}


const loadTransactions_ = (): transactionAction => {
    return {
        type: types.LOAD_TRANSACTIONS
    }
}

const loadBets_ = (): transactionAction => {
    return {
        type: types.LOAD_BETS
    }
}

const loadCashbackOperations_ = (): transactionAction => {
    return {
        type: types.LOAD_CASHBACK_OPERATIONS
    }
}

const loadTransactionsFailed = (error: string): transactionAction => {
    return {
        type: types.LOAD_TRANSACTIONS_FAIL,
        error
    }
}

const loadBetsFailed = (error: string): transactionAction => {
    return {
        type: types.LOAD_BETS_FAIL,
        error
    }
}

const loadCashbackOperationsFailed = (error: string): transactionAction => {
    return {
        type: types.LOAD_CASHBACK_OPERATIONS_FAIL,
        error
    }
}


const loadTransactionsSuccess = (action: string, transactions: ITransaction[], count: number): transactionAction => {
    return {
        type: types.LOAD_TRANSACTIONS_SUCCESS,
        transactions,
        action,
        count
    }
}

const loadBetsSuccess = (action: string, bets: IBet[], count: number): transactionAction => {
    return {
        type: types.LOAD_BETS_SUCCESS,
        bets,
        action,
        count
    }
}

const loadCashbackOperationsSuccess = (action: string, cashbackOperations: ICashBack[], count: number): transactionAction => {
    return {
        type: types.LOAD_CASHBACK_OPERATIONS_SUCCESS,
        cashbackOperations,
        action,
        count
    }
}


//THUNK
export const getTransactions = ({
                                    page = 1,
                                    size = 10,
                                    action = "update",
                                    range_end,
                                    range_start
                                }: IGetTransactions
) => {
    return (dispatch: Dispatch<transactionAction>) => {
        dispatch(loadTransactions_());
        return transactionApi.getTransactions({
            page, type: "operation", size, range_end, range_start
        }).then(pld => {
            dispatch(loadTransactionsSuccess(action, pld.data, pld.count));
        }).catch(error => {
            let res = error.response;
            dispatch(loadTransactionsFailed(res.data.message))
        })
    }
}

export const getCashbackOperations = ({
                                    page = 1,
                                    size = 10,
                                    action = "update",
                                    range_end,
                                    range_start
                                }: IGetTransactions
) => {
    return (dispatch: Dispatch<transactionAction>) => {
        dispatch(loadCashbackOperations_());
        return transactionApi.getTransactions({
            page, type: "cashback", size, range_end, range_start
        }).then(pld => {
            dispatch(loadCashbackOperationsSuccess(action, pld.data, pld.count));
        }).catch(error => {
            let res = error.response;
            dispatch(loadCashbackOperationsFailed(res.data.message))
        })
    }
}

export const getBets = ({
                            page = 1,
                            size = 10,
                            action = "update",
                            range_end,
                            range_start
                        }: IGetBets) => {
    return (dispatch: Dispatch<transactionAction>) => {
        dispatch(loadBets_());
        return transactionApi.getBets({
            page,
            size, range_end, range_start
        }).then(pld => {
            dispatch(loadBetsSuccess(action, pld.data, pld.count));
        }).catch(error => {
            let res = error.response;
            dispatch(loadBetsFailed(res.data.message))
        })
    }
}



