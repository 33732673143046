import {ReactComponent as LogoLoader} from "assets/images/loader_logo_icon.svg";
import React from 'react';
import styled from "styled-components";

const Loader = (): JSX.Element => {
    return (
        <Container>
            <LogoLoader/>
        </Container>
    );
};

export default Loader;


const Container = styled("div")((props) => ({
    display: "flex",
    justifyContent: "center",
    height: "100vh",
    overflow: "hidden",
    width: "100vw",
    background: "rgba(18,25,49,0.8)",
    alignItems: "center",
}))
