import React, {useEffect, useRef} from 'react';
import Slider from 'components/slider';
import {useTypedSelector} from "hooks/useTypedSelector"
import PromotionsCards from 'components/cards/desktopCards/PromotionsCards';
import GameBlock from 'components/blocks/GameBlock';
import {useActions} from 'hooks/useActions';
import FooterDesktop from "../components/wrapper/footer/FooterDesktop";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import styled from "styled-components";
import useDimensions from "react-cool-dimensions";
import Work from "../components/alerts/Work";
import {CardsMenu} from "../features/cards-menu";
import { devices } from 'constants/breakpoints';


const MainDesktop = () => {
    const {getGameList, getTvGames, updateAlert} = useActions();
    const {t} = useTranslation();
    let navigate = useNavigate();


    const componentMounted = useRef(true);
    const {user, gameList, banners, tvGamesList} = useTypedSelector((state) => {
        return {
            user: state.user.user || undefined,
            banners: state.app.banners.map((item) => {

                if (item.link) {
                    let titleSplit = item.link.split(window.location.hostname);
                    if (titleSplit.length === 2) {
                        return {name: item.title, src: item.imageUrl, onClick: () => navigate(titleSplit[1])}
                    } else {
                        return {name: item.title, src: item.imageUrl, onClick: () => window.location.replace(item.link)}
                    }
                } else {
                    return {name: item.title, src: item.imageUrl}
                }

            }),
            tvGamesList: state.game.tvGames.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }

            }),
            gameList: state.game.gameList.toArray().map(item => {
                return {
                    id: item[1].Id,
                    providerId: item[1].SectionId,
                    name: item[1].Name,
                    url: item[1].Thumbnail,
                    isFavorite: item[1].IsFavorite,
                }

            }),
        }
    })


    const openAlert = () => {
        updateAlert({isOpen: true, status: "error", message: `${t(`common.alert.text_error`)}`})
    }
    useEffect(() => {
        if (componentMounted.current) {
            getGameList({
                size: 16,
                title: "",
                provider: "",
                action: "update"
            });
            getTvGames({
                title: "",
                provider: "",
                action: "update",
                size: 16,
            })
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const handleCallback = (): boolean => {
        if (!user) {
            openAlert();
            return false;
        } else return true;
    }


    const {observe, height, width} = useDimensions({});
    return (
        <>
            <Slider slides={banners}/>
            <DesktopContainer ref={observe}>
                <div style={{margin: "32px 0 42px 0px"}}>
                    <StyledCardsMenu isDesktop/>
                </div>
                <div style={{margin: "42px 0px"}}>
                    <GameBlock fullWidth={width} link={"/games"} games={gameList} addFavorite={true}
                               title={`${t(`common.navbar.nav_games`)}`}
                               handleCallback={handleCallback}/>
                </div>
                <div style={{margin: "42px 0px"}}>
                    <GameBlock fullWidth={width} link={"/tv-games"} isTvGames addFavorite={false} games={tvGamesList}
                               title={`${t(`common.navbar.nav_tv_games`)}`}
                               handleCallback={handleCallback}/>
                </div>

            </DesktopContainer>
            <div style={{marginTop: "200px"}}>
                <FooterDesktop/>
            </div>

        </>
    );
};

export default MainDesktop;

const StyledCardsMenu = styled(CardsMenu)`
  display: flex;
  justify-content: space-between;
  max-height: 174px;
  
  & > div {
    height: 174px;
    cursor: pointer;
    :hover {
        filter: brightness(1.2);
    };
    ${devices.desktop} {
      height: 150px;
    }
  }
`

const DesktopContainer = styled.div`
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  max-width: 1658px;
  @media (max-width: 1758px) {
    margin: 0 120px;
  }
`
