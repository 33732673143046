import React from 'react';
import Modal from "../../ui/material-kit/Modal";
import styled from "styled-components";

import {useTranslation} from 'react-i18next';
import {CustomButton} from 'ui/StyledButton';
import {useNavigate} from "react-router-dom";
import Lottie from "react-lottie-player";
import lottieJson from "../../assets/lotties/error.json";
import {useActions} from "../../hooks/useActions";
import {useTypedSelector} from 'hooks/useTypedSelector';

type PromotionModalProps = {
    isDesktop?: boolean,
}
const ErrorModal = (props: PromotionModalProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        updateError
    } = useActions();
    const {error} = useTypedSelector(state => {
        return ({
            error: state.app.error,
        })
    });
    return (
        <Modal
            open={error.isOpen}
            isFullScreen={false}
            onClose={() => {
                updateError({...error, isOpen: false,})
                navigate(-1);
            }}>
            <Background isDesktop={props.isDesktop}>

                <ModalWrapper>

                    <Lottie
                        loop
                        animationData={lottieJson}
                        play
                        speed={0.5}
                        style={{width: 280}}
                        segments={[0, 100]}
                    />
                    <Container isDesktop={props.isDesktop}>
                        <ContentContainer>
                            {error.message}
                        </ContentContainer>
                    </Container>

                    <Container>
                        <CustomButton extra={`width: 264px`} onClick={() => {
                            updateError({...error, isOpen: false,})
                            navigate(-1);
                        }}> {t(`common.alert.back_to_games`)}</CustomButton>
                    </Container>

                </ModalWrapper>


            </Background>
        </Modal>
    );
};


const Background = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  width: ${props => !props.isDesktop ? "100%" : "500px"};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  overflow: auto;
  max-height: 100vh;
  border: ${props => props.isDesktop ? "3px solid #263154" : "none"};
  box-sizing: border-box;
`

const ModalWrapper = styled.div<{ isDesktop?: boolean }>`
  padding: 22px 30px;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
          max-height: 70vh;
          width: 1074px;
          box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);         
          `
    }
  }}
`


const Container = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
      align-items: center;
      justify-content: center;
      flex-direction: column;     
          `
    }
  }}
`


const ContentContainer = styled.div<{ isDesktop?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-overflow: ellipsis;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  font-family: 'Roboto Condensed', 'serif';
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;

  & > * {
    word-break: break-all;
    line-height: 1em;
  }

  & > p {
    margin: 0.5em;
    padding: 0;
  }


`

export default ErrorModal;
