import React, {useEffect, useRef} from 'react';
import Portal from "services/PortalService";
import useOutsideClick from "hooks/useOutsideClick";
import styled, {css} from "styled-components";
import {CSSTransition} from 'react-transition-group';

type ModalProps = {
    onClose?: () => void,
    open: boolean,
    children: React.ReactNode,
    isFullScreen?: boolean,
    extra?: string,

}

const Modal = (props: ModalProps) => {
    const {
        open,
        onClose = () => {
        },
        children,
        isFullScreen = false,
        extra = ``
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        onClose();
    })

    useEffect(() => {
        let body = document.getElementsByTagName("body");
        if (open) {

            body.item(0)!.style.overflow = "hidden";
        } else {

            body.item(0)!.style.overflow = "auto";
        }

    }, [open])

    return (
      <>
          <CSSTransition
              in={open}
              timeout={200}
              classNames={"modal_offset"}
              unmountOnExit
          >
          <Portal id="modal-root">
              <ModalWrapper extra={extra}>
                  <Container isFullScreen={isFullScreen} ref={ref}>
                      {children}
                  </Container>
              </ModalWrapper>
          </Portal>
          </CSSTransition>
      </>


    );
};

export default Modal;

const Container = styled.div<{ isFullScreen: boolean }>`
  ${({isFullScreen}) =>
          isFullScreen &&
          css`
            margin: 0;
            position: relative;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            max-height: none;
            max-width: 100%;
            width: 100%;
            height: 100%;
            border-radius: 0;
          `}
`

const ModalWrapper = styled.div<{ extra: string }>`
  padding: 0;
  z-index: 10000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: #12193180;

  height: 100%;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  ${({extra}) => extra}
`


