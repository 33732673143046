import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import BackImage from "assets/images/ad_tv-games-background_img.webp";
import MaticImage from "assets/images/ad_matic-background_img.webp";
import GamesImage from "assets/images/ad-games-background_img.webp";
import SportsImage from "assets/images/ad_sports-background_image.webp";
import LiveGamesImage from "assets/images/ad_live-games-background_image.webp";
import LiveGamesSvgIcon from "assets/images/ad_main-live-sport-icon.png";

import SportsSvgIcon from "assets/images/ad_main-sports-icon.png";

import {ReactComponent as MainSvgIcon} from "assets/images/ad_main-icon_icon.svg";
import {ReactComponent as AmaticSvgIcon} from "assets/images/ad_main-amatic-icon.svg";
import {ReactComponent as GamesSvgIcon} from "assets/images/ad_main-games-icon.svg";
import DesktopCard from 'ui/adCard/DesktopCard';
import { useTranslation } from 'react-i18next';
import {getStorage} from "../../../hooks/useStorage";
import {useActions} from "../../../hooks/useActions";


type PromotionsCardsProps = {
    isAuth?: boolean,
    setOpenAlert: () => void,
    fullWidth: number;
}

const PromotionsCards = (props: PromotionsCardsProps) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {updateAlert} = useActions();
    const navbar: {
        KEY: string,
        VISIBLE: boolean,
        COLOR: string,
        PATH: string,
        DESKTOP_PATH: string,
        BACKGROUND_PATH: string,
        ICON_PATH: string,
        GRID: string
    }[] = getStorage("APP_OTHER.NAVBAR");
    const data = Object.values(navbar).filter(item => item.VISIBLE)

    const openAlert = () => {
        updateAlert({isOpen: true, status: "warning", message: `${t(`common.alert.will_be_soon`)}`})
    }

    return (
        <SliderWrapperCard>
            <SliderBlock>
                <Block>
                    {
                        data.slice(0, 5).map((item, index) => (
                            <React.Fragment key={`promotion-card-${index}-${item.KEY}`}>
                                <DesktopCard width={Math.trunc((props.fullWidth - 48) / 5)} backgroundImage={item.BACKGROUND_PATH} onClick={() => {
                                    if (item.DESKTOP_PATH === "") {
                                        openAlert();
                                    } else {
                                        navigate(item.DESKTOP_PATH);
                                        window.scrollTo(0, 0);
                                    }

                                }}
                                             description={t(`pages.main.card_${item.KEY}.description`)}
                                             title={t(`pages.main.card_${item.KEY}.title`)} icon={item.ICON_PATH}/>
                            </React.Fragment>
                        ))
                    }
                </Block>

            </SliderBlock>
        </SliderWrapperCard>
    );
};

export default PromotionsCards;


const SliderWrapperCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`

const SliderBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
`

const Block = styled.div`
  display: flex;
  gap: 12px;
  flex-flow: row wrap;
  width: max-content;
`
