import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as HistoryOperationIcon} from "assets/images/profile_logo_history-of-operation_icon.svg"
import FilterDateCustom from 'ui/FilterDateCustom';
import OperationItem from "ui/OperationItem";

import {useTypedSelector} from "hooks/useTypedSelector";
import {useActions} from "hooks/useActions";
import InfiniteScroll from 'react-infinite-scroll-component';
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";

const OperationHistoryDesktop = (): JSX.Element => {
    const [page, setPage] = useState(1);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [calendar, setCalendar] = useState({
        range_start: new Date().setDate(new Date().getDate() + 7),
        range_end: + new Date(),
    });

    const {transactionsCount, transactions, isLoading, error} = useTypedSelector((state) => {
        return {
            error: state.transaction.error,
            isLoading: state.transaction.loading,
            transactionsCount: state.transaction.transactionsCount,
            transactions: state.transaction.transactions
        }
    })

    const {getTransactions} = useActions();

    useEffect(() => {
        setIsLoadingData(true);
        handleSearch().then(() => {
            setPage(1);
            setHasMoreItems(true);
            setIsLoadingData(false);
        });
    }, [calendar]);


    const handleSearch = async () => {
        await getTransactions({
            range_start: calendar.range_start,
            range_end: calendar.range_end,
        });

    }

    const loadMore = () => {
        if (transactions.length >= transactionsCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getTransactions({
            page: page + 1,
            range_start: calendar.range_start,
            range_end: calendar.range_end,
            action: "add",
        })

    };

    return (
        <>
            <MainContainer>
                <div style={{width: 339}}>
                    <NavigationLeftBar heightButton={"65px"}/>
                </div>
                <FlexContent style={{gap: 28}}>
                    <HistoryOperationIcon/>
                    <FilterDateCustom
                        isLoading={isLoadingData}
                        onSave={(from: Date, to: Date) => {
                            setCalendar({range_start: +from, range_end: +to})
                        }}/>
                </FlexContent>
                <ContainerHistory id={`scrollableDivOperations`}>
                    <InfiniteScroll
                        dataLength={transactions.length}
                        scrollableTarget={`scrollableDivOperations`}
                        next={loadMore}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 10,
                        }}
                        hasMore={hasMoreItems}
                        loader={<></>}
                    >

                            {
                                transactions.map((item, index) => (
                                    <React.Fragment key={`operations-history-${index}-${item.createdAt}`}>
                                        <OperationItem amount={`${item.amount.toFixed(2)}`}
                                                       date={item.createdAt} type={item.type}/>
                                    </React.Fragment>
                                ))

                            }

                    </InfiniteScroll>
                </ContainerHistory>
            </MainContainer>
        </>
    );
};

export default OperationHistoryDesktop;

const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
  justify-content: space-between;
`

const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 240,

}))

const ContainerHistory = styled(`div`)((props) => ({
    width: 428,
    height: 450,
    overflowY: "auto",
}))


