import React, {useEffect, useState} from 'react';
import {IProviderGame} from "store/types/gamesTypes";
import {ReactComponent as Arrow} from "assets/images/games_arrow_icon.svg"
import {useNavigate} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {useActions} from 'hooks/useActions';
import GameCard from "ui/GameCard";
import {useTypedSelector} from "hooks/useTypedSelector";
import openGame from "helpers/gamesHelper/openGame";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";


type ProviderBlockProps = {
    providerGame: IProviderGame,
    title: string,
    isLoading: boolean,
    category: string,
}

const ProviderBlock = (props: ProviderBlockProps) => {
    const navigate = useNavigate();
    const {getProviderGames, toggleFavoriteGames, updateAlert, setLoading} = useActions();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [page, setPage] = useState(1);

    const {user} = useTypedSelector((state) => {
        return {
            user: state.user.user
        }
    })
    const loadMore = async () => {
        if (props.providerGame.Games.length >= props.providerGame.Count) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        await getProviderGames({
            title: props.title,
            provider: props.providerGame.SectionId,
            category: props.category,
            action: "add",
            page: newPage
        })

    };


    useEffect(() => {
        setPage(1);
    }, [props.title]);

    const {t} = useTranslation();
    return (
        <Wrapper>
            <ContainerProvider onClick={() => {
                navigate(`/provider/${props.providerGame.SectionId}/games`);
                window.scrollTo(0, 0);

            }}>
                <Title>
                    {`${props.providerGame.SectionId}`}
                </Title>
                <Arrow/>
            </ContainerProvider>

            <SliderWrapperCard
                id={`scrollableDiv${props.providerGame.SectionId}`}
            >
                <InfiniteScroll
                    dataLength={props.providerGame.Games.length}
                    next={loadMore}
                    scrollableTarget={`scrollableDiv${props.providerGame.SectionId}`}
                    style={{display: "flex", flexDirection: "row", gap: 10}}
                    hasMore={hasMoreItems && props.title === ""}
                    loader={<></>}
                >
                    {
                        props.providerGame.Games.map((item, index) => (
                            <React.Fragment key={`${index}-${item.Id}-games`}>
                                <GameCard favoriteClick={(e) => {
                                    if (!user) {
                                        updateAlert({
                                            isOpen: true,
                                            status: "error",
                                            message: `${t(`common.alert.text_error`)}`
                                        })
                                    } else {
                                        e.stopPropagation();
                                        toggleFavoriteGames(item.Id)
                                    }
                                }} isFavorite={item.IsFavorite} url={item.Thumbnail} onClick={() => {
                                    if (!user) {
                                        updateAlert({
                                            isOpen: true,
                                            status: "error",
                                            message: `${t(`common.alert.text_error`)}`
                                        })
                                    } else {
                                        openGame({
                                            providerId: item.SectionId,
                                            gameId: item.Id,
                                            setLoading: (bool: boolean) => {
                                                setLoading(bool);
                                            }
                                        })
                                    }
                                }}/>
                            </React.Fragment>
                        ))
                    }

                </InfiniteScroll>
            </SliderWrapperCard>
        </Wrapper>
    );
};

export default ProviderBlock;


const SliderWrapperCard = styled(`div`)((props) => ({
    width: "100%",
    overflowX: "auto",
    display: "flex",
    gap: 10,
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
        "&::-webkit-scrollbar": {display: "none"},
    },

}));


const Wrapper = styled('div')((props) => ({
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    marginBottom: "10px",
    flexDirection: "column",
    gap: 10
}));


const Title = styled('div')((props) => ({
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 400,
}));


const ContainerProvider = styled('div')((props) => ({
    width: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    backgroundColor: "#39466D",
    "&:hover": {
        backgroundColor: "#2e3a59",
    },
    borderRadius: 5,
    padding: "4px 10px",

}));
