import React, {useEffect, useRef, useState} from 'react';


import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
import styled from 'styled-components';
import LazyImage from "../../ui/LazyImage";
import {useTranslation} from "react-i18next";


interface ISlide {
    name: string,
    src: string,
    onClick?: () => void
}

type SliderProps = {
  slides: ISlide[]
}

const Slider = (props: SliderProps): JSX.Element => {
    const {t, i18n} = useTranslation();
    const pagination = {
        "clickable": true,
        "renderBullet": function (index: number, className: string) {
            return '<span class=\"' + className + '\">' + '</span>';
        },

    }


    return (
        <>
            <SuperSwiper
                key={`${i18n.language}`}
                centeredSlides={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                speed={1000}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={pagination}
                className="mySwiper"
            >
                {props.slides.map((item, index) => (
                    <SwiperSlide style={{cursor: item.onClick && "pointer", }} key={`banner-${index}-${item.name}-${i18n.language}`} onClick={item.onClick}>
                        <Image src={item.src}/>
                    </SwiperSlide>
                ))}
            </SuperSwiper>
        </>
    );
};

export default Slider;


const Image = styled(LazyImage)((props) => ({
    width: "100vw",
    minWidth: 1200,
    height: "auto",
    minHeight: 325
}))


const SuperSwiper = styled(Swiper)(({theme}) => ({
    ".swiper": {
        width: "100%",
        height: "100%"
    },

    ".swiper-slide": {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },


    ".swiper-pagination-bullet": {
        background: "rgba(255, 255, 255, 0.5)",
        opacity: 1,
        width: 20,
        margin: "0px 7px!important",
        height: 20,
    },

    ".swiper-pagination": {
        textAlign: "center",
        position: "absolute",
        zIndex: 100,

        left: 0,
        right: 0,
        bottom: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    },

    ".swiper-pagination-bullet-active": {
        background: "#FFB401",
        width: 20,
        height: 20
    },

    ".swiper-button-prev": {
        color: "rgba(255, 255, 255, 0.7)"
    },

    ".swiper-button-next": {
        color: "rgba(255, 255, 255, 0.7)"
    }
}))
