import React, {useEffect} from 'react';
import RouterApp from "./routes";
import {YMInitializer} from 'react-yandex-metrika';
import settings from "./settings";
import {putStorage} from "./hooks/useStorage";
import useScript from 'hooks/useScript';
import SEO from "./components/seo";

const services = settings.NODE_ENV === "development" ? settings.SETTINGS.SERVICES_DEV : settings.SETTINGS.SERVICES_PROD;
const seoSettings = settings.SETTINGS.SEO;

const App = () => {
    useScript(`https://embed.tawk.to/${services.TAWK.ID}/${services.TAWK.PROPERTY_ID}`, true)
    const mirror = Object.keys(settings.SETTINGS.MIRRORS).find(item => item === window.location.host);
    const curSeoSettings = mirror ? settings.SETTINGS.MIRRORS[`${mirror}`].SEO : seoSettings
    useEffect(() => {
        putStorage("APP_SETTINGS", settings, {silent: true});
        putStorage("API_BASE_URL", settings.NODE_ENV === "development" ?
                settings.SETTINGS.API_DEV_URL :
                mirror ? settings.SETTINGS.MIRRORS[`${mirror}`].API_PROD_URL :
                    settings.SETTINGS.API_PROD_URL,
            {silent: true});
        putStorage("APP_SEO", curSeoSettings, {silent: true});
        putStorage("APP_ACCESS", settings.SETTINGS.ACCESS, {silent: true});
        putStorage("APP_OTHER", settings.SETTINGS.OTHER, {silent: true});
        putStorage("APP_LOADING", false, {silent: true});
        putStorage("APP_SERVICES", services);

    }, [])
    return (
        <>
            <SEO title={curSeoSettings.TITLE}
                 loginKey={curSeoSettings.LOGIN_LOGO}
                 description={curSeoSettings.DESCRIPTION}
                 favicon={curSeoSettings.FAVICON_PATH}
                 keywords={curSeoSettings.KEYWORDS}
                 imgPath={curSeoSettings.LOGO_PATH}/>
            <RouterApp/>
            <YMInitializer accounts={[+services["YM"]["KEY"]]}
                           options={{
                               clickmap: true,
                               trackLinks: true,
                               accurateTrackBounce: true, webvisor: true
                           }}/>
        </>

    );
};

export default App;

