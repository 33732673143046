import React from 'react';

import {ReactComponent as Arrow} from "assets/images/game_arrow_icon.svg"
import LazyImage from '../LazyImage';
import styled from 'styled-components';


type FluidCardProps = {
    title: string,
    description: string,
    onClick?: () => void,
    gridColumn: string,
    backgroundImage: string,
    arrowColor: string,
    icon: string,
    height: number

}
const FluidCard = (props: FluidCardProps) => {
    return (
        <ContainerSegment id={`fluid-card-${props.title}`} onClick={props.onClick} height={props.height} gridColumn={props.gridColumn}>
            <Image numheight={props.height} src={require(`../../${props.backgroundImage}`)} alt={`nice-card-${props.title}`}/>
            <Content>
                <TopContainer style={{alignItems: "flex-start"}}>
                    <SvgContainer>
                        <img style={{height: 47, width: "auto"}} src={require(`../../${props.icon}`)} alt={props.title}/>
                    </SvgContainer>
                </TopContainer>
                <TopContainer style={{padding: "0 0.4375rem 0.5rem 0.625rem"}}>
                    <TextContainer>
                        <Title>
                            {props.title}
                        </Title>
                        <Description>
                            {props.description}
                        </Description>
                    </TextContainer>
                    <SvgIcon key={props.title} color={props.arrowColor}>
                        <Arrow/>
                    </SvgIcon>
                </TopContainer>
            </Content>
        </ContainerSegment>
    );
};

export default FluidCard;


const ContainerSegment = styled(`div`)<{ gridColumn: string, height: number }>((props) => ({
    width: "100%",
    height: props.height,
    gridColumn: props.gridColumn,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    position: "relative",
}));


const SvgIcon = styled('div')<{ color: string }>((props) => ({
    "& > svg > path": {
        stroke: props.color
    },
    filter: `drop-shadow(0px 2px 10px ${props.color})`,
}));

const Image = styled(LazyImage)<{ numheight: number }>((props) => ({
    width: "100%",
    height: props.numheight,
    zIndex: 0,
    objectFit: "cover",
    border: "2px solid #2d427a",
    objectPosition: "right",
    borderRadius: "5px",
}));

const TopContainer = styled(`div`)((props) => ({
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box"
}));

const SvgContainer = styled(`span`)((props) => ({
    display: "flex",
    padding: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
}));

const TextContainer = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
}));


const Content = styled(`div`)((props) => ({
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(35,9,55,0.16)",
    }
}));


const Title = styled(`div`)((props) => ({
    fontSize: 20,
    textTransform: "uppercase",
    color: "white",
}));

const Description = styled(`div`)((props) => ({
    fontSize: 12,
    color: "#5E6F9E"
}));
