import React, {useEffect, useState} from 'react';
import {ReactComponent as CashbackIcon} from "assets/images/profile_cashback-icon.svg"
import axios from 'api';
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import TextField from "ui/material-kit/TextField";
import {getStorage} from "../../hooks/useStorage";
import LottieDotsLoader from 'ui/loaders/DotsLottieLoader';
import GoldTime from "../../ui/GoldTime";
import {useActions} from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";


const CashbackDesktop = (): JSX.Element => {
    const {t} = useTranslation();
    const [isDisabled, setIsDisabled] = useState(false);
    const {setUserBalance, getCashbackInfo} = useActions();

    const {user, cashbackInfo, loadingCashbackInfo} = useTypedSelector(state => {
        return ({
            user: state.user.user,
            cashbackInfo: state.user.cashbackInfo,
            loadingCashbackInfo: state.user.loadingCashbackInfo,
        })
    })


    useEffect(() => {
        getCashbackInfo();
    }, [window.location]);

    const getCashback = async () => {
        axios.post(`/api/get-cashback`).then((pld: any) => {
            if (user) {
                setUserBalance(pld.data.balance);
                getCashbackInfo();
                setIsDisabled(false);
            }

        }).catch((e) => {
            getCashbackInfo();
            setIsDisabled(false);
        });
    }

    const currency = getStorage("APP_OTHER.CURRENCY.KEY");
    return (
        <MainContainer>

            <div style={{width: 339}}>
                <NavigationLeftBar heightButton={"65px"}/>
            </div>
            <FlexContent style={{gap: 28}}>
                <CashbackIcon/>

                {cashbackInfo?.isAvailable && <Description>
                    {cashbackInfo?.isReceived && t(`pages.cashback.already_description`)}
                </Description>}

                {!cashbackInfo?.isAvailable && <Description>
                    {t(`pages.cashback.no_description`)}
                </Description>}

                <FlexContent style={{gap: 10, width: 338}}>

                    {cashbackInfo?.isAvailable && <div style={{width: "100%", display: "flex", gap: 10}}>
                        <TextField readOnly extra={`height: 45px; width: 100%`} onChange={(e) => {
                        }}
                                   value={`${t(`pages.cashback.amount`).toUpperCase()}:  ${cashbackInfo?.amount} ${(user?.currency ?? currency).toUpperCase()}`}/>
                    </div>
                    }
                    <div style={{
                        padding: "0px 20px 20px 20px", width: "100%", display: "flex",
                        justifyContent: "center", flexDirection: "column", alignItems: "flex-start", gap: 10
                    }}>
                        <div>
                            {t(`pages.cashback.time_left`)}
                        </div>

                        <GoldTime initialTime={cashbackInfo?.timeLeft} timeEndCallBack={() => {
                            setTimeout(() => {
                                getCashbackInfo();
                            }, 1000)

                        }}/>

                        <StyledButton
                            disabled={!cashbackInfo?.isAvailable || cashbackInfo?.isReceived || loadingCashbackInfo || isDisabled}
                            onClick={async () => {
                                setIsDisabled(true);
                                await getCashback();
                            }}>
                            {loadingCashbackInfo ? <LottieDotsLoader/> : t(`pages.cashback.get_cashback`)}

                        </StyledButton>

                    </div>

                </FlexContent>

            </FlexContent>

        </MainContainer>
    );
};

export default CashbackDesktop;

const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
`

const Description = styled.div`
  font-size: 14px;
  font-weight: 600;
  max-width: 200px;
  line-height: 16px;
  text-align: center;
`


const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "69%",
}))


const StyledButton = styled("button")<{ disabled?: boolean }>((props) => ({
    textTransform: "none",
    height: 45,
    width: "100%",
    fontFamily: "Roboto Condensed",
    color: "white",
    fontSize: 16,
    borderRadius: 5,
    cursor: "pointer",
    background: "#829FF5",
    "&:hover": {
        background: "#6a86d3"
    },
    "&:disabled": {
        background: "#373D50",
        cursor: "default"
    }
}));
