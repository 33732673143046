import React, {useEffect, useState} from 'react';
import Modal from "../../ui/material-kit/Modal";
import CrossIcon from "../../assets/images/input_cross_icon.svg";
import styled from "styled-components";
import {useTranslation} from 'react-i18next';
import TextField from "../../ui/material-kit/TextField";
import {useInput} from "../../hooks/useInput";
import {CustomButton} from "../../ui/StyledButton";
import {appAPI} from "../../api/app/appApi";
import {useActions} from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {IStaticPage} from "../../store/types/appTypes";

interface helperModalProps {
    isOpen: boolean,
    isDesktop?: boolean,
    onClose: () => void
}

const RulesModal = (props: helperModalProps) => {
    const {isOpen, isDesktop, onClose} = props;
    const {t, i18n} = useTranslation();
    const [pageContent, setPageContent] = useState<IStaticPage>();
    const {pages} = useTypedSelector(state => {
        return {
            pages: state.app.pages,
        }
    })

    useEffect(() => {
        setPageContent(pages.find(item => item.url === `tv-games-rules_${i18n.language}`))

    }, []);

    return (
        <Modal
            open={isOpen}
            isFullScreen={!isDesktop}
            onClose={() => {
                onClose()
            }}>
            <Background isDesktop={!isDesktop}>
                <ModalWrapper>
                    <TitleContainer>
                        <Title>{pageContent?.title}</Title> <ExitIcon url={CrossIcon} onClick={() => {
                        props.onClose()
                    }}/>
                    </TitleContainer>
                    <div dangerouslySetInnerHTML={{__html: pageContent?.content!}}/>
                    <Container>
                        <CustomButton extra={`width: 264px`} onClick={() => {
                            props.onClose()
                        }}>{t("common.help.thanks")}</CustomButton>
                    </Container>

                </ModalWrapper>
            </Background>
        </Modal>
    );
};

export default RulesModal;

const ExitIcon = styled.div<{ url: string }>`
  position: absolute;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 20px;
  top: 20px;
`

const Background = styled.div<{ isDesktop?: boolean }>`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  width: ${props => props.isDesktop ? "100%" : "700px"};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  overflow: auto;
  max-height: 100vh;
  border: ${props => !props.isDesktop ? "3px solid #263154" : "none"};
  box-sizing: border-box;
`

const ModalWrapper = styled.div<{ isDesktop?: boolean }>`
  padding: 22px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
          max-height: 70vh;
          width: 1074px;
          box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22);         
          `
    }
  }}
`

const TitleContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const Title = styled.div<{ isDesktop?: boolean }>`
  color: white;
  font-size: 26px;
  text-align: center;
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  word-break: break-all;
  line-height: 25px;
  max-width: 1000px;
`

const Container = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
      align-items: center;
      justify-content: center;
      flex-direction: column;     
          `
    }
  }}
`
