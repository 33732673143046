import React, {ChangeEvent, InputHTMLAttributes, useState} from 'react';
import styled, {css} from 'styled-components';
import EyeOpenIcon from "assets/images/input_eye_open.svg";
import EyeCloseIcon from "assets/images/input_eye_close.svg";

interface IIcon {
    onClick?: () => void,
    url: string,
    extra?: string
}

type TextFieldProps = {
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string,
    rightIcon?: IIcon,
    leftIcon?: IIcon,
    leftContent?: string,
    type?: string,
    inputProps?: InputHTMLAttributes<HTMLInputElement>,
    readOnly?: boolean,
    regex?: string,
    extra?: string,
    inputExtra?: string,
}

const TextField = (props: TextFieldProps) => {
    const {
        type = "text",
        extra = ``,
        leftIcon,
        leftContent,
        rightIcon,
        inputProps,
        onChange,
        value,
        inputExtra = ``,
        placeholder,
        regex,
        readOnly = false
    } = props;


    const [passwordVisible, setPasswordVisible] = useState(false);
    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (regex) {
            e.target.value = e.target.value.replace(new RegExp(regex, "g"), ``);
        }
        onChange(e);
    };

    return (
        <Span id={`text-field-${type}-${placeholder}-id`} rightIcon={ rightIcon !== undefined} extra={extra} readOnly={readOnly}>
            {leftIcon && <Icon url={leftIcon.url} extra={`right: unset; left: 5px;` + leftIcon.extra}/>}
            {leftContent && <LeftContent>{leftContent}</LeftContent>}
            <StyledInput inputExtra={inputExtra} readOnly={readOnly} value={value} placeholder={placeholder}
                         type={type === `password` && passwordVisible ? `text` : type}
                         onChange={_onChange} {...inputProps}/>
            {type === `password` ? (
                <Eye opened={passwordVisible} onClick={() => setPasswordVisible(!passwordVisible)}/>
            ) : rightIcon ? (
                <Icon {...rightIcon} />
            ) : null}
        </Span>
    );
};

export default TextField;

export const StyledInput = styled.input<{ inputExtra?: string }>`
  &:focus {
    outline: none;
  }
  
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  margin: 0;
  font-size: inherit;
  color: white;
  border: unset;
  background: transparent;
  box-sizing: border-box;
  padding: 12px 10px;
  ${({readOnly}) =>
          readOnly &&
          css`
            cursor: default;
            color: white;
          `}
  ${({inputExtra}) => inputExtra}
`;



const Icon = styled.div<{ extra?: string, url?: string }>`
  position: absolute;
  right: 12px;
  top: 50%;
  direction: ltr;
  transform: translateY(-50%);
  width: 22px;
  box-sizing: border-box;
  height: 22px;
  background: url("${({url}) => url}") no-repeat center center / contain;
  cursor: pointer;
  ${({extra}) => extra}
`;

const LeftContent = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;



const Eye = styled(Icon)<{ opened: boolean }>`
  position: relative;
  margin-left: 30px;
  width: 22px;
  box-sizing: border-box;
  height: 22px;
  background: url("${({url}) => url}") no-repeat center center / contain;
  cursor: pointer;
  background: url(${props => props.opened ? EyeOpenIcon : EyeCloseIcon}) no-repeat center center / contain;
`;


const Span = styled.span<{ extra?: string, readOnly: boolean, rightIcon?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  flex-wrap: wrap;
  position: relative;
  background-color: ${props => props.theme.palette.primary.main};
  border: unset;
  padding: unset;
  border-radius: 4px;
  border: 1px solid #434550;
  box-sizing: border-box;
  font-family: Roboto Condensed;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  :hover {
    border: 1px solid #dadada;
  }
  ${({readOnly}) =>
          readOnly &&
          css`
            cursor: default;
            border: none;
            :hover {
              border: none;
            }
          `}

  ${({rightIcon}) => rightIcon && `padding-right: 46px;`}
  ${({extra}) => extra}
`;
