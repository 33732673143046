import React from 'react';
import {MobileContainer} from "ui/containers";
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';


const Profile = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <MobileContainer>
            <TitleText>
                {t(`pages.profile.navigation`)}
            </TitleText>
            <div style={{margin: "0 10px"}}>
                <NavigationLeftBar/>
            </div>
        </MobileContainer>
    );
};

export default Profile;


const TitleText = styled(`div`)((props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
    padding: "36px 0 18px"
}))
