import React, {useState} from 'react';
import styled from 'styled-components';
import LazyImage from './LazyImage';
import {ReactComponent as Star} from "assets/images/games_star_icon.svg";
import {ReactComponent as Play} from "assets/images/game_play_button_img.svg";
import getProviderImage from "../helpers/providerHelper";

type DesktopGameProps = {
    name: string,
    provider?: string,
    url: string,
    isFavorite: boolean,
    isSmall?: boolean,
    onClick: () => void,
    width?: number,
    height?: number,
    onFavoriteClick: () => void,
    addFavorite?: boolean
}

const DesktopGame = (props: DesktopGameProps) => {

    const {addFavorite = true} = props;
    let image = getProviderImage({
        provider: props.provider?.toLocaleLowerCase() || "",
    });

    return (<>
            {
                props.isSmall ? <>
                    <DesktopWrapper title={`${props.name}`} id={`desktop-game-small`}>
                        <div style={{
                            position: "relative",
                            width: props.width ?? 127,
                            height: props.height ?? 127,
                        }}>
                            <Image width={props.width} height={props.height} isSmall={props.isSmall}
                                   onClick={() => props.onClick()} src={props.url} alt={`${props.name}-game`}/>
                            <GamePlaceholder onClick={() => props.onClick()}>
                                {image ? <ImageProvider alt={`provider` + props.provider} src={image}/> : <div/>}
                                <CustomIconButton onClick={() => props.onFavoriteClick()}
                                                  isFavorite={props.isFavorite}>
                                    <Star/>
                                </CustomIconButton>
                                <PlayContainer>
                                    <SlotIconPlay>
                                        <Play style={{marginLeft: 5, width: 20, height: 20}}/>
                                    </SlotIconPlay>
                                </PlayContainer>
                                <BottomTransitionText>
                                    <TransitionText>{props.name}</TransitionText>
                                </BottomTransitionText>
                            </GamePlaceholder>
                        </div>
                    </DesktopWrapper>


                </> : <Wrapper id={`desktop-game-big`}>
                    <div style={{
                        width: props.width ?? 176,
                        height: props.height ?? 176,
                    }}>
                        <Image width={props.width} height={props.height} onClick={() => props.onClick()} src={props.url}
                               alt={`${props.name}-game`}/>
                    </div>

                    <InfoContainer>
                        <TextContent>
                            {`${props.name}`}
                        </TextContent>
                        {addFavorite &&
                            <CustomIconButton onClick={() => props.onFavoriteClick()} isFavorite={props.isFavorite}>
                                <Star/>
                            </CustomIconButton>}
                    </InfoContainer>
                </Wrapper>
            }

        </>

    );
};

export default DesktopGame;

const TransitionText = styled.div`
  color: rgb(255, 255, 255);
  font-size: 16px;

  text-align: center;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.1;
  font-weight: 500;
`

const BottomTransitionText = styled.div`
  width: 100%;
  position: absolute;
  user-select: none;
  text-align: center;
  bottom: -50px;
  border-radius: 0 0 10px 10px;
  background-color: #FFB401;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

const SlotIconPlay = styled.div`
  width: 0;
  height: 0;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  :hover {
    width: 4rem;
    height: 4rem;
  }
`


const ImageProvider = styled.img`
  position: absolute;
  display: none;
  top: 0;
  padding: 10px;
  left: 0;
  height: 40px;
  width: 80px;
  object-fit: contain;
  object-position: center;

`

const PlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const GamePlaceholder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: 1;
`

const CustomIconButton = styled("div")<{ isFavorite?: boolean }>`
  padding: 10px;
  height: auto;
  cursor: pointer;
  position: absolute;
  display: none;
  top: 0;
  right: 0;

  :hover {
    & > svg > path {
      fill: white
    }
  }

  :active {
    & > svg > path {
      fill: #a87600
    }
  }

  & > svg > path {
    fill: ${props => props.isFavorite ? "#FFB401" : "#263154"}
  }
`


const DesktopWrapper = styled.div`
  justify-content: flex-start;
  display: flex;
  gap: 5px;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  padding: 5px;
  transition: all 0.3s;

  :hover {
    border-radius: 10px;

    ${SlotIconPlay} {
      width: 4rem;
      height: 4rem;
    }

    ${BottomTransitionText} {
      bottom: 0px;
      transition: bottom 500ms ease 0s;
    }

    ${GamePlaceholder} {
      opacity: 1;
    }

    ${CustomIconButton} {
      display: block;
    }

    ${ImageProvider} {
      display: block;
    }
  }
`


const Wrapper = styled.div`
  padding: 20px 9px 22px;
  border: 1px solid #31353D;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: 1400px) {
    padding: 10px 9px 12px;
  }

  :hover {
    border: 1px solid #474e57;
  }
`
const Image = styled(LazyImage)<{ isSmall?: boolean, width?: number, height?: number }>`
  width: ${props => props.isSmall ? props.width ?? "127px" : props.width ?? "176px"};
  height: ${props => props.isSmall ? props.height ?? "127px" : props.height ?? "176px"};
  cursor: pointer;
  border-radius: ${props => props.isSmall ? "10px" : "5px"};
  object-fit: cover;
  object-position: center;
`
const InfoContainer = styled.div`
  max-height: 42px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

`

const TextContent = styled.div`
  font-family: Roboto Condensed, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  @media (max-width: 1600px) {
    font-size: 16px;
  }
  @media (max-width: 1400px) {
    font-size: 14px;
  }
`

