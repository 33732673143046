import React from 'react';
import styled from "styled-components";
import StarIcon from "assets/images/star.png";
import { useTranslation } from 'react-i18next';

export const Star = () => {
    const {t} = useTranslation();
    return (
        <HallOfFame>
            <HallOfFameImages src={StarIcon}/>
            <HallOfFameText>
                {t(`pages.hall_of_fame.top`)}
            </HallOfFameText>
        </HallOfFame>
    );
};

const HallOfFame = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  cursor: pointer;
  margin: 0 0 10px -24px;
`

const HallOfFameText = styled.div`
  top: 54%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  position: absolute;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #060912;
  font-size: 22px;
`
const HallOfFameImages = styled.img`
  position: absolute;
  object-fit: contain;
  width: 100px;
  height: 100px;
`
