import React, {useEffect} from 'react';
import styled from "styled-components";
import Ball from "assets/images/loader-ball-icon.png"
import Modal from "../material-kit/Modal";
type PrettyLoaderProps = {
    isLoading: boolean,
}

const PrettyLoader = (props: PrettyLoaderProps) => {
    useEffect(() => {
        if (props.isLoading) {
            let body = document.getElementsByTagName("body");
            body.item(0)!.style.overflow = "hidden";
        } else {
            let body = document.getElementsByTagName("body");
            body.item(0)!.style.overflow = "auto";
        }


    }, [props.isLoading]);

    if (!props.isLoading) {
        return null
    } else {
        return (
            <Modal open={props.isLoading} onClose={() => {}}>
                <ContainerPlaceholder>
                    <Loader src={Ball} alt={'ball-loader-common'}/>
                </ContainerPlaceholder>
            </Modal>

        );
    }


};

export default PrettyLoader;

const Loader = styled.img`
  width: 75px;
  height: 75px;
  animation: load 1.5s linear infinite;
`
const ContainerPlaceholder = styled.div`
  position: absolute;
  justify-content: center;
  background-color: black;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 100000000;
  top: 0;
  left: 0;
  display: flex;
  
  @keyframes load {
    0% {
      transform: rotate(0deg) scale(.9);
    }
    50% {
      transform: rotate(180deg) scale(1.1);
    }
    100% {
      transform: rotate(360deg) scale(.9);
    }

  }


`
