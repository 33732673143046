export interface IUser {
    id: number,
    login: string,
    balance: number,
    active: boolean,
    createdAt: string,
    role: string,
    roleId: number,
    currencyId: string,
    currency: string,
    creatorId: number,
    creator: string
}

export interface ICashbackInfo {
    timeLeft: number,
    amount: number,
    isAvailable: boolean,
    isReceived: boolean
}

export interface IUserState {
    user: IUser | null,
    error: string | undefined,
    loading: boolean,
    cashbackInfo: ICashbackInfo | null,
    loadingCashbackInfo: boolean,
}


export enum UserActionTypes {
    LOAD_USER = "LOAD_USER",
    UPDATE_USER = "UPDATE_USER",
    LOGOUT_USER = "LOGOUT_USER",
    LOAD_CASHBACK_INFO = "LOAD_CASHBACK_INFO",

    LOAD_USER_FAIL = "LOAD_USER_FAIL",
    UPDATE_USER_FAIL = "UPDATE_USER_FAIL",
    LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL",
    LOAD_CASHBACK_INFO_FAIL = "LOAD_CASHBACK_INFO_FAIL",

    LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
    LOAD_CASHBACK_INFO_SUCCESS = "LOAD_CASHBACK_INFO_SUCCESS"
}


/////////////////////Actions/////////////////////////

interface ILoadUser {
    type: UserActionTypes.LOAD_USER;
}

interface IUpdateUser {
    type: UserActionTypes.UPDATE_USER;
}

interface ILogoutUser {
    type: UserActionTypes.LOGOUT_USER;
}

interface ILoadCashbackInfo {
    type: UserActionTypes.LOAD_CASHBACK_INFO;
}

/////////////////////Fail/////////////////////////

interface ILoadUserFail {
    type: UserActionTypes.LOAD_USER_FAIL;
    error: string;
}

interface IUpdateUserFail {
    type: UserActionTypes.UPDATE_USER_FAIL;
    error: string;
}


interface ILogoutUserFail {
    type: UserActionTypes.LOGOUT_USER_FAIL;
    error: string;
}

interface ILoadCashbackInfoFail {
    type: UserActionTypes.LOAD_CASHBACK_INFO_FAIL;
    error: string;
}

/////////////////////Success/////////////////////////

interface ILoadUserSuccess {
    type: UserActionTypes.LOAD_USER_SUCCESS;
    user: IUser;
}

interface IUpdateUserSuccess {
    type: UserActionTypes.UPDATE_USER_SUCCESS;
    user: IUser | null;
}

interface ILogoutUserSuccess {
    type: UserActionTypes.LOGOUT_USER_SUCCESS;
}

interface ILoadCashbackInfoSuccess {
    type: UserActionTypes.LOAD_CASHBACK_INFO_SUCCESS;
    cashbackInfo: ICashbackInfo;
}


export type userAction =
    ILoadUser
    | IUpdateUser
    | ILogoutUser
    |
    ILoadUserFail
    | IUpdateUserFail
    | ILogoutUserFail
    |
    ILoadUserSuccess
    | IUpdateUserSuccess
    | ILogoutUserSuccess
    | ILoadCashbackInfo
    | ILoadCashbackInfoFail
    | ILoadCashbackInfoSuccess;
