import React, {useEffect, useState} from 'react';
import {useInput} from "hooks/useInput";
import {useActions} from "hooks/useActions";
import {useTypedSelector} from "hooks/useTypedSelector";
import StyledButton from 'ui/StyledButton';
import styled from 'styled-components';
import TextField from "ui/material-kit/TextField";
import {useTranslation} from "react-i18next";
import {getStorage} from "../../hooks/useStorage";
import useKeyPress from "../../hooks/useKeyPress";

type LoginProps = {
    isDesktop?: boolean,
}
const Login = (props: LoginProps): JSX.Element => {
    const userName = useInput('');
    const password = useInput('');
    const [state, setState] = useState(false);
    const {userLogin, updateModal} = useActions();
    const {loading, error, user} = useTypedSelector(state => {
        return {
            user: state.user.user,
            loading: state.user.loading,
            error: state.user.error || ""
        }
    })
    const handleClick = async () => {
        await userLogin({login: userName.value, password: password.value});
        setState(true)
    }

    useKeyPress("Enter", async () => {
        await handleClick()
    }, [userName.value, password.value]);

    useEffect(() => {
        if (user && !loading) {
            updateModal(false);
        }
    }, [loading, error])

    const {t} = useTranslation();
    const fields = {
        title: t(`common.login.title`),
        start_winning: t(`common.login.start_winning`),
        username: t(`common.login.username`),
        password: t(`common.login.password`),
        loginButton: t(`common.login.login_button`),
    }

    useEffect(() => {
        const close = (e: any) => {
            if (e.keyCode === 27) {
                e.preventDefault();
                updateModal(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    const PATH = getStorage("APP_SEO.LOGO_PATH");
    const logo = require(`../../${PATH}`)

    if (!props.isDesktop) {
        return (
            <Container>
                <div>
                    <img src={logo} style={{width: "100%"}} alt=""/>
                </div>
                <CredentialsContainer style={{marginTop: 75}}>
                    <TextField placeholder={fields.username} {...userName} />
                    <TextField placeholder={fields.password} type={'password'} {...password}/>
                    <StyledButton onClick={handleClick} isDisabled={loading}>
                        {
                            loading ? "..." : fields.loginButton
                        }
                    </StyledButton>

                </CredentialsContainer>
                {error !== "" && state && <ErrorBlock>
                    {error}
                </ErrorBlock>}
            </Container>
        );
    } else {
        return (
            <WrapperDesktop>
                <Text>
                    {fields.title}
                </Text>
                <Text style={{color: "#5BC448", marginTop: -10}}>
                    {fields.start_winning}

                </Text>

                <WrapperContent>
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 9}}>
                        <Label>{fields.username}</Label>
                        <TextField placeholder={fields.username} {...userName} />
                    </div>
                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 9}}>
                        <Label>{fields.password}</Label>
                        <TextField placeholder={fields.password} type={'password'} {...password} inputProps={{
                            onKeyDown: async (e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                    await handleClick();
                                }
                            }
                        }}/>
                    </div>
                    <div>
                        {error !== "" && state && <ErrorBlock>
                            {error === "incorrect login or password"? t("common.errors.incorrect_login_or_password"): error}
                        </ErrorBlock>}
                    </div>

                </WrapperContent>
                <div style={{width: 264, color: "white", height: 48, marginTop: 12}}>
                    <StyledButton onClick={handleClick} isDisabled={loading}>
                        {
                            loading ? "..." : `${fields.loginButton}`
                        }
                    </StyledButton>
                </div>
            </WrapperDesktop>
        )
    }


};

export default Login;

const Label = styled.span`
  color: #FFFFFF;
  font-weight: 400;
  font-size: 24px;
`

const ErrorBlock = styled.div`
  
  position: absolute;
  color: red;
`

const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 74px);
`


const WrapperContent = styled.div`
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

`

const WrapperDesktop = styled.div`
  width: 740px;
  height: 460px;
  margin-top: -80px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 18px;
  
`

const CredentialsContainer = styled.div`
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

`


const Text = styled.div`
  color: #9B9EAD;
  font-family: Roboto Condensed;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 400;
`
