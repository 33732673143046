import React, {useEffect, useMemo} from 'react';
import {TopList} from './top-list';
import {Swiper, SwiperSlide} from 'swiper/react';
import styled from 'styled-components';
import {Autoplay} from 'swiper'
import "swiper/css";
import "swiper/css/pagination";
import useMediaQuery from 'hooks/useMediaQuery';
import {devices, sizes} from 'constants/breakpoints';
import {HighRollerItem} from "./high-roller-item";
import {mergeHighRollersEvent, useHighRollers} from '../model/high-roller.store';
import {useSocket} from "services/SocketService";


type TickerProps = {
    open: boolean,
    onClose?: () => void,
}

export const Ticker = (props: TickerProps) => {
    const {
        open, onClose = () => {
        }
    } = props;
    const highRollers = useHighRollers();

    const socket = useSocket();

    useEffect(() => {
        socket.on("high_roll", pld => {
            mergeHighRollersEvent(pld);
        });
    }, []);


    const tablet = useMediaQuery(`(max-width: ${sizes.tablet}px)`)
    const desktop = useMediaQuery(`(max-width: ${sizes.desktop}px)`)
    const desktopS = useMediaQuery(`(max-width: ${sizes.desktopS}px)`)
    const laptopL = useMediaQuery(`(max-width: ${sizes.laptopL}px)`)
    const laptop = useMediaQuery(`(max-width: ${sizes.laptop}px)`)


    const columns = useMemo(() => {
        if (tablet) return 1;
        if (laptop) return 2;
        if (laptopL) return 3;
        if (desktopS) return 4;
        if (desktop) return 5;
        return 6;
    }, [tablet, laptop, laptopL, desktopS, desktop]);


    return (
        <>
            {open &&
                <TopList highRollers={highRollers} onClose={onClose}/>
            }
            <SwiperWrapper>
                <Swiper
                    slidesPerView={columns}
                    spaceBetween={10}
                    autoplay={{
                        delay: 7000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay]}
                >
                    {highRollers.map((highRoller, index) => (

                            <SwiperSlide key={`swiper-${highRoller.login}-${index}`}>
                                <CustomHighRollerItem highRoller={highRoller}/>
                            </SwiperSlide>

                    ))}
                </Swiper>
            </SwiperWrapper>
        </>
    );
};


const CustomHighRollerItem = styled(HighRollerItem)`
  width: 260px;
`;


const SwiperWrapper = styled.div`
  position: absolute;
  width: 92%;
  left: 100px;
  margin-right: 100px;

  ${devices.tablet} {
    left: 70px;
  }
`;
