import React, {ChangeEvent, useRef, useState} from 'react';
import styled from "styled-components";
import SelectButton from "ui/SelectButton";
import TextField from "./material-kit/TextField";
import SearchIcon from "assets/images/input_search_icon.svg";
import Accordion from './material-kit/Accordion';
import useOutsideClick from 'hooks/useOutsideClick';

interface SelectList {
    title: string,
    img?: string,
    isSelect?: boolean,
    onClick: () => void
}

type AccordionSelectProps = {
    title: string,
    list: SelectList[],
    withSearch?: boolean,
    isImage: boolean,
    value?: string,
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void,
}


const AccordionSelect = (props: AccordionSelectProps) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick(ref, () => {
        setOpen(false);
    })


    return (

        <div ref={ref}>
            <Accordion extraContent={AccordionContentStyles} extra={AccordionStyles}
                       title={`${props.title}`} onChange={() => {
                setOpen(!open);
            }} open={open} effect={props.value}>
                {props.withSearch &&

                <TextField rightIcon={{
                    url: SearchIcon,
                    extra: `width: 16px; height: 16px;`
                }} extra={`height: 29px; background: #121931; font-size: 16px`}
                           value={props.value!} onChange={props.onChange!}/>
                }

                <ButtonContainer>
                    {
                        props.list.map((item, index) => (
                            <React.Fragment key={`${index}-${item.title}-list`}>
                                <SelectButton onClick={() => {
                                    item.onClick();
                                    setOpen(false);
                                }} title={item.title} img={item.img} isSelect={item.isSelect} isImage={props.isImage}/>


                            </React.Fragment>
                        ))
                    }
                </ButtonContainer>

            </Accordion>
        </div>
    )
};

export default AccordionSelect;


const AccordionStyles = `  
  background: #263154;
  font-family: Roboto Condensed;
  box-sizing: border-box;
  font-size: 18px;
  padding: 11px 16px 11px 19px;
  border-radius: 5px;
  border: none;
  height: 43px;
  min-height: 43px;
  text-transform: capitalize;
  border-radius: 5px; 
  :hover {
    background-color: #202948;
  }
  `

const AccordionContentStyles = `
  gap: 4px;
  margin: 0;
  box-sizing: border-box;
  padding: 0px 5px 8px 5px;
`


const ButtonContainer = styled.div`
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 270px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
`
