import React from 'react';
import {MainMenuCard} from "features/cards-menu/types/card.types";
import styled, {css} from 'styled-components';
import BackgroundCosmos from 'features/cards-menu/assets/background_cosmos.png';
import BackgroundLines from 'features/cards-menu/assets/lines_background.png';
import {ReactComponent as Arrow} from "assets/images/game_arrow_icon.svg"
import {useTranslation} from 'react-i18next';

type MenuCardProps = {
    card: MainMenuCard,
    onClick: () => void
}

export const MenuCard = (props: MenuCardProps) => {
    const {card, onClick} = props;
    const {t} = useTranslation();
    return (
        <Wrapper
            fullHeight={`${window.innerHeight}px`}
            gridColumn={card.GRID}
            onClick={onClick}
            gradient={card.gradientColor}>

            <Background url={BackgroundCosmos}/>
            {card.withLineBackground &&
                <Background isHelp={card.KEY === "help"} url={BackgroundLines}/>}
            <BackgroundMain width={card.width} height={card.height} url={card.background}/>
            <BackgroundIcon isPragmatic={card.KEY === 'pragmatic'} url={card.icon}/>
            <TopContainer isHelp={card.KEY === "help"}>
                <TextContainer>
                    <Title>
                        {t(`pages.main.card_${card.KEY}.title`)}
                    </Title>
                    <Description>
                        {t(`pages.main.card_${card.KEY}.description`)}
                    </Description>
                </TextContainer>
                <SvgIcon title={t(`pages.main.card_${card.KEY}.title`)} shadow={card.shadowArrow}
                         color={card.arrowColor}>
                    <Arrow/>
                </SvgIcon>
            </TopContainer>
        </Wrapper>
    );
};


const SvgIcon = styled.div<{ color?: string, shadow?: string }>`
  & > svg > path {
    stroke: ${props => props.color};
  }

  & > svg {
    width: auto;
    height: 18px;
  }

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${props => props.shadow};
  border-radius: 5px;

`;

const TopContainer = styled.div<{ isHelp: boolean }>`
  width: 100%;
  bottom: 0%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 8px;

  ${({isHelp}) => isHelp && css`
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    & > :nth-child(1) {
      writing-mode: vertical-rl;
      rotate: 180deg;
      margin-bottom: 10px;

      & > :nth-child(2) {
        display: none;
      }

    }
  `}

`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`


const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  color: white;
`

const Description = styled.div`
  font-size: 12px;
  color: #5E6F9E
`


const Wrapper = styled.div<{ gradient?: string, gridColumn: string, fullHeight: string }>`
  width: 100%;
  height: calc((${({fullHeight}) => fullHeight} - 200px) / 4);
  grid-column: ${(props) => props.gridColumn};
  position: relative;
  background: ${props => props.gradient};
  background-color: #060C1E;
  border: 1px solid #142144;
  border-radius: 5px;


  :active {
    transform: scale(0.95);
  }
`


const BackgroundCustom = styled.div<{ url?: string, isHelp?: boolean }>`
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-repeat: no-repeat;
`


const Background = styled(BackgroundCustom)`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position-y: bottom;
  ${({isHelp}) => isHelp && css`
    transform: scale(-1, 1);
  `}
`

const BackgroundIcon = styled(BackgroundCustom)<{ isPragmatic?: boolean }>`
  max-width: 40%;
  max-height: 60%;
  top: 0;
  left: 0;
  background-size: contain;
  background-image: url(${props => props.url});
`

const BackgroundMain = styled(BackgroundCustom)<{ height?: string, width?: string }>`
  max-height: ${({height}) => height ?? "70%"};
  max-width: ${({width}) => width ?? "70%"};
  bottom: inherit;
  left: inherit;
  top: 0;
  right: 0;
  z-index: 0;
  background-size: contain;
  background-image: url(${props => props.url});
  background-position-x: right;

`
