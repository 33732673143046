import React, {ChangeEvent} from 'react';
import CloseIcon from "assets/images/input_cross_icon.svg";
import TextField from './material-kit/TextField';

type CustomSearchProps = {
    value: string,
    onChange: (value: ChangeEvent<HTMLInputElement>) => void,
    placeholder: string,
    OnCloseClick: () => void,
}


const CustomSearch = (props: CustomSearchProps): JSX.Element => {
    const {value, onChange, placeholder, OnCloseClick} = props;
    let fieldCss = `
        height: 31px;
        border: none;
        font-size: 16px;
        input::placeholder {
            text-align: center;
        }
    `
    return (
        <TextField  placeholder={'SEARCH GAME'} rightIcon={value === "" ? undefined : {
            onClick: OnCloseClick,
            url: CloseIcon,
            extra: `width: 16px; height: 16px;`

        }} extra={fieldCss} value={value} onChange={(e) => {
            onChange(e)
        }} />
    );
};

export default CustomSearch;

