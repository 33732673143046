export interface ITransaction {
    status: string,
    amount: number,
    type: string,
    createdAt: string,
}

export interface ICashBack {
    status: string,
    amount: number,
    limit?: number,
    profit?: number,
    totalDeposit?: number,
    type: string,
    createdAt: string,
}


export interface IBet {
    providerId: string,
    gameId: string,
    amount: number,
    type: string,
    createdAt: string,
}


export interface ITransactionState {
    bets: IBet[],
    betsCount: number,
    cashbackOperations: ICashBack[],
    cashbackOperationsCount: number,
    transactions: ITransaction[],
    transactionsCount: number,
    error: string | undefined,
    loading: boolean
}


export enum TransactionActionTypes {
    INITIAL_TRANSACTIONS = 'INITIAL_TRANSACTIONS',
    LOAD_CASHBACK_OPERATIONS = "LOAD_CASHBACK_OPERATIONS",
    LOAD_TRANSACTIONS = "LOAD_TRANSACTIONS",
    LOAD_BETS = "LOAD_BET",

    LOAD_CASHBACK_OPERATIONS_FAIL = "LOAD_CASHBACK_OPERATIONS_FAIL",
    LOAD_TRANSACTIONS_FAIL = "LOAD_TRANSACTIONS_FAIL",
    LOAD_BETS_FAIL = "LOAD_BETS_FAIL",

    LOAD_TRANSACTIONS_SUCCESS = "LOAD_TRANSACTIONS_SUCCESS",
    LOAD_BETS_SUCCESS = "LOAD_BETS_SUCCESS",
    LOAD_CASHBACK_OPERATIONS_SUCCESS = "LOAD_CASHBACK_OPERATIONS_SUCCESS",
}


/////////////////////Actions/////////////////////////

interface ILoadCashbackOperations {
    type: TransactionActionTypes.LOAD_CASHBACK_OPERATIONS;
}

interface ILoadTransactions {
    type: TransactionActionTypes.LOAD_TRANSACTIONS;
}

interface ILoadBets {
    type: TransactionActionTypes.LOAD_BETS;
}

/////////////////////Fail/////////////////////////

interface ILoadTransactionsFail {
    type: TransactionActionTypes.LOAD_TRANSACTIONS_FAIL;
    error: string;
}

interface ILoadBetsFail {
    type: TransactionActionTypes.LOAD_BETS_FAIL;
    error: string;
}

interface ILoadCashbackOperationsFail {
    type: TransactionActionTypes.LOAD_CASHBACK_OPERATIONS_FAIL;
    error: string;
}


/////////////////////Success/////////////////////////

interface ILoadTransactionsSuccess {
    type: TransactionActionTypes.LOAD_TRANSACTIONS_SUCCESS;
    transactions: ITransaction[];
    action: string;
    count: number;
}


interface ILoadCashbackOperationsSuccess {
    type: TransactionActionTypes.LOAD_CASHBACK_OPERATIONS_SUCCESS;
    cashbackOperations: ICashBack[];
    action: string;
    count: number;
}

interface ILoadBetsSuccess {
    type: TransactionActionTypes.LOAD_BETS_SUCCESS;
    bets: IBet[];
    action: string;
    count: number;
}


interface IInitialTransactions {
    type: TransactionActionTypes.INITIAL_TRANSACTIONS;
}


export type transactionAction =
    ILoadCashbackOperations
    | ILoadTransactions
    | ILoadBets
    |
    ILoadTransactionsFail
    | ILoadBetsFail
    | ILoadCashbackOperationsFail
    |
    ILoadCashbackOperationsSuccess
    | ILoadTransactionsSuccess
    | ILoadBetsSuccess
    | IInitialTransactions
