import React, {useEffect, useState } from "react";

const useTime = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = window.setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => {
            window.clearInterval(intervalId);
        }
    }, []);

    return time;
}

export default useTime;
