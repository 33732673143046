import React from 'react';
import {useActions} from 'hooks/useActions';
import {useTypedSelector} from 'hooks/useTypedSelector';
import Modal from "ui/material-kit/Modal";
import styled from 'styled-components';
import CrossIcon from 'assets/images/input_cross_icon.svg'

type CustomModalProps = {
    children: React.ReactNode,
    isDesktop?: boolean,
}


const CustomModal = (props: CustomModalProps): JSX.Element => {
    const {updateModal} = useActions();
    const {open} = useTypedSelector((state => {
        return {
            open: state.app.modal
        }
    }))

    const handleClose = () => {
        updateModal(false)
    }
    return (
        <>
            <Modal
                open={open}
                isFullScreen={!props.isDesktop}
                onClose={handleClose}>
                <Background>
                    <IconContainer>
                        <ExitIcon url={CrossIcon} onClick={handleClose}/>
                    </IconContainer>
                    {props.children}
                </Background>
            </Modal>
        </>
    );
};

export default CustomModal;

const Background = styled.div`
  position: relative;
  background: ${props => props.theme.palette.primary.dark};
  opacity: 1;
  height: 100%;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 10000;
  
`

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`


const ExitIcon = styled.div<{ url: string }>`
  position: relative;
  background: url("${props => props.url}") no-repeat center center / contain;
  width: 24px;
  height: 24px;
  margin: 15px;
  cursor: pointer;
`
