import React from 'react';
import styled from 'styled-components';
import DiscoverCard from "ui/DiscoverCard";


interface ICategory {
    name: string,
    img_url: string,
    id: number,
    createdAt: string
}

type DiscoverCardsProps = {
    selectCard: string,
    categories: ICategory[],
    setSelectCard: (cal: string) => void
}


const DiscoverCards = (props: DiscoverCardsProps): JSX.Element => {
    return (
        <SliderWrapperCard>
            <SliderBlock>
                <Block>
                    {
                        props.categories?.map((item, index) => (
                            <React.Fragment key={`${index}-discover-games`}>
                                <DiscoverCard url={item.img_url}
                                              title={item.name}
                                              setCategory={(val: string) => props.setSelectCard(val)}
                                              selectCategory={props.selectCard}/>
                            </React.Fragment>
                        ))
                    }
                </Block>
            </SliderBlock>
        </SliderWrapperCard>

    );
};

export default DiscoverCards;

const SliderWrapperCard = styled(`div`)((props) => ({
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    boxSizing: "border-box",
    "@media (max-width: 768px)": {
        "&::-webkit-scrollbar": {display: "none"},
    },

}));

const SliderBlock = styled(`div`)((props) => ({
    flexDirection: "row",
    display: "flex",
    overflowY: "hidden",
    width: "max-content"
}));

export const Block = styled(`div`)((props) => ({
    width: 'max-content',
    display: 'flex',
    flexFlow: 'row wrap',
    overflowY: "hidden",
    gap: 5
}));
