import {OrderedMap} from "immutable";
import {gameAction, GameActionTypes, IGame, IGameState, IProviderGame, ITvGame} from "../types/gamesTypes";

const initialState: IGameState = {
    error: undefined,
    providers: [],
    tvProviders: [],
    loading: false,
    gameListCount: 0,
    categories: [],
    tvGames: OrderedMap(),
    tvGamesCount: 0,
    games: OrderedMap(),
    gameList: OrderedMap()
}


const startLoading = (state: IGameState) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state: IGameState, error: string) => {
    return {...state, loading: false, error: error}
}


const updateProviderGamesMap = (oldGames: OrderedMap<string, IProviderGame>, newGame: IProviderGame) => {
    let newMap = oldGames;
    let newProviderGame = {...newGame, Games: [...newGame.Games]};
    newMap = newMap.set(newGame.SectionId, newProviderGame);
    return newMap;
}

const addProviderGamesMap = (oldGames: OrderedMap<string, IProviderGame>, newGame: IProviderGame) => {
    let newMap = oldGames;
    let oldProviderGame = oldGames.get(newGame.SectionId);
    let newProviderGame = {...newGame, Games: [...oldProviderGame?.Games!, ...newGame.Games]};
    newMap = newMap.set(newGame.SectionId, newProviderGame);
    return newMap;
}


const getCorrectGamesMap = (newGames: IProviderGame[]) => {
    let newMap: OrderedMap<string, IProviderGame> = OrderedMap();
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.SectionId, newGame);
    }
    return newMap;
}

const updateGameListMap = (newGames: IGame[]) => {
    let newMap: OrderedMap<string, IGame> = OrderedMap();
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.Id, newGame);
    }
    return newMap;
}

const addGameListMap = (oldGames: OrderedMap<string, IGame>, newGames: IGame[]) => {
    let newMap = oldGames;
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.Id, newGame);
    }
    return newMap;
}

const updateTvGameMap = (newGames: ITvGame[]) => {
    let newMap: OrderedMap<string, ITvGame> = OrderedMap();
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.Id, newGame);
    }
    return newMap;
}

const addTvGameMap = (oldGames: OrderedMap<string, ITvGame>, newGames: ITvGame[]) => {
    let newMap = oldGames;
    for (let i in newGames) {
        let newGame = newGames[i];
        newMap = newMap.set(newGame.Id, newGame);
    }
    return newMap;
}

const gameReducer = (state = initialState, action: gameAction): IGameState => {
    switch (action.type) {

        case GameActionTypes.LOAD_CATEGORIES:
        case GameActionTypes.LOAD_TV_PROVIDERS:
        case GameActionTypes.LOAD_PROVIDERS:
        case GameActionTypes.LOAD_GAMES:
        case GameActionTypes.UPDATE_GAME:
            return startLoading(state)

        case GameActionTypes.LOAD_CATEGORIES_FAIL:
        case GameActionTypes.LOAD_TV_PROVIDERS_FAIL:
        case GameActionTypes.LOAD_PROVIDERS_FAIL:
        case GameActionTypes.LOAD_GAMES_FAIL:
        case GameActionTypes.UPDATE_GAME_FAIL:
            return stopLoading(state, action.error)


        case GameActionTypes.LOAD_PROVIDERS_SUCCESS:
            return {
                ...state, loading: false, providers: action.providers,
            }
        case GameActionTypes.LOAD_TV_PROVIDERS_SUCCESS:
            return {
                ...state, loading: false, tvProviders: action.tvProviders,
            }
        case GameActionTypes.LOAD_CATEGORIES_SUCCESS:
            return {
                ...state, loading: false, categories: action.categories,
            }
        case GameActionTypes.LOAD_GAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                games: getCorrectGamesMap(action.games),
            }
        case GameActionTypes.LOAD_GAMELIST_SUCCESS:
            if (action.action === "update") {
                return {
                    ...state,
                    loading: false,
                    gameListCount: action.count,
                    gameList: updateGameListMap(action.games),
                }
            } else if (action.action === "add") {
                return {
                    ...state,
                    loading: false,
                    gameListCount: action.count,
                    gameList: addGameListMap(state.gameList, action.games),
                }
            } else {
                return {
                    ...state,
                    loading: false,
                }
            }

        case GameActionTypes.LOAD_TV_GAMES_SUCCESS:
            if (action.action === "update") {
                return {
                    ...state,
                    loading: false,
                    tvGamesCount: action.count,
                    tvGames: updateTvGameMap(action.tvGames),
                }
            } else if (action.action === "add") {
                return {
                    ...state,
                    loading: false,
                    tvGamesCount: action.count,
                    tvGames: addTvGameMap(state.tvGames, action.tvGames),
                }
            } else {
                return {
                    ...state,
                    loading: false,
                }
            }
        case GameActionTypes.LOAD_PROVIDER_GAMES_SUCCESS:
            if (action.action === "update") {
                return {
                    ...state,
                    loading: false,
                    games: updateProviderGamesMap(state.games, action.games),
                }
            } else if (action.action === "add") {
                return {
                    ...state,
                    loading: false,
                    games: addProviderGamesMap(state.games, action.games),
                }
            } else {
                return {
                    ...state,
                    loading: false,
                }
            }
        case GameActionTypes.UPDATE_FAVORITE_GAME_SUCCESS:

            if (action.isTvGames) {
                let oldTvGame = state.tvGames.get(action.game.Id)!;
                return {
                    ...state,
                    loading: false,
                    tvGames: state.tvGames.set(action.game.Id, {...oldTvGame, ...action.game}),
                }
            }
           else if (action.isGameList){
                let oldGame = state.gameList.get(action.game.Id)!;
                return {
                    ...state,
                    loading: false,
                    gameList: state.gameList.set(action.game.Id, {...oldGame, ...action.game}),
                }
            } else {
                let games = state.games;
                let oldProvider = games.get(action.game.SectionId)!;
                let oldGames = oldProvider?.Games.reduce((a: OrderedMap<string, IGame>, b) => a.set(b.Id, b), OrderedMap<string, IGame>());
                let oldGame = oldGames.get(action.game.Id)!;
                let newGames = oldGames.set(action.game.Id, {...oldGame, ...action.game});
                let newProviderGame: IProviderGame = {
                    ...oldProvider,
                    Games: [
                        ...newGames.toArray().map(item => item[1])
                    ]
                }
                return {
                    ...state,
                    loading: false,
                    games: games.set(action.game.SectionId, newProviderGame),
                }
            }
        default:
            return state;
    }
}


export default gameReducer;
