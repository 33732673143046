import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import HistoryItem from 'ui/HistoryItem';
import {ReactComponent as BetHistoryIcon} from "assets/images/profile_logo_bet-history_icon.svg"
import FilterDateCustom from 'ui/FilterDateCustom';
import {useTypedSelector} from 'hooks/useTypedSelector';
import {useActions} from 'hooks/useActions';

import InfiniteScroll from 'react-infinite-scroll-component';
import NavigationLeftBar from "components/wrapper/drawer/NavigationLeftBar";


const BetHistoryDesktop = (): JSX.Element => {
    const [page, setPage] = useState(1);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [calendar, setCalendar] = useState({
        range_start: new Date().setDate(new Date().getDate() + 7),
        range_end: + new Date(),
    });

    const {bets, betsCount} = useTypedSelector((state) => {
        return {
            bets: state.transaction.bets,
            betsCount: state.transaction.betsCount
        }
    })

    const {getBets} = useActions();


    useEffect(() => {
        setIsLoadingData(true);
        handleSearch().then(() => {
            setPage(1);
            setHasMoreItems(true);
            setIsLoadingData(false);
        })
    }, [calendar]);


    const handleSearch = async () => {
        await getBets({
            range_start: calendar.range_start,
            range_end: calendar.range_end,
        });

    }

    const loadMore = () => {
        if (bets.length >= betsCount) {
            setHasMoreItems(false);
            return;
        }
        setHasMoreItems(true);
        let newPage = page + 1;
        setPage(newPage);
        getBets({
            page: page + 1,
            range_start: calendar.range_start,
            range_end: calendar.range_end,
            action: "add",
        })

    };


    return (
            <MainContainer>
                <div style={{width: 339}}>
                    <NavigationLeftBar heightButton={"65px"}/>
                </div>
                <FlexContent style={{gap: 28}}>
                    <BetHistoryIcon/>
                    <FilterDateCustom
                        isLoading={isLoadingData}
                        onSave={(from: Date, to: Date) => {
                            setCalendar({range_start: +from, range_end: +to})
                        }}/>
                </FlexContent>
                <ContainerHistory id={`scrollableDivOperations`}>
                    <InfiniteScroll
                        dataLength={bets.length}
                        scrollableTarget={`scrollableDivOperations`}
                        next={loadMore}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 10,
                        }}
                        hasMore={hasMoreItems}
                        loader={<></>}
                    >

                        {
                            bets.map((item, index) => (
                                <React.Fragment key={`bet-history-${index}-${item.providerId}`}>
                                    <HistoryItem access={item.type === "win"} amount={`${item.amount.toFixed(2)}`}
                                                 status={item.type}
                                                 date={item.createdAt} provider={item.providerId} game={item.gameId}/>
                                </React.Fragment>
                            ))

                        }

                    </InfiniteScroll>
                </ContainerHistory>
            </MainContainer>

    );
};

export default BetHistoryDesktop;


const MainContainer = styled.div`
  margin: 23px auto 0 auto;
  max-width: 1150px;
  background-color: ${props => props.theme.palette.primary.dark};
  padding: 20px;
  display: flex;
  justify-content: space-between;
`

const FlexContent = styled(`div`)((props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 240,
}))

const ContainerHistory = styled(`div`)((props) => ({
    width: 428,
    height: 450,
    overflowY: "auto",
}))





