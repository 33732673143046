import React from 'react';
import {ReactComponent as Star} from "assets/images/games_star_icon.svg"
import {ReactComponent as Arrow} from "assets/images/game_arrow_icon.svg"
import LazyImage from './LazyImage';
import styled from 'styled-components';

type GameProviderCardProps = {
    text: string
    onClick: () => void,
    isFavorite: boolean,
    favoriteClick: () => void,
    url: string
}
const GameProviderCard = (props: GameProviderCardProps): JSX.Element => {
    return (
        <Wrapper id={`game-provider-card`} onClick={(e) => {
            props.onClick();
            e.stopPropagation();
        }}>
            <ContentContainer>
                <Image src={props.url} alt={`game-${props.text}-${props.url}`}/>
                <ContainerText>
                    <Text>
                        {`${props.text}`}
                    </Text>
                    <SmallText>
                        {`${props.text}`}
                    </SmallText>
                </ContainerText>

            </ContentContainer>
            <PlayContainer>
                <CustomIconButton onClick={(e) => {
                    e.stopPropagation();
                    props.favoriteClick();

                }} isFavorite={props.isFavorite}>
                    <Star/>
                </CustomIconButton>

                <PlayButton>
                    <ButtonText>
                        Play
                    </ButtonText>
                    <Arrow/>
                </PlayButton>
            </PlayContainer>

        </Wrapper>
    );
};

export default GameProviderCard;

const Image = styled(LazyImage)((props) => ({
    width: 86,
    height: 80,
    marginBottom: -5,
    borderRadius: 13,
    objectPosition: "center",
    objectFit: "cover",
}));

const ContainerText = styled("div")((props) => ({
    textOverflow: "ellipsis",
    textAlign: "left",
    overflow: 'hidden',
}));


const Text = styled(ContainerText)((props) => ({
    fontFamily: "Roboto Condensed",
    whiteSpace: "nowrap",
    fontSize: 17,
    "@media (max-width: 300px)": {
        display: "none"
    },
    fontWeight: 400
}));

const SmallText = styled(Text)((props) => ({
    fontSize: 14,
    color: "#E0D81A",
    textTransform: "capitalize",
    fontWeight: 400
}));

const ButtonText = styled("div")((props) => ({
    fontFamily: "Roboto Condensed",
    whiteSpace: "nowrap",
    fontSize: 17,
    textTransform: "uppercase"
}));


const Wrapper = styled("div")((props) => ({
    width: "100%",
    color: "white",
    overflow: 'hidden',
    cursor: "pointer",
    height: 101,
    padding: "14px 8px 10px 12px",
    background: "#39466D",
    borderRadius: 5,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
        background: "#2e3a59",
    }
}));


const ContentContainer = styled(ContainerText)((props) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 12,
}));

const PlayContainer = styled("div")((props) => ({
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
}));

const PlayButton = styled("div")((props) => ({
    padding: "6px 14px",
    width: 102,
    height: 27,
    margin: 0,
    background: "#273154",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    color: "#ffffff",
    justifyContent: "space-between",
    "&:hover": {
        background: "#FFB401",
        color: "#000000",
        "& > svg > path": {
            stroke: "#000000"
        }
    }

}));

const CustomIconButton = styled("div")<{ isFavorite: boolean }>((props) => ({
    padding: 0,
    cursor: "pointer",
    margin: 0,
    "& > svg > path": {
        fill: props.isFavorite ? "#FFB401" : "#263154"
    }
}));
