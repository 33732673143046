import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import theme from 'constants/theme-constants';
import {store} from './store';
import './index.css';
import "swiper/css/bundle";
import 'react-day-picker/dist/style.css';
import './i18n';
import {ThemeProvider} from 'styled-components';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
    ,
    document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
