import axios from "api";
import { AxiosPromise } from "axios";
import {IHallOfFame} from "../model/hall-of-fame.types";


const BASE_URL = '/api/info';


export const getHallOfFameMonths = (): AxiosPromise<string[]> =>
    axios.get(`${BASE_URL}/hall-of-fame/months`,);

export const getHallOfFame = (params?: {month: string}): AxiosPromise<IHallOfFame[]> =>
    axios.get(`${BASE_URL}/hall-of-fame`, {params});
