import {
    AppTypes as types,
    IAlert, IBanner, IError, IPromotion, IStaticPage
} from "../types/appTypes";


import {appAction} from "../types/appTypes";
import {Dispatch} from "redux";
import {appAPI} from "api/app/appApi";

export const updateAlert = (alert: IAlert): appAction => {
    return {
        type: types.UPDATE_ALERT,
        alert
    }
}

export const updateError = (error: IError): appAction => {
    return {
        type: types.UPDATE_ERROR,
        error
    }
}

export const updateModal = (open: boolean): appAction => {
    return {
        type: types.UPDATE_MODAL,
        open
    }
}

export const setSport = (sport: any): appAction => {
    return {
        type: types.SET_SPORT,
        sport
    }
}

export const setLoading = (loading: boolean): appAction => {
    return {
        type: types.SET_LOADING,
        loading
    }
}
export const loadStaticPages = (pages: IStaticPage[]): appAction => {
    return {
        type: types.GET_STATIC_PAGES,
        pages
    }
}

export const loadPromotions = (promotions: IPromotion[]): appAction => {
    return {
        type: types.GET_PROMOTIONS,
        promotions
    }
}

export const loadBanners = (banners: IBanner[]): appAction => {
    return {
        type: types.GET_BANNERS,
        banners
    }
}

export const getStaticPages = () => {
    return (dispatch: Dispatch<appAction>) => {
        return appAPI.getStaticPages().then(pld => {
            dispatch(loadStaticPages(pld));
        }).catch(() => {
            console.log("error static pages")
        })
    }
}

export const getPromotions = (lang: string) => {
    return (dispatch: Dispatch<appAction>) => {
        return appAPI.getPromotions(lang).then(pld => {
            dispatch(loadPromotions(pld));
        }).catch(() => {
            console.log("error promotions")
        })
    }
}

export const getBanners = (lang: string) => {
    return (dispatch: Dispatch<appAction>) => {
        return appAPI.getBanners(lang).then(pld => {
            dispatch(loadBanners(pld));
        }).catch(() => {
            console.log("error banners")
        })
    }
}

