import React from 'react';
import styled from 'styled-components';

type DiscoverCardProps = {
    title: string,
    url: string,
    setCategory: (key: string) => void,
    selectCategory: string,
}

const DiscoverCard = (props: DiscoverCardProps) => {
    let isSelect = props.title === props.selectCategory;
    return (
        <Container id={`discover-card-${props.title}`} isSelect={isSelect} onClick={() => {
            props.setCategory(props.title);
        }}>
            <ImgCard src={props.url} isSelect={isSelect} alt={`${props.title}-category`}/>
            <Title isSelect={isSelect}>
                {`${props.title}`}
            </Title>
        </Container>
    );
};

export default DiscoverCard;


const Title = styled.div<{ isSelect?: boolean }>`
  font-size: 13px;
  font-weight: 700;
  text-transform: capitalize;
  color: ${props => props.isSelect ? "#FFB401" : "#ffffff"}
`

const ImgCard = styled.img<{ isSelect?: boolean }>`
  filter:${props => !props.isSelect? "grayscale(100%)  brightness(140%)": "none"} ;
  width: 27px;
  height: 27px;
`


const Container = styled.div<{ isSelect?: boolean }>`
  cursor: pointer;
  background: ${props => props.theme.palette.primary.main};
  box-sizing: border-box;
  border-radius: 5px;
  border: ${props => `1px solid ${props.isSelect ? "#FFB401" : "#434550"}`};
  height: 60px;
  width: 109px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;

  & > svg > path {
    fill: ${props => props.isSelect ? "#FFB401" : "#ffffff"}
  }

`
