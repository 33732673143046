import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ArrowButton from "ui/ArrowButton";

type ScrollButtonType = {
    isDesktop?: boolean
}

const ScrollButton = (props: ScrollButtonType) => {
    const [scrollState, setScrollState] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    const handleScroll = () => {
        setScrollState(window.scrollY);
    };

    return (
        <>
            {scrollState > 0 &&
            <FixedButtonContainer>
                <ArrowButton styles={props.isDesktop ? {
                    maxWidth: 50,
                    maxHeight: 50,
                    minWidth: 50,
                    minHeight: 50,
                } : undefined} onClick={() => window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })}/>
            </FixedButtonContainer>}
        </>
    );
};

export default ScrollButton;


const FixedButtonContainer = styled(`div`)((props) => ({
    position: 'fixed',
    right: 10,
    bottom: 90,
    zIndex: 100
}));
