import React from 'react';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';


type StyledMobileButtonProps = {
    src: string,
    title: string,
    iconJSX?: JSX.Element,
    heightButton?: string,
    style?: object
}


const StyledMobileButton = ({src, style, title, heightButton, iconJSX}: StyledMobileButtonProps): JSX.Element => {
    const navigate = useNavigate();
    return (

        <ContainerBtn id={`styled-mobile-button`} heightButton={heightButton} onClick={() => {
            navigate(src)
        }}>
            <Title style={style} >
                {title}
            </Title>

            <StyledIcon>
                {iconJSX}
            </StyledIcon>

        </ContainerBtn>
    );
};

export default StyledMobileButton;

const ContainerBtn = styled("button")<{heightButton?: string}>(({theme, heightButton}) => ({
    boxSizing: "border-box",
    borderRadius: 5,
    display: "flex",
    height: !heightButton? 60 : heightButton,
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    padding: "12px",
    width: "100%",
    color: "black",
    background: theme.palette.primary.main,
    "&:hover": {
        background: "#212b49"
    }

}))

const Title = styled(`div`)({
    textTransform: "uppercase",
    color: "white",
    fontSize: 18,
    fontWeight: 700,
    fontFamily: "Roboto Condensed",
})

const StyledIcon = styled(`div`)({
    padding: 0,
    margin: 0,
    height: 40,
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > svg": {
        width: 30,
        height: 30
    }
})

